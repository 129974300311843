import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, Button, Upload, Select, message, Breadcrumb, ConfigProvider } from "antd";
import "../property/styles.css"; // Ensure this path is correct.
import { isJPGorPNG } from "../../utils";
import {
  useAddNewAdminMutation,
  useGetAllRolesQuery,
} from "../../features/adminstrator/adminApi";
import ImgCrop from "antd-img-crop";

const { Option } = Select;

const AddNewAdmin = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addNewAdmin, { isLoading }] = useAddNewAdminMutation(); // Initialize the mutation

  const [imageObj, setImageObj] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const {
    data: roleDataList,
    isLoading: isRoleLoading,
    error,
  } = useGetAllRolesQuery();

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setImgUrl(reader.result);
      },
      false
    );
    if (imageObj) {
      reader.readAsDataURL(imageObj);
    }
  }, [imageObj]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.originFileObj) {
        return {
          ...file,
          preview: URL.createObjectURL(file.originFileObj),
        };
      }
      return file;
    });

    setFileList(newFileList);
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
    } else {
      setImageObj(null);
    }
  };
  
  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return false;
  };

  const handleShowPreview = () => {
    setShowPreviewModal(true);
  };

  const handlePreviewCancel = () => {
    setShowPreviewModal(false);
  };

  const handleFormFinish = async (values) => {
    try {
      // Include the uploaded profile image and selected role ID in the form values
      const payload = {
        name: values.name,
        email: values.email,
        phone_number: values.phone_number,
        password: values.password,
        role: values.role, // Role ID
        profile: imageObj,
      };
      const response = await addNewAdmin(payload).unwrap();
      console.log("response", response);

      message.success("Admin created successfully!");
      navigate(`/${id}/adminstrator/list`);
    } catch (error) {
      message.error("Failed to create admin. Please try again.");
      console.error("Error creating admin:", error);
    }
  };

  return (
    <>
    <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/adminstrator/list`)}>
          Admin List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Admin</Breadcrumb.Item>
      </Breadcrumb>
      <Card
      style={{width: 880, margin: 'auto'}}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: "8px" }}>Add New Admin</span>
          </div>
        }>
           <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
        <Form layout="vertical" onFinish={handleFormFinish}>
          <Form.Item
            rules={[
              { required: true, message: "Please upload tenant profile" },
            ]}>
            <ImgCrop rotationSlider>
              <Upload
                onPreview={handleShowPreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                listType="picture-circle"
                accept=".png,.jpg,image/png,image/jpeg"
                multiple={false}
                maxCount={1}
                fileList={fileList}>
                {fileList.length >= 1 ? null : "+ Upload"}
              </Upload>
            </ImgCrop>
          </Form.Item>
          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent:'space-between'}}>
            <Form.Item
              className="input-group"
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input the name!" }]}>
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              className="input-group"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email!",
                },
              ]}>
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phone_number"
              className="input-group"
              rules={[
                { required: true, message: "Please input the phone number!" },
              ]}>
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Role"
              name="role"
              className="input-group"
              rules={[{ required: true, message: "Please select a role!" }]}>
              <Select
                loading={isRoleLoading}
                placeholder="Select a role"
                style={{ height: 44 }}>
                {error && <Option disabled>Error loading roles</Option>}
                {!isRoleLoading && roleDataList?.data?.roles?.length > 0 ? (
                  roleDataList.data.roles.map((role) => (
                    <Option key={role._id} value={role._id}>
                      {role.name}
                    </Option>
                  ))
                ) : (
                  <Option disabled>No roles available</Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item
              label="Set Password"
              name="password"
              className="input-group"
              rules={[
                { required: true, message: "Please input the password!" },
              ]}>
              <Input.Password style={{ height: 44 }} />
            </Form.Item>

            </div>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}>
                <Button type="text" onClick={() => navigate(`/${id}/adminstrator/list`)} className="btn">Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn filter"
                loading={isLoading}>
                Create
              </Button>
            </Form.Item>
        </Form>
        </ConfigProvider>
      </Card>
    </>
  );
};

export default AddNewAdmin;
