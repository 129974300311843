import React, { useState } from "react";
import {
  Card,
  Form,
  Button,
  Table,
  Input,
  Modal,
  DatePicker,
  Select,
  Tag,
  Space,
  Dropdown,
} from "antd";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;

const { Search } = Input;
const MaintenanceList = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isViewDetailModalOpen, setIsViewDetailModalOpen] = useState(false);

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showChangeStatusModal = (record) => {
    setCurrentRecord(record);
    setIsChangeStatusModalOpen(true);
  };

  const handleChangeOk = () => {
    setIsChangeStatusModalOpen(false);
  };

  const handleChangeCancel = () => {
    setIsChangeStatusModalOpen(false);
  };

  const showViewDetailModal = (record) => {
    setCurrentRecord(record);
    setIsViewDetailModalOpen(true);
  };

  const handleViewDetailOk = () => {
    setIsViewDetailModalOpen(false);
  };

  const handleViewDetailCancel = () => {
    setIsViewDetailModalOpen(false);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Outlet Name",
      dataIndex: "outletName",
      key: "outletName",
    },
    {
      title: "Type of Issue",
      dataIndex: "typeOfIssue",
      key: "typeOfIssue",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags?.map((tag) => {
            let color;
            switch (tag) {
              case "Processing":
                color = "#FF9F43";
                break;
              case "Finished":
                color = "#7367F0";
                break;
              case "Pending":
                color = "red";
                break;
              default:
                color = "red";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: (
        <SettingsOutlinedIcon style={{ display: "flex", margin: "auto" }} />
      ),
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: "View Details",
                  onClick: () => showViewDetailModal(record),
                },
                {
                  key: "2",
                  label: "Change Status",
                  onClick: () => showChangeStatusModal(record),
                },
                {
                  key: "3",
                  label: "Make Invoice",
                  onClick: () => navigate("/maintenance/list/make-invoice"),
                },
                {
                  key: "4",
                  label: "Delete",
                  onClick: () => showDeleteModal(),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{border: '1px solid darkblue'}}>Action <DownOutlined/></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      date: "11/11/2022",
      tenantName: "Tenant Name",
      outletName: "Outlet Name",
      typeOfIssue: "Issue #001",
      tags: ["Processing"],
    },
    {
      key: "2",
      date: "11/11/2022",
      tenantName: "Tenant Name",
      outletName: "Outlet Name",
      typeOfIssue: "Issue #001",
      tags: ["Finished"],
    },
    {
      key: "3",
      date: "11/11/2022",
      tenantName: "Tenant Name",
      outletName: "Outlet Name",
      typeOfIssue: "Issue #001",
      tags: ["Pending"],
    },
  ];
  return (
    <>
     <div className="page-new-add">
        <div>
          <h3 className="page-title">Maintenance List</h3>
          <p className="total-number">
            Total Maintenance :{" "}
            <div className="total-no-display">
              {/* {?.data?.totalTenants} */}
            </div>
          </p>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Search by Tenant Name, Outlet Name"
          allowClear
          enterButton="Search"
          size="large"
          style={{
            width: 456,
          }}
        />
        <RangePicker />
      </div>

      <div className="filter-card">
        <Card title="Filter by :">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="filter-card-info">
                <Select
                  placeholder="Tenant Type"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}
                  // onChange={(value) => setStatusFilter(value)} // Update status filter state
                >
                  <Option value="">Own</Option>
                  <Option value="Active">Lease</Option>
                </Select>
                <Select
                  placeholder="Status"
                  style={{
                    width: 150,
                    height: 38,
                  }}
                >
                  <Option value="">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Suspend">Suspend</Option>
                </Select>
              </div>
              <div className="filter-btn">
                <Button className="filter-gp-btn" >
                  Clear
                </Button>
                <Button className="filter-gp-btn filter">Filter</Button>
              </div>
            </div>
          </div>
        </Card>
      </div>

      {/* Table Card */}
        <Form style={{ marginTop: "15px" }}>
          <Form.Item>
            <Table columns={columns} dataSource={dataSource} />
          </Form.Item>
        </Form>

      {/* Delete Modal */}
      <Modal
        title="Are you sure to delete this property list?"
        open={isDeleteModalOpen}
        okText="Confirm"
        okType="danger"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}></Modal>

      {/* Change Status Modal */}
      <Modal
        title="Maintenance Details"
        open={isChangeStatusModalOpen}
        okText="Confirm"
        onOk={handleChangeOk}
        onCancel={handleChangeCancel}>
        <Form layout="vertical">
          <Form.Item label="Status">
            <Select
              defaultValue={currentRecord?.tags}
              style={{ height: 44 }}
              options={[
                { value: "processing", label: "Processing" },
                { value: "finished", label: "Finished" },
                { value: "pending", label: "Pending" },
              ]}></Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* View Detail Modal */}
      <Modal
        title="Maintenance Details"
        open={isViewDetailModalOpen}
        footer={null}
        onCancel={handleViewDetailCancel}
        width={780}>
        <Form layout="vertical">
          <Form.Item label="Date">
            <Input
              defaultValue={currentRecord?.date}
              style={{ height: 44 }}></Input>
          </Form.Item>

          <div className="view-detail-modal">
            <Form.Item label="Tenant Name">
              <Input
                defaultValue={currentRecord?.tenantName}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Outlet Name">
              <Input
                defaultValue={currentRecord?.outletName}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="view-detail-modal">
            <Form.Item label="Type of Issue">
              <Input
                defaultValue={currentRecord?.typeOfIssue}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Status">
              <Input
                defaultValue={currentRecord?.tags}
                className="view-detail-input"
                style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <Form.Item label="Note">
            <Input.TextArea></Input.TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default MaintenanceList;
