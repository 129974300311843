import React from 'react';
import { Pagination } from 'antd';

const ReusablePagination = ({ currentPage, pageSize, total, onChange }) => {
  return (
    <div className="pagination">
      {/* Page size changer */}
      <div className="size-changer">
        <p className="pagination-name">Count Per Page</p>
        <Pagination
          current={currentPage}
          pageSizeOptions={["10", "20", "30"]}
          showSizeChanger={true}
          pageSize={pageSize}
          onChange={(page, size) => onChange(page, size)} // Handle page and size change
          style={{ marginTop: "6px" }}
          showQuickJumper={false}
          locale={{ items_per_page: "" }}
        />
      </div>

      {/* Page number pagination */}
      <div className="pagination-number">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={total}
          onChange={(page) => onChange(page, pageSize)} // Handle page number change only
          align="end"
        />
      </div>
    </div>
  );
};

export default ReusablePagination;
