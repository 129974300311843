export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
}

export function isJPGorPNG(file) {
  const isImage =
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png";

  return isImage;
}

export function simulateCustomError(errorMessage) {
  return { data: { message: errorMessage } };
}

export const percentageRegex = /^(?:99|[1-9]\d?|1)(?:\.\d{1,2})?$/;
