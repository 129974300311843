import { baseApi } from "../../app/baseApi";

export const announcementApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllAnnouncementList: builder.query({
      query: () => `/Announcement/announcements/list`,
      providesTags: ["AnnouncementList"],
    }),

    // addNewAnnouncement: builder.mutation({
    //   query: (params) => ({
    //     url: `/Announcement/announcements/create`,
    //     method: "POST",
    //     body: {
    //       sendTo,
    //       type,
    //       title,
    //       description,
    //       imageUrl,  // Send the URL of the uploaded image
    //     },
    //   }),
    //   invalidatesTags: ["AnnouncementList"],
    // }),

    addNewAnnouncement: builder.mutation({
      query: (params) => {
        const formData = new FormData();
        formData.append("sendTo", params.sendTo);
        formData.append("title", params.title);
        formData.append("description", params.description);
        if(params.image) {
          formData.append("image", params.image);
        }
        return {
          url: `/Announcement/announcements/create`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["AnnouncementList"]
    }),
    

    // updateUtility: builder.mutation({
    //   query: ({ utilityId, updatedData }) => ({
    //     url: `/Utility_Bill/update/${utilityId}`,
    //     method: "PATCH",
    //     body: updatedData,
    //   }),
    //   onError: (error) => {
    //     console.error("Edit utility failed:", error);
    //   },
    //   invalidatesTags: ["UtilityList"],
    // }),

    deleteAnnouncement: builder.mutation({
      query: ({ announcementId }) => ({
        url: `/Announcement/announcements/delete/${announcementId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AnnouncementList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllAnnouncementListQuery, useDeleteAnnouncementMutation, useAddNewAnnouncementMutation
} = announcementApi;
