import {
  Button,
  Card,
  Table,
  Dropdown,
  Space,
  Form,
  Select,
  Input,
  Tag,
  Modal,
  message,
  Row,
  Col,
  Divider
} from "antd";
import React, { useState } from "react";
import "../property/styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useDeleteAdminMutation,
  useGetAllAdminListQuery,
} from "../../features/adminstrator/adminApi";
import AddIcon from "@mui/icons-material/Add";
import { DownOutlined, EditOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";

const { Search } = Input;

const AdminstratorList = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const token = useSelector((state) => state.authSlice.token2);
  const { data: adminListData, isLoading: isLoadingAdmins } =
    useGetAllAdminListQuery(token);

  const [deleteAdmin, { isLoading: isDeleteLoading }] =
    useDeleteAdminMutation();

  const { id } = useParams();


  const handleViewDetails = (admin) => {
    setSelectedAdmin(admin); // Set the selected shop to show details
    setIsViewModalOpen(true); // Open the modal when a shop is selected
  };

  const hanleViewCancel = () => {
    setIsViewModalOpen(false);
  };

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteAdmin({ adminId: currentRecord?.key }).unwrap();
        setIsModalOpen(false);
        message.success("Admin is deleted successfully.");
      } catch (err) {
        console.error("Failed to delete this admin", err);
        message.error("Failed to delete this admin");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: (<span><EyeOutlined/> View</span>),
      onClick: (admin) => handleViewDetails(admin),
    },
    {
      key: "2",
      label: (<span><EditOutlined/> Edit</span>),
      onClick: (record) =>
        navigate(`/${id}/adminstrator/edit`, { state: { adminData: record } }),
    },
    {
      key: "3",
      label: (<span><DeleteOutlined/> Delete</span>),
      onClick: (record) => showModal(record),
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ph No.",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Roles",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag color={status === "Available" ? "green" : "red"}>{status}</Tag>
      ),
    },
    {
      title: '',
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record),
              })),
            }}
            placement="bottomRight">
            <Button style={{border:'1px solid darkblue'}}>Action <DownOutlined/></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = adminListData?.data?.map((admin, index) => ({
    key: admin._id,
    no: index + 1,
    name: admin.name,
    email: admin.email,
    phone_number: admin.phone_number,
    role: admin.role.name,
  }));

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return (
    <>
     <div className="page-new-add">
        <div>
          <h3 className="page-title">Admin List</h3>
          <p className="total-number">
            Total Admin :{" "}
            <div className="total-no-display">
              {adminListData?.data?.admins}
            </div>
          </p>
        </div>
        <div className="property-search">
          <Form.Item>
            <Link to={`/${id}/adminstrator/add-new-admin`}>
              <Button className="property-add-btn">
                <AddIcon />
                Add New Admin
              </Button>
            </Link>
          </Form.Item>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Search"
          allowClear
          enterButton="Search"
          size="large"
          // onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: 456,
          }}
        />
      </div>

      <div className="filter-card">
        <Card title="Filter by :" >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="filter-card-info">
                <Select
                  placeholder="Role"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}
                  // onChange={(value) => setStatusFilter(value)} // Update status filter state
                >
                  {/* <Option value="">Own</Option>
                  <Option value="Active">Lease</Option> */}
                </Select>
                <Select
                  placeholder="Status"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}
                  // onChange={(value) => setStatusFilter(value)} // Update status filter state
                >
                  {/* <Option value="">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Suspend">Suspend</Option> */}
                </Select>
              </div>
              <div className="filter-btn">
                <Button className="filter-gp-btn" 
                // onClick={resetFilters}
                >
                  Clear
                </Button>
                <Button className="filter-gp-btn filter">Filter</Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Form>
        <Form.Item>
          <Table
            columns={columns}
            dataSource={tableData}
            onChange={onChange}
            showSorterTooltip={{
              target: "sorter-icon",
            }}
          />
        </Form.Item>
      </Form>

      <Modal
        width={360}
          style={{ textAlign: "center" }}
          title="ARE YOU SURE?"
          open={isModalOpen}
          okText="Confirm"
          okType="danger"
          closable={false}
          centered
          footer={[
            <div className="delete-modal-btn">
            <Button className="delete-btn" key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="delete-btn filter" key="submit" onClick={handleOk}>
              Delete
            </Button>
            </div>
          ]}>
          <p>Do you want to delete this permanently?</p>
        </Modal>

        <Modal
        width={730}
        centered
        open={isViewModalOpen}
        onCancel={hanleViewCancel}
        footer={null}
        title="View Details">
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        {selectedAdmin && (
          <Row gutter={[16]}>
            {/* Property Type and Building */}
            <Col span={6}>
              <p>
                <strong>Name</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{selectedAdmin.name}</p>
            </Col>

            <Col span={6}>
              <p>
                <strong>Email Address</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{selectedAdmin.email}</p>
            </Col>

            <Col span={6}>
              <p>
                <strong>Phone Number</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{selectedAdmin.phone_number}</p>
            </Col>

            <Col span={6}>
              <p>
                <strong>Status</strong>
              </p>
            </Col>
            <Col span={1}>
              <p>:</p>
            </Col>
            <Col span={16}>
              <p>{selectedAdmin.status}</p>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default AdminstratorList;
