import { Form, Input, Button, Divider, Modal } from "antd";
import React, { useState } from "react";
import styles from "./styles.css";
import ZoneList from "../../features/property/ZoneList";
import { AddNewZone } from "../../features";
import AddIcon from "@mui/icons-material/Add";

const { Search } = Input;

const AddZone = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
     <div className="page-new-add">
        <h3 className="page-title">Zone</h3>
        <div className="property-search">
          <Form.Item>
            <Button className="property-add-btn" onClick={showModal}>
              <AddIcon />
              Add New Zone
            </Button>
          </Form.Item>
        </div>
      </div>

      <div className="search-picker-combo">
        <Search
          placeholder="Zone"
          allowClear
          enterButton="Search"
          onSearch={handleSearch}
          size="large"
          style={{ width: 456, marginBottom:"15px" }}
        />
      </div>
        <Form>
          <Form.Item>
            <ZoneList searchTerm={searchTerm}/>
          </Form.Item>
        </Form>

      <Modal
        width={880}
        title="Add New Zone"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <AddNewZone  handleOk={handleOk} handleCancel={handleCancel}  />
      </Modal>
    </>
  );
};

export default AddZone;
