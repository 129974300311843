import {
  Button,
  Table,
  Space,
  Dropdown,
  Modal,
  Form,
  Input,
  message,
  Select,
  Divider,
  ConfigProvider,
} from "antd";
import React, { useState } from "react";
import "./styles.css";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import {
  useDeleteUtilityMutation,
  useGetAllUtilityQuery,
  useUpdateUtilityMutation,
} from "../../features/utility/utilityApi";
import AddIcon from "@mui/icons-material/Add";
import {
  EyeOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import UtilityType from "./UtilityType";

const { Option } = Select;

const UtilityList = () => {
  const token = useSelector((state) => state.authSlice.token2);

  const { data: utlityData, isLoading, error } = useGetAllUtilityQuery(token);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);

  const [form] = Form.useForm();

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [deleteUtility] = useDeleteUtilityMutation();
  const [updateUtility] = useUpdateUtilityMutation();

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteUtility({ utilityId: currentRecord.key }).unwrap();
        setIsModalOpen(false);
        message.success("Utility deleted successfully!");
      } catch (err) {
        console.error("Failed to delete this utility:", err);
        message.error("Failed to delete this utility.");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEditModal = (record) => {
    form.setFieldsValue({
      utilityType: record.utilityType,
      title: record.title,
      unit: record.unit,
      description: record.description,
      rateValue: record.rateValue,
      rateType: record.rateType,
    });
    setCurrentRecord(record);
    setIsViewMode(false);
    setIsEditModalOpen(true);
  };

  const showViewModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      utilityType: record.utilityType,
      title: record.title,
      unit: record.unit,
      description: record.description,
      rateValue: record.rateValue,
      rateType: record.rateType,
    });
    setIsViewMode(true); // Ensure you set view mode first
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        utilityId: currentRecord.key,
        updatedData: {
          utilityType: values.utilityType,
          title: values.title,
          unit: values.unit,
          rate: values.rateValue,
          rate_type: values.rateType,
          description: values.description,
        },
      };
      // Call your update utility function here with updatedData
      await updateUtility(updatedData).unwrap();
      setIsEditModalOpen(false);
      message.success("Utility updated successfully!");
    } catch (err) {
      console.error("Failed to update this utility:", err);
      message.error("Failed to update this utility.");
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => index + 1,
    },
    // {
    //   title: "Utility Type",
    //   dataIndex: "utilityType",
    //   key: "utilityType",
    // },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "30%",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: "10%",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span>
                      <EyeOutlined /> View
                    </span>
                  ),
                  onClick: () => showViewModal(record),
                },
                {
                  key: "2",
                  label: (
                    <span>
                      <EditOutlined /> Edit
                    </span>
                  ),
                  onClick: () => showEditModal(record),
                },
                {
                  key: "3",
                  label: (
                    <span>
                      <DeleteOutlined /> Delete
                    </span>
                  ),
                  onClick: () => showModal(record),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = utlityData?.data?.map((utility, index) => ({
    key: utility._id,
    no: index + 1,
    utilityType:
      utility.utilityType.charAt(0).toUpperCase() +
      utility.utilityType.slice(1),
    rate: `${formatPrice(utility.rate)} ${
      utility.rate_type === "%" ? "%" : utility.rate_type.toUpperCase()
    }`,
    rateValue: utility.rate,
    rateType: utility.rate_type,
    description: utility.description,
    unit: utility.unit,
    title: utility.title,
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleAddOk = () => {
    setIsModalVisible(false);
  };

  const handleAddCancel = () => {
    setIsModalVisible(false);
  };

  const handleUtilityTypeChange = (value) => {
    if (value === "meter") {
      form.setFieldsValue({ unit: "1 Unit" }); // Set "1 Unit" if Meter is selected
    } else if (value === "area") {
      form.setFieldsValue({ unit: "1 sq ft" });
    } else {
      form.setFieldsValue({ unit: "-" });
    }
  };

  return (
    <>
      <div className="page-new-add">
        <h3 className="page-title">Utility List</h3>
        <div className="property-search">
          <Form.Item>
            <Button className="property-add-btn" onClick={showAddModal}>
              <AddIcon />
              Add Type of Utility
            </Button>
          </Form.Item>
        </div>
      </div>

      <Table
        loading={isLoading}
        style={{ marginTop: "15px" }}
        columns={columns}
        pagination={{
          pageSizeOptions: ["10", "20", "30"], // Options for 10, 20, or 50 items per page
          showSizeChanger: true, // Enables the dropdown for selecting page size
          defaultPageSize: 10, // Sets the default page size to 10
        }}
        dataSource={tableData}></Table>

      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button className="delete-btn" key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>

      <Modal
        width={880}
        title={isViewMode ? "View Type of Utility" : "Edit Type of Utility"}
        open={isEditModalOpen}
        closeIcon={null}
        onCancel={handleEditCancel}
        footer={
          isViewMode
            ? null
            : [
                // If it's not in view mode, show the custom footer
                <>
                  <Button
                    type="text"
                    className="btn"
                    key="back"
                    onClick={handleEditCancel}>
                    Cancel
                  </Button>
                  <Button
                    className="btn filter"
                    key="submit"
                    type="primary"
                    onClick={handleEditOk}>
                    Update
                  </Button>
                </>,
              ]
        }>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              utilityType: currentRecord?.utilityType,
              title: currentRecord?.title,
              description: currentRecord?.description,
              unit: currentRecord?.unit,
              rateValue: currentRecord?.rateValue,
              rateType: currentRecord?.rateType,
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flexWrap: "wrap",
              }}>
              <Form.Item
                style={{ width: 400 }}
                name="utilityType"
                label="Utility Type">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select Utility Type"
                  onChange={handleUtilityTypeChange}>
                  <Option value="meter">Meter</Option>
                  <Option value="area">Area</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              <Form.Item style={{ width: 400 }} name="title" label="Title">
                <Input
                  style={{ height: 44 }}
                  placeholder="Title"
                  readOnly={isViewMode}></Input>
              </Form.Item>

              <Form.Item style={{ width: 400 }} name="unit" label="Unit">
                <Input
                  style={{ height: 44 }}
                  placeholder="Unit"
                  readOnly={isViewMode}></Input>
              </Form.Item>

              <Form.Item style={{ width: 400 }} label="Rate">
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="rateValue"
                    noStyle
                    rules={[
                      { required: true, message: "Rate value is required" },
                    ]}>
                    <Input
                      style={{ width: "80%", height: 44 }}
                      placeholder="Rate Value"
                      readOnly={isViewMode}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rateType"
                    noStyle
                    rules={[
                      { required: true, message: "Rate type is required" },
                    ]}>
                    <Select
                      style={{ width: "30%", height: 44 }}
                      placeholder="Rate Type"
                      disabled={isViewMode}>
                      <Option value="MMK">Flat</Option>
                      <Option value="%">Percent</Option>
                    </Select>
                  </Form.Item>
                </Space.Compact>
              </Form.Item>

              <Form.Item
                style={{ width: "100%" }}
                name="description"
                label="Description">
                <TextArea
                  style={{ height: 44 }}
                  placeholder="Description"
                  readOnly={isViewMode}></TextArea>
              </Form.Item>
            </div>
          </Form>
        </ConfigProvider>
      </Modal>

      <Modal
        width={880}
        title="Add Type of Utility"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <UtilityType handleOk={handleAddOk} handleCancel={handleAddCancel} />
      </Modal>
    </>
  );
};

export default UtilityList;
