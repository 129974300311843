import { baseApi } from "../../app/baseApi";

export const tenantCategoryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenantCategory: builder.query({
      query: () => `/Setting/tenant-category/list`,
      providesTags: ["TenantCategoryList"],
    }),

    getTenantCategoryById: builder.query({
      query: (categoryId) => `/Setting/tenant-category/edit/${categoryId}`,
      providesTags: ["TenantCategoryList"],
    }),

    addTenantCategory: builder.mutation({
      query: ({ name, description }) => ({
        url: `/Setting/tenant-category/create`,
        method: "POST",
        body: {
          name,
          description,
        },
      }),
      invalidatesTags: ["TenantCategoryList"],
    }),

    updateTenantCategory: builder.mutation({
      query: ({ categoryId, updatedData }) => ({
        url: `/Setting/tenant-category/update/${categoryId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit contract failed:", error);
      },
      invalidatesTags: ["TenantCategoryList"],
    }),

    deleteTenantCategory: builder.mutation({
      query: ({ categoryId }) => ({
        url: `/Setting/tenant-category/delete/${categoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TenantCategoryList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTenantCategoryQuery,
  useAddTenantCategoryMutation,
  useUpdateTenantCategoryMutation,
  useGetTenantCategoryByIdQuery,
  useDeleteTenantCategoryMutation
} = tenantCategoryApi;
