import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "../features/auth/authSlice";
import navigationSlice from "../features/navigation/navigationSlice";
import { baseApi } from "./baseApi";

// Persist configuration for authSlice
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['email', 'token', 'token2', 'name', 'branches', 'selectedBranch'], // Persist these specific state properties
};

const persistedAuthReducer = persistReducer(authPersistConfig, authSlice);

export const store = configureStore({
  reducer: {
    authSlice: persistedAuthReducer,
    navigationSlice: navigationSlice,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(baseApi.middleware),
  devTools: true,
});

export const persistor = persistStore(store);
