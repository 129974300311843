import { baseApi } from "../../app/baseApi";

export const contractApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllContracts: builder.query({
      query: () => `/Setting/contracts/list`,
      providesTags: ["ContractList"],
    }),

    addNewContract: builder.mutation({
      query: ({ contractName, contractType, contractNumber, aboutToExpire }) => ({
        url: `/Setting/contracts/create`,
        method: "POST",
        body: {
          contractName,
          contractType,
          contractNumber,
          aboutToExpire,
        },
      }),
      invalidatesTags: ["ContractList"],
    }),

    updateContract: builder.mutation({
      query: ({ contractId, updatedData }) => ({
        url: `/Setting/contracts/update/${contractId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit contract failed:", error);
      },
      invalidatesTags: ["ContractList"],
    }),

    deleteContract: builder.mutation({
      query: ({ contractId }) => ({
        url: `/Setting/contracts/delete/${contractId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ContractList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllContractsQuery, useUpdateContractMutation, useDeleteContractMutation, useAddNewContractMutation
} = contractApi;
