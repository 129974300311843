import { Card, Spin } from "antd";
import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useGetAllTenantPropertyPaymentQuery } from "./dashboardApi";
import { useSelector } from "react-redux";

const NumberOfTenant = () => {
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: dashboardData,
    isLoading: isTenantLoading,
    error,
  } = useGetAllTenantPropertyPaymentQuery(token);

  const tenantDataForPieChart = dashboardData?.data?.numberOfTenant;
  const tenantColor = ['#9261D2', '#3BC7D8'];

  const propertyDataForPieChart = dashboardData?.data?.numberOfPropery;
  const propertyColor = ["#28C76F",'#E90A16'];

  const paymentDataForPieChart = dashboardData?.data?.numberOfPaymentType;
  const paymentColor = ['#FF9F43', '#4132E5'];

  const totalTenants =
    (tenantDataForPieChart?.ownCount || 0) +
    (tenantDataForPieChart?.leaseCount || 0);

  const tenantData = [
    {
      label: "Owner",
      value: (
        ((tenantDataForPieChart?.ownCount || 0) / totalTenants) *
        100
      ).toFixed(2),
    },
    {
      label: "Lease",
      value: (
        ((tenantDataForPieChart?.leaseCount || 0) / totalTenants) *
        100
      ).toFixed(2),
    },
  ];

  const propertyData = [
    {
      label: "Available",
      value: (
        ((propertyDataForPieChart?.available || 0) / totalTenants) *
        100
      ).toFixed(2),
    },
    {
      label: "Unavailable",
      value: (
        ((propertyDataForPieChart?.unavailable || 0) / totalTenants) *
        100
      ).toFixed(2),
    }
  ];

  const paymentData = [
    {
      label: "Cash",
      value: (
        ((paymentDataForPieChart?.cash || 0) / totalTenants) *
        100
      ).toFixed(2),
    },
    {
      label: "Digital Payment",
      value: (
        ((paymentDataForPieChart?.digitalPayment || 0) / totalTenants) *
        100
      ).toFixed(2),
    },
  ]

  return (
    <div style={{display: 'flex', flexDirection:"row", flexWrap:'wrap', gap:'20px', justifyContent:"space-between"}}>
      <Card
        style={{ width: "30%", marginBottom: "20px", position: "relative" }}
        title="Number of Tenant">
        {isTenantLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "230px",
            }}>
            <Spin size="large" />
          </div>
        ) : (
          <PieChart
          colors={tenantColor}
            series={[
              {
                data: tenantData,
                innerRadius: 50,
                outerRadius: 90,
                cx: 130,
                cy: 90,
              },
            ]}
            height={250}
            width={300}
            slotProps={{
              legend: {
                direction: "column",
                position: { vertical: "bottom", horizontal: "right" },
                padding: 0,
                labelStyle: {
                  fontSize: 14,
                },
              },
            }}
          />
        )}
      </Card>

      <Card
        style={{ width: "30%", marginBottom: "20px", position: "relative" }}
        title="Number of Property">
        {isTenantLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "230px",
            }}>
            <Spin size="large" />
          </div>
        ) : (
          <PieChart
          colors={propertyColor}
            series={[
              {
                data: propertyData,
                innerRadius: 50,
                outerRadius: 90,
                cx: 130,
                cy: 90,
              },
            ]}
            height={250}
            width={310}
            slotProps={{
              legend: {
                direction: "column",
                position: { vertical: "bottom", horizontal: "right" },
                padding: 0,
                labelStyle: {
                  fontSize: 14,
                },
              },
            }}
          />
        )}
      </Card>

      <Card
        style={{ width: "30%", marginBottom: "20px", position: "relative" }}
        title="Number of Tenant">
        {isTenantLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "230px",
            }}>
            <Spin size="large" />
          </div>
        ) : (
          <PieChart
          colors={paymentColor}
            series={[
              {
                data: paymentData,
                innerRadius: 50,
                outerRadius: 90,
                cx: 130,
                cy: 90,
              },
            ]}
            height={250}
            width={300}
            slotProps={{
              legend: {
                direction: "column",
                position: { vertical: "bottom", horizontal: "right" },
                padding: 0,
                labelStyle: {
                  fontSize: 14,
                },
              },
            }}
          />
        )}
      </Card>
    </div>
  );
};

export default NumberOfTenant;
