import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthLayout from "./layout/auth/AuthLayout";
import MainLayout from "./layout/main/MainLayout";
import CentralizedLayout from "./layout/centralized/CentralizedLayout";
import RequireAuth from "./features/auth/RequireAuth";
import {
  AddBuilding,
  AddFloor,
  AddNewProperty,
  AddNewTenant,
  AddProperty,
  AddShop,
  AddZone,
  ChangePwd,
  Dashboard,
  EditDetail,
  ForgotPwd,
  OwnerDetail,
  OwnerHistory,
  PropertyList,
  SelectBranch,
  SubTenantList,
  SuperAdminSignIn,
  TenantDetails,
  TenantList,
  TransferOwner,
  VerifyOtp,
  ViewDetail,
  AddNewSubTenant,
  AddSubProperty,
  SubTenantDetails,
  UtilityList,
  UtilityType,
  InvoiceList,
  InvoiceBulkUpload,
  InvoicePreview,
  EditInvoice,
  InvoiceDetails,
  MaintenanceList,
  TypesOfIssues,
  MakeInvoice,
  AnnouncementList,
  NotificationList,
  AdminstratorList,
  RolePermission,
  RoleCreate,
  RoleUpdate,
  CentralizedDashboard,
  Contract,
  AddNewAdmin,
  AdminViewDetail,
  TermsNCondition,
  UpdateAdmin,
  EditTenant,
  EditSubTenant,
  CentralizedAdminList,
  InvoiceSetting,
  BranchSetting,
  AddNewCentralizedAdmin,
  UserLogs,
  AuditLogs,
  RolePermissionCentralized,
  AddNewRoleCentralized,
  TenantCategory,
} from "./pages";
import { AddNewBranch, AddNewShop, AddTenantCategory } from "./features";
import "../src/index.css";

const App = () => {
  return (
    <Routes>
      <Route path="sign-in" element={<AuthLayout />}>
        <Route index element={<SuperAdminSignIn />} />
        <Route path="select-branch" element={<SelectBranch />} />
        <Route path="forgot-pwd">
          <Route index element={<ForgotPwd />} />
          <Route path="verify-otp">
            <Route index element={<VerifyOtp />} />
            <Route path="change-pwd" element={<ChangePwd />} />
          </Route>
        </Route>
      </Route>

      <Route
        path="/"
        element={
          <RequireAuth>
            <CentralizedLayout />
          </RequireAuth>
        }>
        <Route index element={<CentralizedDashboard />} />
        <Route path="/adminstrator/list">
        <Route index element={<CentralizedAdminList />}/>
        <Route path="add-new-admin" element={<AddNewCentralizedAdmin/>}/>
        </Route>
        <Route path="/adminstrator/role-permission">
        <Route index element={<RolePermissionCentralized/>}/>
        <Route path="add-new-role" element={<AddNewRoleCentralized/>}/>
        </Route>

        <Route path="reporting">
          <Route path="user-logs" element={<UserLogs/>}/>
          <Route path="audit-logs" element={<AuditLogs/>}/>
        </Route>
        <Route path="setting">
          <Route path="branch">
            <Route index element={<BranchSetting/>} />
            <Route path="add-new-branch" element={<AddNewBranch/>}/>
          </Route>
        
        </Route>
      </Route>

      <Route
        path="/:id"
        element={
          <RequireAuth>
            <MainLayout />
          </RequireAuth>
        }>
        <Route index element={<Dashboard />} />

        <Route path="property">
          <Route path="list">
            <Route index element={<PropertyList />} />
            <Route path="add-new-property" element={<AddNewProperty />} />
            <Route path="view-details" element={<ViewDetail />} />
            <Route path="edit-details/:id" element={<EditDetail />} />
            <Route path="transfer-owner" element={<TransferOwner />} />
            <Route path="owner-history" element={<OwnerHistory />} />
            <Route path="owner-details" element={<OwnerDetail />} />
          </Route>
          <Route path="add-building" element={<AddBuilding />} />
          <Route path="add-floor" element={<AddFloor />} />
          <Route path="add-shop" >
          <Route index element={<AddShop />} />
          <Route path="create-shop" element={<AddNewShop/>}/>
          </Route>
          <Route path="add-zone" element={<AddZone />} />
        </Route>

        <Route path="tenant">
          <Route path="list">
            <Route index element={<TenantList />} />
            <Route path="add-new-tenant" element={<AddNewTenant />} />
            <Route path="add-property" element={<AddProperty />} />
            <Route path="tenant-details" element={<TenantDetails />} />
            <Route path="edit-tenant" element={<EditTenant />} />
          </Route>
          <Route path="sub-list">
            <Route index element={<SubTenantList />} />
            <Route path="add-new-sub-tenant" element={<AddNewSubTenant />} />
            <Route path="add-sub-property" element={<AddSubProperty />} />
            <Route path="sub-tenant-details" element={<SubTenantDetails />} />
            <Route path="edit" element={<EditSubTenant />} />
          </Route>
          <Route path="tenant-category" element={<TenantCategory/>} />
        </Route>

        <Route path="utility">
          <Route path="list" element={<UtilityList />} />
          <Route path="types" element={<UtilityType />} />
        </Route>

        <Route path="invoice">
          <Route path="list">
            <Route index element={<InvoiceList />} />
            <Route
              path="invoice-details/:invoice_id"
              element={<InvoiceDetails />}
            />
          </Route>

          <Route path="preview">
            <Route index element={<InvoicePreview />} />
            <Route path="edit/:invoice_id" element={<EditInvoice />} />
          </Route>
          <Route path="bulk-upload">
            <Route index element={<InvoiceBulkUpload />} />
          </Route>
        </Route>

        <Route path="maintenance">
          <Route path="list">
            <Route index element={<MaintenanceList />} />
            <Route path="make-invoice" element={<MakeInvoice />} />
          </Route>
          <Route path="types-of-issue" element={<TypesOfIssues />} />
        </Route>

        <Route path="announcement">
          <Route path="list" element={<AnnouncementList />} />
        </Route>

        <Route path="notification">
          <Route path="list" element={<NotificationList />} />
        </Route>

        <Route path="adminstrator">
          <Route path="list" element={<AdminstratorList />} />
          <Route path="view-details" element={<AdminViewDetail />} />
          <Route path="add-new-admin" element={<AddNewAdmin />} />
          <Route path="edit" element={<UpdateAdmin />} />
          <Route path="role-permission">
            <Route index element={<RolePermission />} />
            <Route path="view-details" element={<RoleCreate />} />
            <Route path="update-role" element={<RoleUpdate />} />
          </Route>
        </Route>

        <Route path="setting">
          <Route path="contract" element={<Contract />} />
          <Route path="terms" element={<TermsNCondition />} />
          <Route path="invoice" element={<InvoiceSetting/>} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
