import React from "react";
import { Card, Table } from "antd";

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Invoice Type",
    dataIndex: "invoiceType",
    key: "invoiceType",
  },
  {
    title: "Tenant Name",
    dataIndex: "tenantName",
    key: "tenantName",
  },
  {
    title: "Outlet Name",
    dataIndex: "outletName",
    key: "outletName",
  },
  {
    title: "Transaction Type",
    dataIndex: "transcationType",
    key: "transcationType",
  },
  {
    title: "Payment Type",
    dataIndex: "paymentType",
    key: "paymentType",
    filters: [
      {
        text: "Partially",
        value: "Partially",
      },
      {
        text: "Fully",
        value: "Fully",
      },
    ],
    onFilter: (value, record) => record.paymentType.indexOf(value) === 0,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Remaining Amount",
    dataIndex: "remainingAmount",
    key: "remainingAmount",
  },
];

const data = [
  {
    key: "1",
    date: "1/8/2024",
    invoiceType: 'Rental',
    tenantName: "Tenant",
    outletName: "outlet 1",
    transcationType: "Cash",
    paymentType: "Partially",
    amount: '250,000 MMK',
    remainingAmount: '0 MMK',
  },
  {
    key: "2",
    date: "2/8/2024",
    invoiceType: 'Monthly',
    tenantName: "Tenant 1",
    outletName: "outlet 2",
    transcationType: "Cash",
    paymentType: "Fully",
    amount: '250,000 MMK',
    remainingAmount: '0 MMK',
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  console.log("params", pagination, filters, sorter, extra);
};

const App = () => (
  <Card title="Invoice Report" style={{ marginBottom: "20px" }}>
    <Table
      columns={columns}
      dataSource={data}
      onChange={onChange}
      showSorterTooltip={{
        target: "sorter-icon",
      }}
    />
  </Card>
);

export default App;
