import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Upload,
  DatePicker,
  message,
} from "antd";
import styles from "./styles.css";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../../features/tenant/tenantApi";
import moment from "moment";
import { useSelector } from "react-redux";

const { Option } = Select;

const LeaseDetails = ({ propertyData, onBack }) => {
  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const token = useSelector((state) => state.authSlice.token2);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  useEffect(() => {
    if (propertyData?.data?.billing_contact_person?.city) {
      setSelectedCity(propertyData?.data?.billing_contact_person?.city);
    }
  }, [propertyData]);

  const propertyId = propertyData?.data?._id;
  console.log("PropertyID", propertyId);
  

  const downloadContractFile = async (contractFilePath) => {
    try {
      const response = await fetch(
        `https://tms-b.obs.com.mm/api/v1/Tenant/tenants/get-properties-list/edit/${propertyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = contractFilePath;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Form layout="vertical">
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon onClick={onBack} style={{ cursor: "pointer" }} />
            <span style={{ marginLeft: "8px" }}>Lease Shop Information</span>
          </div>
        }>
        <Form.Item label="Outlet Name" className="tenant-label-group">
          <Input
            value={propertyData?.data?.outlet_name}
            readOnly
            style={{ height: 44 }}
            placeholder="Outlet Name"
          />
        </Form.Item>
      </Card>

      <Card title="Billing Contact Person" style={{ marginTop: "15px" }}>
        <div className="add-new-tenant">
          <Form.Item className="tenant-label-group" label="Name">
            <Input
              readOnly
              value={propertyData?.data?.billing_contact_person?.name}
              style={{ height: 44 }}
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Phone Number">
            <Input
              style={{ height: 44 }}
              readOnly
              value={propertyData?.data?.billing_contact_person?.phoneNumber}
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Email">
            <Input
              readOnly
              value={propertyData?.data?.billing_contact_person?.email}
              style={{ height: 44 }}
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="City">
            <Select
              readOnly
              value={propertyData?.data?.billing_contact_person?.city}
              style={{ height: 44 }}
              placeholder="Select city name"
              onChange={(value) => setSelectedCity(value)}
              loading={isCityListLoading}>
              {cityList?.data?.map((city) => (
                <Option key={city._id} value={city._id}>
                  {city?.city_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Township">
            <Select
              readOnly
              value={propertyData?.data?.billing_contact_person?.township}
              style={{ height: 44 }}
              placeholder="Select a township"
              loading={isTownshipListLoading}>
              {townshipList?.data?.map((township) => (
                <Option key={township._id} value={township._id}>
                  {township?.township_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Address" style={{ width: 705 }}>
            <Input
              style={{ height: 44 }}
              readOnly
              value={propertyData?.data?.billing_contact_person?.address}
            />
          </Form.Item>
        </div>
      </Card>

      <Card title="Property Information" style={{ marginTop: "15px" }}>
        <div className="add-new-tenant">
          <Form.Item className="tenant-label-group" label="Branch">
            <Input
              value={propertyData?.data?.shop?.branch?.name}
              readOnly
              style={{ height: 44 }}
              placeholder="Select Branch"
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Building">
            <Input
              value={propertyData?.data?.shop?.building?.name}
              readOnly
              style={{ height: 44 }}
              placeholder="Select Building"
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Floor">
            <Input
              value={propertyData?.data?.shop?.floor?.name}
              readOnly
              style={{ height: 44 }}
              placeholder="Select Floor"
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Zone / Role">
            <Input
              value={propertyData?.data?.shop?.zone?.name}
              readOnly
              style={{ height: 44 }}
              placeholder="Select Zone / Role"
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Shop">
            <Input
              value={propertyData?.data?.shop?.name}
              readOnly
              style={{ height: 44 }}
              placeholder="Shop Name"
            />
          </Form.Item>

          <Form.Item className="tenant-label-group" label="Price (MMK)">
            <Input
              value={propertyData?.data?.shop?.price}
              readOnly
              style={{ height: 44 }}
              placeholder="Price"
            />
          </Form.Item>
        </div>
      </Card>

      <Card title="Contract Information" style={{ marginTop: "15px" }}>
        <div className="add-new-tenant">
          <Form.Item className="contract-label-group" label="Start Date">
            <DatePicker
              style={{ width: 522, height: 44 }}
              value={
                propertyData?.data?.start_date
                  ? moment(propertyData?.data?.start_date)
                  : null
              }
              format="DD-MM-YYYY"
            />
          </Form.Item>

          <Form.Item label="Due Date">
            <DatePicker
              style={{ width: 522, height: 44 }}
              value={
                propertyData?.data?.due_date
                  ? moment(propertyData?.data?.due_date)
                  : null
              }
              format="DD-MM-YYYY"
            />
          </Form.Item>

          <Form.Item label="Contract">
            <div
              style={{
                width: 522,
                padding: "12px",
                border: "1px dashed #d9d9d9",
                textAlign: "center",
                backgroundColor: "#fafafa",
              }}>
              {propertyData?.data?.contract_file ? (
                <button
                  onClick={() =>
                    downloadContractFile(
                      propertyData?.data?.contract_file
                    )
                  }
                  style={{
                    color: "#1890ff",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}>
                  Download Contract: {propertyData?.data?.contract_file}
                </button>
              ) : (
                <p>No contract file uploaded</p>
              )}
            </div>
          </Form.Item>
        </div>
      </Card>
    </Form>
  );
};

export default LeaseDetails;
