import { Form, Input, Button, Select, message, ConfigProvider } from "antd";
import React from "react";
import { useGetAllBuildingQuery, useAddNewFloorMutation } from "./propertyApi";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddNewFloor = ({ handleOk, handleCancel }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const { data: buildingListData, isLoading: isLoadingBuildings, error: isAddingError } =
    useGetAllBuildingQuery(token);

  const [addNewFloor, { isLoading, error }] = useAddNewFloorMutation(token);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const payload = {
      name: values.floorName,
      number_of_shop: values.numberOfShops,
      area: values.area,
      price_per_sq: values.pricePerSq,
      building: values.building,
    };
    try {
      await addNewFloor(payload).unwrap();
      form.resetFields(); // Clear the form fields after successful submission
      message.success("Floor is added successfully.");
      handleOk();
    } catch (isAddingError) {
      const errorMessage = isAddingError?.data?.errors?.[0] || "Failed to add floor";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields when Cancel is clicked
    handleCancel(); // Call parent cancel handler
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            flexWrap: "wrap",
          }}>
          <Form.Item label="Building" name="building">
            <Select
              style={{ height: 44, width: 400 }}
              placeholder="Select a building"
              loading={isLoadingBuildings}>
              {buildingListData?.data?.buildings?.map((building) => (
                <Option key={building._id} value={building._id}>
                  {building.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item style={{ width: 400 }} label="Floor Name" name="floorName">
            <Input style={{ height: 44 }} placeholder="Floor Name" />
          </Form.Item>
          <Form.Item
            style={{ width: 400 }}
            label="Number of Shops"
            name="numberOfShops"
            min={1}>
            <Input
              type="number"
              style={{ height: 44 }}
              placeholder="Number of Shops"
            />
          </Form.Item>
          <Form.Item
            style={{ width: 400 }}
            label="Unit (sq ft)"
            name="area"
            min={1}>
            <Input
              type="number"
              style={{ height: 44 }}
              placeholder="Area (sq ft)"
            />
          </Form.Item>
          <Form.Item
            style={{ width: 400 }}
            label="Price per sq ft (MMK)"
            name="pricePerSq"
            min={1}>
            <Input
              type="number"
              style={{ height: 44 }}
              placeholder="Price per sq ft (MMK)"
            />
          </Form.Item>
        </div>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" onClick={handleFormCancel} className="btn">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="btn filter"
            loading={isLoading}>
            Create
          </Button>
        </Form.Item>
      </Form>
      </ConfigProvider>
    </div>
  );
};

export default AddNewFloor;
