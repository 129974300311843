import React, { useState } from "react";
import { Button, Divider, Form, Input, Modal } from "antd";
import AddIcon from "@mui/icons-material/Add";
import { AddTenantCategory, TenantCategoryList } from "../../features";
import { PageHeader } from "../../component";

const { Search } = Input;
const TenantCategory = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  return (
    <div>
      <PageHeader
        title="Tenant Category List"
        addBtn="Add New Sub Tenant"
      />
      <div className="search-picker-combo">
        <Search
          placeholder="Search..."
          allowClear
          enterButton="Search"
          size="large"
          onSearch={handleSearch}
          style={{
            width: 456,
          }}
        />
      </div>
     <TenantCategoryList searchTerm={searchTerm} />

     <Modal
        width={880}
        title="Add New Tenant Category"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <AddTenantCategory  handleOk={handleOk} handleCancel={handleCancel}  />
      </Modal>
    </div>
  );
};

export default TenantCategory;
