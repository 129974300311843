import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Card } from 'antd';

const data = [
  { x: 'Jan', y: 800 },
  { x: 'Feb', y: 1300 },
  { x: 'Mar', y: 1500 },
  { x: 'Apr', y: 1400 },
  { x: 'May', y: 1000 },
  { x: 'Jun', y: 1600 },
  { x: 'Jul', y: 1700 },
  { x: 'Aug', y: 1650 },
  { x: 'Sep', y: 1600 },
  { x: 'Oct', y: 1800 },
  { x: 'Nov', y: 1790 },
  { x: 'Dec', y: 1900 },
];

const MyChart = () => {
  return (
    <Card title="Tenant Growth" style={{marginBottom:"20px"}}>
    <div style={{ height: 300 }}>
      <LineChart
        xAxis={[{ dataKey: 'x', scaleType: 'point' }]}
        yAxis={[{ min: 0, max: 2000 }]}
        series={[{ dataKey: 'y' }]}
        dataset={data}
      />
    </div>
    </Card>
  );
};

export default MyChart;
