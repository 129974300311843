import {
  ConfigProvider,
  Form,
  Input,
  Select,
  Button,
  message,
  Space,
} from "antd";
import React from "react";
import "./styles.css";
import TextArea from "antd/es/input/TextArea";
import { useAddNewUtilityMutation } from "../../features/utility/utilityApi";

const { Option } = Select;

const UtilityType = ({ handleOk, handleCancel }) => {
  const [form] = Form.useForm();

  const [addNewUtility, { isLoading, error }] = useAddNewUtilityMutation();

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();

      const newUtility = {
        utilityType: values.utilityType,
        title: values.title,
        unit: values.unit,
        rate: parseFloat(values.rateValue),
        rate_type: values.rateType,
        description: values.description,
      };
      await addNewUtility(newUtility).unwrap();
      message.success("Utility created successfully!");
      form.resetFields(); // Reset form after successful submission
      handleOk();
    } catch (err) {
      console.error("Failed to create utility:", err);
      message.error("Failed to create utility.");
    }
  };

  const handleUtilityTypeChange = (value) => {
    if (value === "meter") {
      form.setFieldsValue({ unit: "1 Unit" }); // Set "1 Unit" if Meter is selected
    } else if (value === 'area') {
      form.setFieldsValue({ unit: "1 sq ft" });
    } else {
      form.setFieldsValue({unit: '-'});
    }
  };

  const handleCancelClick = () => {
    form.resetFields(); // Reset form when cancel is clicked
    handleCancel(); // Close the modal
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
        <Form layout="vertical" form={form}>
          <div className="card-group">
            <Form.Item
              name="utilityType"
              label="Utility Type"
              style={{ width: 400 }}>
              <Select
                style={{ height: 44 }}
                placeholder="Select Utility Type"
                onChange={handleUtilityTypeChange}>
                <Option value="meter">Meter</Option>
                <Option value="area">Area</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="title"
              label="Title"
              style={{ width: 400 }}
              className="card-item">
              <Input placeholder="Title" style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              name="unit"
              label="Unit"
              style={{ width: 400 }}
              className="card-item">
              <Input placeholder="Unit" style={{ height: 44 }} />
            </Form.Item>

            <Form.Item style={{ width: 400 }} label="Rate">
              <Space.Compact style={{ width: "100%" }}>
                <Form.Item name="rateValue" noStyle>
                  <Input
                    style={{ width: "80%", height: 44 }}
                    placeholder="Rate Value"
                  />
                </Form.Item>
                <Form.Item name="rateType" noStyle>
                  <Select
                    style={{ width: "30%", height: 44 }}
                    placeholder="Rate Type">
                    <Option value="%">Percent</Option>
                    <Option value="MMK">Flat</Option>
                  </Select>
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </div>

          <Form.Item name="description" label="Description">
            <TextArea placeholder="Description" style={{ height: 44 }} />
          </Form.Item>

          <Form.Item className="add-new-btn-div">
            <Button type="text" className="btn" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button
              className="filter btn"
              onClick={handleCreate}
              loading={isLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </>
  );
};

export default UtilityType;
