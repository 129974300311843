import {
  Form,
  Input,
  Table,
  Modal,
  Select,
  message,
  Button,
  Space,
  Dropdown,
  Col,
  Row,
  Tag,
  Divider,
  ConfigProvider,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllShopsQuery,
  useDeleteShopMutation,
  useUpdateShopMutation,
  useGetAllBuildingQuery,
  useGetFloorsByBuildingIdQuery,
  useGetZoneByBuildingIdQuery,
} from "./propertyApi";
import {
  EditOutlined,
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const ShopList = ({ searchTerm }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const { data: shopListData, isLoading: isLoadingShops } =
    useGetAllShopsQuery(token);
  const [deleteShop] = useDeleteShopMutation();
  const [updateShop] = useUpdateShopMutation();

  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const [form] = Form.useForm();
  const { data: buildingsData, isLoading: isLoadingBuildings } =
    useGetAllBuildingQuery();

  const { data: floorsData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });

  const { data: zonesData, isLoading: isLoadingZones } =
    useGetZoneByBuildingIdQuery(
      { floorId: selectedFloor },
      {
        skip: !selectedFloor,
      }
    );

  useEffect(() => {
    if (selectedBuilding && selectedFloor) {
      console.log("Building ID:", selectedBuilding);
      console.log("Floor ID:", selectedFloor);
    }
  }, [selectedBuilding, selectedFloor]);

  useEffect(() => {
    if (zonesData) {
      console.log("Zones Data:", zonesData);
    }
  }, [zonesData]);

  const [filteredShop, setFilteredShop] = useState([]);

  useEffect(() => {
    if (shopListData) {
      // Filter data based on search term
      const filteredData =
        searchTerm.trim() === ""
          ? shopListData?.data
          : shopListData.data.filter((shop) =>
              shop.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
      setFilteredShop(filteredData);
    }
  }, [searchTerm, shopListData]);

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleViewDetails = (shop) => {
    setSelectedShop(shop);
    setIsViewModalOpen(true);
  };

  const hanleViewCancel = () => {
    setIsViewModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: (shop) => handleViewDetails(shop),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Zone / Role",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Shop Name",
      dataIndex: "shopName",
      key: "shopName",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Total Area (sq ft)",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Price (MMK)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = filteredShop?.map((shop, index) => ({
    key: shop?._id,
    no: index + 1,
    building: shop?.building?.name,
    floor: shop?.floor?.name,
    shopName: shop?.name,
    code: shop?.code,
    area: formatPrice(shop?.total_area),
    price: formatPrice(shop?.price),
    zone: shop?.zone?.name,
    buildingId: shop?.building?._id,
    floorId: shop?.floor?._id,
    zoneId: shop?.zone?._id,
    status: shop.status === 1 ? "Available" : "Unavailable",
  }));

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    try {
      await deleteShop({ shopId: currentRecord.key }).unwrap();
      message.success("Shop deleted successfully!");
    } catch (error) {
      message.error("Failed to delete the shop.");
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setSelectedBuilding(record.buildingId);
    setSelectedFloor(record.floorId)
    setCurrentRecord({
      ...record,
      buildingId: record.buildingId,
      floorId: record.floorId,
      zoneId: record.zoneId,
    });
    form.setFieldsValue({
      building: record.buildingId,
      floor: record.floorId,
      zone: record.zoneId,
      shopName: record.shopName,
      code: record.code,
      area: record.area,
      price: record.price,
    });
    
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    if (currentRecord) {

      // Use selectedFloor and selectedZone directly for their IDs
      const updatedData = {
        shopId: currentRecord.key,
        updatedData: {
          building: form.getFieldValue("building"),
          floor: form.getFieldValue("floor"),
          zone: form.getFieldValue("zone"),
          name: form.getFieldValue("shopName"),
          code: form.getFieldValue("code"),
          total_area: form.getFieldValue("area"),
          price: form.getFieldValue("price"),
        },
      };
      console.log("Updated Data", updatedData);

      try {
        await updateShop(updatedData).unwrap();
        message.success("Shop updated successfully!");
      } catch (error) {
        message.error("Failed to update the shop.");
      } finally {
        setIsEditModalOpen(false);
      }
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    setSelectedFloor("");
    form.setFieldsValue({ floor: undefined, zone: undefined });
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
    form.setFieldsValue({ zone: undefined });
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
            colorBgContainerDisabled: "rgba(0, 0, 0, 0.08)",
          },
        }}>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isLoadingShops}
          pagination={{
            pageSizeOptions: ["10", "20", "30"], // Options for 10, 20, or 50 items per page
            showSizeChanger: true, // Enables the dropdown for selecting page size
            defaultPageSize: 10, // Sets the default page size to 10
          }}
        />

        {/* Delete Modal  */}
        <Modal
          width={360}
          style={{ textAlign: "center" }}
          title="ARE YOU SURE?"
          open={isDeleteModalOpen}
          closable={false}
          centered
          footer={[
            <div className="delete-modal-btn">
              <Button
                className="delete-btn"
                key="back"
                onClick={handleDeleteCancel}>
                Cancel
              </Button>
              <Button
                className="delete-btn filter"
                key="submit"
                onClick={handleDeleteOk}>
                Delete
              </Button>
            </div>,
          ]}>
          <p>Do you want to delete this permanently?</p>
        </Modal>

        {/* Edit Modal */}
        <Modal
          onCancel={handleEditCancel}
          width={880}
          title="Edit Shop"
          open={isEditModalOpen}
          footer={[
            <>
              <Button
                type="text"
                className="btn"
                key="back"
                onClick={handleEditCancel}>
                Cancel
              </Button>
              <Button
                className="btn filter"
                key="submit"
                onClick={handleEditOk}>
                Update
              </Button>
            </>,
          ]}>
          <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
          <Form form={form} layout="vertical">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flexWrap: "wrap",
              }}>
              <Form.Item name="building" label="Building">
                <Select
                  placeholder="Building"
                  style={{ height: 44, width: 400 }}
                  onChange={handleBuildingChange}
                  loading={isLoadingBuildings}>
                  {buildingsData?.data?.buildings?.map((building) => (
                    <Select.Option key={building._id} value={building._id}>
                      {building.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Floor Name" name="floor">
                <Select
                  style={{ height: 44, width: 400 }}
                  placeholder="Select a floor"
                  onChange={handleFloorChange}
                  disabled={!selectedBuilding}
                  loading={isLoadingFloors}>
                  {floorsData?.data?.map((floor) => (
                    <Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="zone" label="Zone / Role">
                <Select
                  placeholder="Zone / Role"
                  style={{ height: 44, width: 400 }}
                  loading={isLoadingZones}
                  disabled={!selectedBuilding || !selectedFloor}>
                  {zonesData?.data?.map((zone) => (
                    <Select.Option key={zone._id} value={zone._id}>
                      {zone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ width: 400 }}
                name="shopName"
                label="Shop Name">
                <Input style={{ height: 44 }} placeholder="Enter Shop Name" />
              </Form.Item>

              <Form.Item style={{ width: 400 }} name="code" label="Code">
                <Input style={{ height: 44 }} placeholder="Enter Code" />
              </Form.Item>

              <Form.Item style={{ width: 400 }} name="area" label="Total Area">
                <Input style={{ height: 44 }} placeholder="Enter Total Area" />
              </Form.Item>

              <Form.Item style={{ width: 400 }} name="price" label="Price">
                <Input style={{ height: 44 }} placeholder="Enter Price" />
              </Form.Item>
            </div>
          </Form>
        </Modal>

        <Modal
          width={730}
          centered
          open={isViewModalOpen}
          onCancel={hanleViewCancel}
          footer={null}
          title="View Details">
          <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
          {selectedShop && (
            <Row gutter={[16, 16]}>
              {/* Property Type and Building */}
              <Col span={4}>
                <p>
                  <strong>Building</strong>
                </p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={8}>
                <p>{selectedShop.building}</p>
              </Col>
              <Col span={4}>
                <p>
                  <strong>Shop Code</strong>
                </p>
              </Col>

              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={6}>
                <p>{selectedShop.code}</p>
              </Col>

              {/* Shop Name and Floor */}
              <Col span={4}>
                <p>
                  <strong>Floor</strong>
                </p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={8}>
                <p>{selectedShop.floor}</p>
              </Col>
              <Col span={4}>
                <p>
                  <strong>Total Area</strong>
                </p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={6}>
                <p>{selectedShop.area}</p>
              </Col>
              <Col span={4}>
                <p>
                  <strong>Zone</strong>
                </p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={8}>
                <p>{selectedShop.zone}</p>
              </Col>
              <Col span={4}>
                <p>
                  <strong>Price</strong>
                </p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={6}>
                <p>{selectedShop.price}</p>
              </Col>

              {/* Total Area and Status */}
              <Col span={4}>
                <p>
                  <strong>Shop Name</strong>
                </p>
              </Col>
              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={8}>
                <p>{selectedShop.shopName} sq ft</p>
              </Col>
              <Col span={4}>
                <p>
                  <strong>Status</strong>
                </p>
              </Col>

              <Col span={1}>
                <p>:</p>
              </Col>
              <Col span={6}>
                <p>
                  <Tag
                    color={
                      selectedShop.status === "Available" ? "green" : "red"
                    }>
                    {selectedShop.status}
                  </Tag>
                </p>
              </Col>
            </Row>
          )}
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default ShopList;
