import { Card, Form, Input, Button, Breadcrumb, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAddNewBranchMutation } from "./branchSettingApi";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../../tenant/tenantApi";

const { Option } = Select;

const AddNewBranch = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.authSlice.token);
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  const [addNewBranch, { isLoading, error }] = useAddNewBranchMutation(token);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue({ township: undefined });
  }, [selectedCity, form]);

  const handleSubmit = async (values) => {
    const payload = {
      name: values.branchName,
      contact_person: values.contactPerson,
      email: values.emailAddress,
      phone_number: values.phoneNumber,
      city: values.city,
      township: values.township,
      address: 'Testing Address',
    };
    try {
      await addNewBranch(payload).unwrap();
      form.resetFields();
      message.success("New Branch is created successfully.");
    } catch (err) {
      message.error("Failed to add new branch.");
    }
  };

  return (
    <div>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/setting/branch`)}>
          Branch Setting
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Branch</Breadcrumb.Item>
      </Breadcrumb>
      <Card title="Create New Branch">
        <Form onFinish={handleSubmit} layout="vertical">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}>
            <Form.Item style={{ width: 350 }} label="Branch" name="branchName">
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              style={{ width: 350 }}
              label="Contact Person"
              name="contactPerson">
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              style={{ width: 350 }}
              label="Email Address"
              name="emailAddress">
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              style={{ width: 350 }}
              label="Phone Number"
              name="phoneNumber">
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
              style={{ width: 350 }}
              className="tenant-label-group">
              <Select
                style={{ height: 44 }}
                placeholder="Select city name"
                onChange={(value) => setSelectedCity(value)}
                loading={isCityListLoading}>
                {cityList?.data?.map((city) => (
                  <Option key={city._id} value={city._id}>
                    {city?.city_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
             style={{ width: 350 }}
              name="township"
              label="Township"
              className="tenant-label-group">
              <Select
                style={{ height: 44 }}
                placeholder="Select a township"
                loading={isTownshipListLoading}>
                {townshipList?.data?.map((township) => (
                  <Option key={township._id} value={township._id}>
                    {township?.township_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}>
            <Button onClick={() => navigate(`/setting/branch`)} className="btn">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn filter"
              loading={isLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AddNewBranch;
