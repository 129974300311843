import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  Checkbox,
  DatePicker,
  message,
  Button,
  Breadcrumb,
  ConfigProvider,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "./styles.css";
import {
  useAddNewPropertyMutation,
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../features/tenant/tenantApi";
import {
  useGetAllBuildingQuery,
  useGetFloorsByBuildingIdQuery,
  useGetShopByZoneIdQuery,
  useGetZoneByBuildingIdQuery,
} from "../../features/property/propertyApi";
import { isJPGorPNG } from "../../utils";
import { useGetAllContractsQuery } from "../../features/setting/contractApi";

const { Dragger } = Upload;
const { Option } = Select;

const AddProperty = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageObj, setImageObj] = useState(null);
  const [imgUrl, setImgUrl] = useState("");
  const [fileList, setFileList] = useState([]);
  const [contractFileList, setContractFileList] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedZone, setSelectedZone] = useState("");

  const location = useLocation();
  const tenant = location.state?.tenant;

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );
    
  const { data: contractTypeList, isLoading: isContractTypeLoading } =
    useGetAllContractsQuery();

  const [
    addNewProperty,
    { isLoading: isAddingProperty, error: addTenantError },
  ] = useAddNewPropertyMutation();

  const { data: BuildingList, isLoading: isBuildingListLoading } =
    useGetAllBuildingQuery();

  const { data: floorList, isLoading: isFloorListLoading } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });

  const { data: zoneList, isLoading: isLoadingZones } =
    useGetZoneByBuildingIdQuery(
      { floorId: selectedFloor },
      {
        skip: !selectedFloor,
      }
    );

  const { data: shopList, isLoading: isShopListLoading } =
    useGetShopByZoneIdQuery(
      { zoneId: selectedZone },
      {
        skip: !selectedZone,
      }
    );

  useEffect(() => {
    form.setFieldValue({ township: undefined });
  }, [selectedCity, form]);

  useEffect(() => {
    if (selectedBuilding && selectedFloor) {
      console.log("Building ID:", selectedBuilding);
      console.log("Floor ID:", selectedFloor);
    }
  }, [selectedBuilding, selectedFloor]);

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setImgUrl(reader.result);
      },
      false
    );
    if (imageObj) {
      reader.readAsDataURL(imageObj);
    }
  }, [imageObj]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.originFileObj) {
        return {
          ...file,
          preview: URL.createObjectURL(file.originFileObj),
        };
      }
      return file;
    });

    setFileList(newFileList);
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return false;
  };

  const handleRadioChange = (e) => {
    console.log("Selected value:", e.target.value); // Log the selected value
    form.setFieldsValue({ type: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({
        billingName: tenant.tenantName,
        billingPhoneNumber: tenant.phNum,
        billingEmail: tenant.email,
        billingCity: tenant.city,
        billingTownship: tenant.township,
        billingAddress: tenant.address,
      });
    } else {
      form.resetFields([
        "billingName",
        "billingPhoneNumber",
        "billingEmail",
        "billingCity",
        "billingTownship",
        "billingAddress",
      ]);
    }
  };

  const handleFileChange = ({ fileList }) => {
    setContractFileList(fileList);
  };

  const handleBeforeFileUpload = (file) => {
    return false;
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        outlet_name: values?.outletName,
        type: values?.type,
        billing_contact_person: {
          name: values.billingName,
          phoneNumber: values.billingPhoneNumber,
          email: values.billingEmail,
          city: values.billingCity,
          township: values.billingTownship,
          address: values.billingAddress,
        },
        shop_id: values.shop,
        contract_info: {
          contract_name: values.contract_name, // Extract contract name
          contract_id: values.contract_id, // Extract contract ID
          start_date: values.start_date,
          due_date: values.due_date,
          contract_file: contractFileList[0]?.originFileObj,
        },
      };
      console.log("payload", payload);

      await addNewProperty({ params: payload, tenantId: tenant.key }).unwrap();
      message.success("Property created successfully");
      navigate(`/${id}/tenant/list`);
    } catch (error) {
      message.error(`Not available to create this property.`);
    }
  };

  const handleShopChange = (shopId) => {
    const selectedShop = shopList?.data?.find(
      (shopItem) => shopItem?._id === shopId
    );

    form.setFieldsValue({
      code: selectedShop?.code || "",
      area: selectedShop?.total_area || "",
      price: selectedShop?.price || "",
    });
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/tenant/list`)}>
          Tenant List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add Property</Breadcrumb.Item>
      </Breadcrumb>
      <Form
        initialValues={{ type: 2 }}
        form={form}
        layout="vertical"
        onFinish={onFinish}>
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginLeft: "8px" }}>Shop Information</span>
            </div>
          }
          extra={
            <div style={{ paddingTop: "25px" }}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Radio.Group onChange={handleRadioChange} name="radiogroup">
                  <Radio value={1}>Lease</Radio>
                  <Radio value={2}>Owner</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          }>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="billing-contact-form">
              <Form.Item
                label="Outlet Name"
                className="tenant-label-group"
                name="outletName">
                <Input style={{ height: 44 }} placeholder="Outlet Name" />
              </Form.Item>
            </div>
            <Form.Item>
              <Checkbox onChange={handleCheckboxChange}>
                Billing contact person same as tenant info.
              </Checkbox>
            </Form.Item>
          </ConfigProvider>
        </Card>

        <Card title="Billing Contact Person" style={{ marginTop: "15px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="add-new-tenant">
              <Form.Item
                className="tenant-label-group"
                label="Name"
                name="billingName">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Phone Number"
                name="billingPhoneNumber">
                <Input style={{ height: 44 }} placeholder="Phone Number" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Email"
                name="billingEmail">
                <Input style={{ height: 44 }} placeholder="Email" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="City"
                name="billingCity">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select city name"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isCityListLoading}>
                  {cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Township"
                name="billingTownship">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a township"
                  loading={isTownshipListLoading}>
                  {townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Address"
                style={{ width: 705 }}
                name="billingAddress">
                <Input style={{ height: 44 }} placeholder="Address" />
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Card title="Property Information" style={{ marginTop: "15px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="add-new-tenant">
              <Form.Item className="tenant-label-group" label="Building">
                <Select
                  onChange={(value) => setSelectedBuilding(value)}
                  style={{ height: 44 }}
                  placeholder="Select Building">
                  {BuildingList?.data?.buildings?.map((building) => (
                    <Select.Option key={building._id} value={building._id}>
                      {building.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item className="tenant-label-group" label="Floor">
                <Select
                  onChange={(value) => setSelectedFloor(value)}
                  style={{ height: 44 }}
                  placeholder="Select Floor">
                  {floorList?.data.map((floor) => (
                    <Select.Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item className="tenant-label-group" label="Zone / Role">
                <Select
                  onChange={(value) => setSelectedZone(value)}
                  style={{ height: 44 }}
                  placeholder="Select Zone / Role">
                  {zoneList?.data?.map((zone) => (
                    <Select.Option key={zone._id} value={zone._id}>
                      {zone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[{ required: true }]}
                className="tenant-label-group"
                name="shop"
                label="Shop Name">
                <Select
                  style={{ height: 44 }}
                  onChange={handleShopChange}
                  placeholder="Select shop name"
                  loading={isShopListLoading}>
                  {shopList?.data?.map((shop) => (
                    <Option key={shop._id} value={shop._id}>
                      {shop?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="code"
                label="Shop Code">
                <Input style={{ height: 44 }} placeholder="Shop Code"></Input>
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="area"
                label="Total Area (sq ft)">
                <Input style={{ height: 44 }} placeholder="Total Area"></Input>
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="price"
                label="Price (MMK)">
                <Input style={{ height: 44 }} placeholder="Price"></Input>
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Card title="Contract Information" style={{ marginTop: "15px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="contract-form">
              <Form.Item
                style={{ width: "100%" }}
                className="contract-label-group"
                name="contract_id" // This will store the contract ID
                label="Contract Name">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a contract"
                  loading={isContractTypeLoading}
                  onChange={(value, option) => {
                    form.setFieldsValue({
                      contract_name: option["data-contractname"], // Set the contract name in the form
                    });
                  }}>
                  {contractTypeList?.data?.map((contract) => (
                    <Option
                      key={contract._id}
                      value={contract._id}
                      data-contractname={contract.contractName} // Storing contractName
                    >
                      {contract?.contractName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="contract_name"
                style={{ display: "none" }} // Hidden field to store contract_name
              >
                <Input />
              </Form.Item>

              <Form.Item name="start_date" label="Start Date">
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="Start Date"
                />
              </Form.Item>

              <Form.Item name="due_date" label="Due Date">
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="Due Date"
                />
              </Form.Item>

              <Form.Item name="contract_file" label="Contract (PDF or JPG)">
                <Dragger
                  onChange={handleFileChange}
                  beforeUpload={handleBeforeFileUpload}
                  multiple={false}
                  maxCount={1}
                  listType="text"
                  accept=".pdf, .jpg, .jpeg, .png"
                  style={{ width: 1060, height: 44 }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single upload.
                  </p>
                </Dragger>
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Form.Item>
          <div className="add-tenant-btn-group">
            <Button
              onClick={() => navigate(`/${id}/tenant/list`)}
              className="btn">
              Cancel
            </Button>
            <Button
              type="text"
              htmlType="submit"
              loading={isAddingProperty}
              disabled={isAddingProperty}
              className="btn filter">
              Add
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddProperty;
