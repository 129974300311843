import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Avatar,
  Button,
  Dropdown,
  Modal,
  message,
  Select,
  Divider,
  ConfigProvider,
} from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";
import NotificaitonIcon from "../../assets/icons/notification.svg";
import DashboardIcon from "../../assets/icons/dashboard.svg";
import TenantIcon from "../../assets/icons/tenant.svg";
import PropertyIcon from "../../assets/icons/property.svg";
import UtilityIcon from "../../assets/icons/utility.svg";
import InvoiceIcon from "../../assets/icons/invoice.svg";
import MaintenanceIcon from "../../assets/icons/maintenance.svg";
import AnnouncementIcon from "../../assets/icons/announcement.svg";
import AdminIcon from "../../assets/icons/admin.svg";
import SettingIcon from "../../assets/icons/setting.svg";
import "./main.css";
import {
  logoutAccount,
  setSelectedBranch,
  setToken2,
} from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { setCanNavigateToCentralized } from "../../features/navigation/navigationSlice";
import { useGetBranchDataQuery } from "../../features/auth/authApi";
import Logo from "../../assets/Logo -01 1.png";
import ExternalLinkIcon from "../../assets/externalLink.svg";
import SelectedDashboard from "../../assets/icons/selectedDashboard.svg";

const { Option } = Select;
const { Header, Content, Sider } = Layout;

const siderStyle = {
  font: "16px",
  overflow: "auto",
  minHeight: "100vh",
  position: "fixed",
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: "thin",
  scrollbarColor: "unset",
  transition: "width 0.2s",
};

function getItem(label, key, icon, path, children) {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
  };
}

const App = () => {
  const userName = useSelector((state) => state.authSlice.name);
  const navigationState = useSelector((state) => state.navigationSlice);
  const branches = useSelector((state) => state.authSlice.branches);
  const [selectedKey, setSelectedKey] = useState("1");

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const selectedBranchId = useSelector(
    (state) => state.authSlice.selectedBranch
  );

  const {
    data: branchData,
    isLoading,
    error,
  } = useGetBranchDataQuery(selectedBranchId, {
    skip: !selectedBranchId,
  });

  const canNavigateToCentralized = navigationState?.canNavigateToCentralized;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  useEffect(() => {
    if (branchData?.data?.token) {
      dispatch(setToken2(branchData.data.token));
    }
  }, [branchData, dispatch]);

  const LogoutModalForm = ({ visible, handleOk, handleCancel }) => (
    <Modal
      title="Are you sure you want to logout?"
      open={visible}
      okButtonProps={{ type: "danger" }}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
    />
  );

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const permissions = branchData?.data?.user?.permissions || [];

  const checkPermission = useCallback(
    (permissions, moduleName, action) => {
      const module = permissions?.find(
        (perm) => perm.module_name.toLowerCase() === moduleName.toLowerCase()
      );
      return module ? module[action] : false;
    },
    [permissions]
  );

  const generateMenuItems = (permissions, selectedKey) => {
    return [
      (checkPermission(permissions, "Dashboard", "read") ||
        checkPermission(permissions, "Dashboard", "create") ||
        checkPermission(permissions, "Dashboard", "write") ||
        checkPermission(permissions, "Dashboard", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>Dashboard</span>,
          "1",
          <img
            src={selectedKey === "1" ? SelectedDashboard : DashboardIcon}
            alt="Dashboard Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          `/${selectedBranchId}`
        ),

      (checkPermission(permissions, "Tenant", "read") ||
        checkPermission(permissions, "Tenant", "write") ||
        checkPermission(permissions, "Tenant", "create") ||
        checkPermission(permissions, "Tenant", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>Tenant</span>,
          "sub1",
          <img
            src={TenantIcon}
            alt="Tenant Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          [
            (checkPermission(permissions, "Tenant", "read") ||
              checkPermission(permissions, "Tenant", "write") ||
              checkPermission(permissions, "Tenant", "create") ||
              checkPermission(permissions, "Tenant", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Tenant List
                </span>,
                "2",
                null,
                "tenant/list"
              ),
            (checkPermission(permissions, "Tenant", "read") ||
              checkPermission(permissions, "Tenant", "write") ||
              checkPermission(permissions, "Tenant", "create") ||
              checkPermission(permissions, "Tenant", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Sub Tenant List
                </span>,
                "3",
                null,
                "tenant/sub-list"
              ),

              (checkPermission(permissions, "Tenant", "read") ||
              checkPermission(permissions, "Tenant", "write") ||
              checkPermission(permissions, "Tenant", "create") ||
              checkPermission(permissions, "Tenant", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Tenant Category
                </span>,
                "31",
                null,
                "tenant/tenant-category"
              ),
          ]
        ),

      (checkPermission(permissions, "Property", "read") ||
        checkPermission(permissions, "Property", "write") ||
        checkPermission(permissions, "Property", "create") ||
        checkPermission(permissions, "Property", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>Property</span>,
          "sub2",
          <img
            src={PropertyIcon}
            alt="Property Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          [
            (checkPermission(permissions, "Property", "read") ||
              checkPermission(permissions, "Property", "write") ||
              checkPermission(permissions, "Property", "create") ||
              checkPermission(permissions, "Property", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Property List
                </span>,
                "4",
                null,
                "property/list"
              ),

            (checkPermission(permissions, "Property", "read") ||
              checkPermission(permissions, "Property", "write") ||
              checkPermission(permissions, "Property", "create") ||
              checkPermission(permissions, "Property", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Add Building
                </span>,
                "5",
                null,
                "property/add-building"
              ),

            (checkPermission(permissions, "Property", "read") ||
              checkPermission(permissions, "Property", "write") ||
              checkPermission(permissions, "Property", "create") ||
              checkPermission(permissions, "Property", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Add Floor
                </span>,
                "6",
                null,
                "property/add-floor"
              ),

            (checkPermission(permissions, "Property", "read") ||
              checkPermission(permissions, "Property", "write") ||
              checkPermission(permissions, "Property", "create") ||
              checkPermission(permissions, "Property", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Add Zone / Role
                </span>,
                "7",
                null,
                "property/add-zone"
              ),

            (checkPermission(permissions, "Property", "read") ||
              checkPermission(permissions, "Property", "write") ||
              checkPermission(permissions, "Property", "create") ||
              checkPermission(permissions, "Property", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Add Shop
                </span>,
                "8",
                null,
                "property/add-shop"
              ),
          ]
        ),

      (checkPermission(permissions, "Utility_Bill", "read") ||
        checkPermission(permissions, "Utility_Bill", "write") ||
        checkPermission(permissions, "Utility_Bill", "create") ||
        checkPermission(permissions, "Utility_Bill", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>
            Utility Bill
          </span>,
          "sub3",
          <img
            src={UtilityIcon}
            alt="Property Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          [
            (checkPermission(permissions, "Utility_Bill", "read") ||
              checkPermission(permissions, "Utility_Bill", "write") ||
              checkPermission(permissions, "Utility_Bill", "create") ||
              checkPermission(permissions, "Utility_Bill", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Utility List
                </span>,
                "9",
                null,
                "utility/list"
              ),
          ]
        ),

      (checkPermission(permissions, "Invoice", "read") ||
        checkPermission(permissions, "Invoice", "write") ||
        checkPermission(permissions, "Invoice", "create") ||
        checkPermission(permissions, "Invoice", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>Invoice</span>,
          "sub4",
          <img
            src={InvoiceIcon}
            alt="Invoice Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          [
            (checkPermission(permissions, "Invoice", "read") ||
              checkPermission(permissions, "Invoice", "write") ||
              checkPermission(permissions, "Invoice", "create") ||
              checkPermission(permissions, "Invoice", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Invoice List
                </span>,
                "11",
                null,
                "invoice/list"
              ),

            (checkPermission(permissions, "Invoice", "read") ||
              checkPermission(permissions, "Invoice", "write") ||
              checkPermission(permissions, "Invoice", "create") ||
              checkPermission(permissions, "Invoice", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Preview Invoice
                </span>,
                "30",
                null,
                "invoice/preview"
              ),

            (checkPermission(permissions, "Invoice", "read") ||
              checkPermission(permissions, "Invoice", "write") ||
              checkPermission(permissions, "Invoice", "create") ||
              checkPermission(permissions, "Invoice", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Bulk Upload
                </span>,
                "12",
                null,
                "invoice/bulk-upload"
              ),
          ]
        ),

      (checkPermission(permissions, "Maintenance", "read") ||
        checkPermission(permissions, "Maintenance", "write") ||
        checkPermission(permissions, "Maintenance", "create") ||
        checkPermission(permissions, "Maintenance", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>
            Maintenance
          </span>,
          "sub5",
          <img
            src={MaintenanceIcon}
            alt="Dashboard Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          [
            (checkPermission(permissions, "Maintenance", "read") ||
              checkPermission(permissions, "Maintenance", "write") ||
              checkPermission(permissions, "Maintenance", "create") ||
              checkPermission(permissions, "Maintenance", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Maintenance List
                </span>,
                "13",
                null,
                "maintenance/list"
              ),

            (checkPermission(permissions, "Maintenance", "read") ||
              checkPermission(permissions, "Maintenance", "write") ||
              checkPermission(permissions, "Maintenance", "create") ||
              checkPermission(permissions, "Maintenance", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Types of Issue
                </span>,
                "14",
                null,
                "maintenance/types-of-issue"
              ),
          ]
        ),

      (checkPermission(permissions, "Announcement", "read") ||
        checkPermission(permissions, "Announcement", "write") ||
        checkPermission(permissions, "Announcement", "create") ||
        checkPermission(permissions, "Announcement", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>
            Announcement
          </span>,
          "sub6",
          <img
            src={AnnouncementIcon}
            alt="Announcement Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          (checkPermission(permissions, "Announcement", "read") ||
            checkPermission(permissions, "Announcement", "write") ||
            checkPermission(permissions, "Announcement", "create") ||
            checkPermission(permissions, "Announcement", "delete")) && [
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Announcement List
              </span>,
              "15",
              null,
              "announcement/list"
            ),
          ]
        ),

      (checkPermission(permissions, "Notification", "read") ||
        checkPermission(permissions, "Notification", "write") ||
        checkPermission(permissions, "Notification", "create") ||
        checkPermission(permissions, "Notification", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>
            Notification
          </span>,
          "sub7",
          <img
            src={NotificaitonIcon}
            alt="Notification Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          (checkPermission(permissions, "Notification", "read") ||
            checkPermission(permissions, "Notification", "write") ||
            checkPermission(permissions, "Notification", "create") ||
            checkPermission(permissions, "Notification", "delete")) && [
            getItem(
              <span style={{ fontSize: "16px", fontWeight: 400 }}>
                Notification Lists
              </span>,
              "16",
              null,
              "notification/list"
            ),
          ]
        ),

      (checkPermission(permissions, "Administrator", "read") ||
        checkPermission(permissions, "Administrator", "write") ||
        checkPermission(permissions, "Administrator", "create") ||
        checkPermission(permissions, "Administrator", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>
            Adminstrator
          </span>,
          "sub8",
          <img
            src={AdminIcon}
            alt="Admin Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          [
            (checkPermission(permissions, "Administrator", "read") ||
              checkPermission(permissions, "Administrator", "write") ||
              checkPermission(permissions, "Administrator", "create") ||
              checkPermission(permissions, "Administrator", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Administrator List
                </span>,
                "17",
                null,
                "adminstrator/list"
              ),

            (checkPermission(permissions, "Administrator", "read") ||
              checkPermission(permissions, "Administrator", "write") ||
              checkPermission(permissions, "Administrator", "create") ||
              checkPermission(permissions, "Administrator", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Role & Permission
                </span>,
                "18",
                null,
                "adminstrator/role-permission"
              ),
          ]
        ),

      (checkPermission(permissions, "Setting", "read") ||
        checkPermission(permissions, "Setting", "write") ||
        checkPermission(permissions, "Setting", "create") ||
        checkPermission(permissions, "Setting", "delete")) &&
        getItem(
          <span style={{ fontSize: "16px", fontWeight: 400 }}>Setting</span>,
          "sub9",
          <img
            src={SettingIcon}
            alt="Setting Icon"
            style={{ width: "18px", height: "18px" }}
          />,
          null,
          [
            (checkPermission(permissions, "Setting", "read") ||
              checkPermission(permissions, "Setting", "write") ||
              checkPermission(permissions, "Setting", "create") ||
              checkPermission(permissions, "Setting", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Invoice Setting
                </span>,
                "24",
                null,
                "setting/invoice"
              ),

            (checkPermission(permissions, "Setting", "read") ||
              checkPermission(permissions, "Setting", "write") ||
              checkPermission(permissions, "Setting", "create") ||
              checkPermission(permissions, "Setting", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Contract Setting
                </span>,
                "28",
                null,
                "setting/contract"
              ),

            (checkPermission(permissions, "Setting", "read") ||
              checkPermission(permissions, "Setting", "write") ||
              checkPermission(permissions, "Setting", "create") ||
              checkPermission(permissions, "Setting", "delete")) &&
              getItem(
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  Terms and Conditions
                </span>,
                "29",
                null,
                "setting/terms"
              ),
          ]
        ),
    ].filter(Boolean);
  };

  const items = useMemo(() => generateMenuItems(permissions), [permissions]);

  const profileItems = [
    {
      label: <a>Edit Profile</a>,
      key: "edit",
    },
    {
      label: <a onClick={() => setLogoutModalVisible(true)}>Log out</a>,
      key: "logout",
    },
  ];

  const handleLogout = () => {
    dispatch(logoutAccount());
    navigate("/login");
    message.success("You have successfully logged out.");
  };

  const handleCancelLogout = () => {
    setLogoutModalVisible(false);
  };
  useEffect(() => {
    if (branchData?.data?.token) {
      dispatch(setToken2(branchData.data.token));
      // Set canNavigateToCentralized based on permissions or some condition
      dispatch(setCanNavigateToCentralized(true)); // or false based on logic
    }
  }, [branchData, dispatch]);

  useEffect(() => {
    // Load the initial state for canNavigateToCentralized from localStorage or an API
    const canNavigate = localStorage.getItem("canNavigateToCentralized");
    if (canNavigate) {
      dispatch(setCanNavigateToCentralized(JSON.parse(canNavigate)));
    }
  }, [dispatch]);

  const handleNavigateToCentralized = () => {
    if (canNavigateToCentralized) {
      navigate("/");
    } else {
      message.error(
        "You are not allowed to navigate to the Centralized Dashboard."
      );
    }
  };

  const handleBranchChange = (value) => {
    dispatch(setToken2(null));
    dispatch(setSelectedBranch(value));
    navigate(`/${value}`);
  };

  console.log("Selected Key", selectedKey);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerBg: "box-shadow: 0px 3px 10px 0px #00000033;",
              headerPadding: 0,
              siderBg:
                "linear-gradient(128.88deg, #03045E 1.86%, #0057FF 100%)",
            },
            Menu: {
              itemSelectedBg: "#FFFFFF",
              itemBg: "transparent",
              // itemHoverColor: "black",
              itemSelectedColor: "black",
              itemColor: collapsed ? "black" : "white",
              subMenuItemBg: "#e6f4ff",
              itemBorderRadius: "4px",
            },
          },
        }}>
        <Layout hasSider>
          <Sider
            style={siderStyle}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            width={280} // Set the width of the sidebar when expanded
            collapsedWidth={80} // Set the width of the sidebar when collapsed
          >
            <div
              style={{
                height: 100,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div style={{ margin: "auto" }}>
                {!collapsed && (
                  <img
                    style={{ width: 120, height: 52 }}
                    src={Logo}
                    alt="Logo"
                  />
                )}
              </div>
              <div>
                <Button
                  style={{
                    border: "1px solid white",
                    width: "15px",
                    height: "50px",
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                    background: "#FFFFFF",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                  }}
                  onClick={toggleCollapsed}>
                  <div style={{ margin: "auto" }}>
                    {collapsed ? <RightOutlined /> : <LeftOutlined />}
                  </div>
                </Button>
              </div>
            </div>
            <Divider
              style={{ backgroundColor: "white", margin: 0, marginBottom: 20 }}
            />
            <div className="demo-logo-vertical" />
            <Menu
              selectedKeys={[selectedKey]}
              onClick={handleMenuClick}
              mode="inline"
              items={items}
            />
          </Sider>

          <Layout
            style={{
              marginInlineStart: collapsed ? 80 : 280, // Adjust margin when collapsed
            }}>
            <Header
              style={{
                margin: "20px",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
              <div className="header">
                <div className="setting">
                  <Select
                    suffixIcon={<DownOutlined />}
                    value={selectedBranchId} // Set default value
                    style={{ width: "147px", height: "39px" }}
                    onChange={handleBranchChange}>
                    {branches.map((branch) => (
                      <Option key={branch._id} value={branch._id}>
                        {branch?.name}
                      </Option>
                    ))}
                  </Select>
                  <Button
                    style={{ width: "147px", height: "39px" }}
                    onClick={handleNavigateToCentralized}>
                    Main Dashboard
                    <img
                      src={ExternalLinkIcon}
                      alt="External Link Icon"
                      style={{ width: "18px", height: "18px" }}
                    />
                  </Button>
                </div>
                <div className="user-setting">
                  <Dropdown
                    menu={{ items: profileItems }}
                    trigger={["click"]}
                    placement="bottomRight"
                    arrow>
                    <a onClick={(e) => e.preventDefault()}>
                      <div className="user">
                        <Avatar size="small" icon={<UserOutlined />} />
                        <p className="user-profile" style={{ color: "black" }}>
                          {userName}
                        </p>
                      </div>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Content
              style={{ margin: "24px", marginTop: 0, minHeight: "100vh" }}>
              <Outlet />
            </Content>
          </Layout>
          <LogoutModalForm
            visible={logoutModalVisible}
            handleOk={handleLogout}
            handleCancel={handleCancelLogout}
          />
        </Layout>
      </ConfigProvider>
    </>
  );
};

export default App;
