import React from "react";
import { Card, Button, ConfigProvider } from "antd";
import FilterLogo from "../assets/icons/Vector.svg";

const FilterCard = ({ children, onClear, onFilter }) => {
  return (
    <div className="filter-card">
      <Card title="Filter by :">
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
              colorTextPlaceholder: "#00062599",
              colorIcon: "#00062599",
            },
          }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="filter-card-info">{children}</div>
              <div className="filter-btn">
                <Button className="filter-gp-btn clear" onClick={onClear}>
                  Clear
                </Button>
                <Button className="filter-gp-btn filter" onClick={onFilter}>
                  <img src={FilterLogo} alt="Filter logo" />
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </ConfigProvider>
      </Card>
    </div>
  );
};

export default FilterCard;
