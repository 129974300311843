import {
  Button,
  Card,
  Checkbox,
  Form,
  Table,
  Input,
  message,
  Breadcrumb,
} from "antd";
import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAddPermissionMutation,
  useGetPermissionByIdQuery,
} from "../../features/adminstrator/adminApi";

const RoleUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { role } = location.state || {};
  const [permissions, setPermissions] = useState([]);

  const {
    data: PermissionListData,
    isLoading,
    error,
  } = useGetPermissionByIdQuery({ roleId: role.key });

  const [addPermission, { isLoading: isUpdating }] = useAddPermissionMutation();

  useEffect(() => {
    if (PermissionListData) {
      setPermissions(
        PermissionListData?.data?.map((permission, index) => ({
          key: index + 1,
          module_id: permission.module_id,
          module: permission.module_name.replace("_", " "),
          read: permission.read,
          write: permission.write,
          create: permission.create,
          delete: permission.delete,
        }))
      );
    }
  }, [PermissionListData]);

  const handleCheckboxChange = (recordKey, field) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) =>
        permission.key === recordKey
          ? { ...permission, [field]: !permission[field] }
          : permission
      )
    );
  };

  const handleUpdate = async () => {
    try {
      const updatedPermissions = permissions.map(
        ({ module_id, read, write, create, delete: del }) => ({
          module_id,
          read: read ? true : false,
          write: write ? true : false,
          create: create ? true : false,
          delete: del ? true : false,
        })
      );

      console.log("Updated Permission", updatedPermissions);

      await addPermission({
        adminId: role.key,
        updatedData: updatedPermissions,
      }).unwrap();
      message.success("Role permissions updated successfully!");
      navigate(`/${id}/adminstrator/role-permission`);
    } catch (error) {
      message.error("Failed to update role permissions.");
    }
  };

  const columns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "40%",
      align: "center",
    },
    {
      title: "READ",
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (text, record) => (
        <Checkbox
          checked={record.read}
          onChange={() => handleCheckboxChange(record.key, "read")}
        />
      ),
    },
    {
      title: "WRITE",
      dataIndex: "write",
      key: "write",
      align: "center",
      render: (text, record) => (
        <Checkbox
          checked={record.write}
          onChange={() => handleCheckboxChange(record.key, "write")}
        />
      ),
    },
    {
      title: "CREATE",
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (text, record) => (
        <Checkbox
          checked={record.create}
          onChange={() => handleCheckboxChange(record.key, "create")}
        />
      ),
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (text, record) => (
        <Checkbox
          checked={record.delete}
          onChange={() => handleCheckboxChange(record.key, "delete")}
        />
      ),
    },
  ];

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item
          onClick={() => navigate(`/${id}/adminstrator/role-permission`)}>
          Role & Permissions
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Role</Breadcrumb.Item>
      </Breadcrumb>
      <Card title="View Role" style={{ marginBottom: "20px" }}>
        <Form layout="vertical">
          <Form.Item style={{ width: "300px" }} label="Role">
            <Input
              style={{ width: "30px", height: "44px" }}
              readOnly
              value={role?.role}
            />
          </Form.Item>
        </Form>
      </Card>
      <div className="permission-table">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginLeft: "8px" }}>Group of Permissions</span>
            </div>
          }>
          <Form layout="vertical">
            <Form.Item>
              <Table
                pagination={false}
                columns={columns}
                dataSource={permissions}></Table>
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => navigate(`/${id}/adminstrator/role-permission`)}
                className="btn">
                Cancel
              </Button>
              <Button
                onClick={handleUpdate}
                loading={isUpdating}
                className="btn filter">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default RoleUpdate;
