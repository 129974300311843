import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Modal,
  Divider,
  ConfigProvider,
  Button
} from "antd";
import { Outlet, Link, useNavigate } from "react-router-dom";
import AdminIcon from "../../assets/icons/admin.svg";
import DashboardIcon from "../../assets/icons/dashboard.svg";
import SettingIcon from "../../assets/icons/setting.svg";
import {
  LeftOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../main/main.css";
import { logoutAccount } from "../../features/auth/authSlice";
import Logo from "../../assets/Logo -01 1.png";
import ReportingIcon from "../../assets/icons/reporting.svg";

const { Header, Content, Sider } = Layout;

const siderStyle = {
  overflow: "auto",
  minHeight: "100vh",
  position: "fixed",
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: "thin",
  scrollbarColor: "unset",
  transition: "width 0.2s",
};

function getItem(label, key, icon, path, children) {
  return {
    key,
    icon,
    children,
    label: path ? <Link to={path}>{label}</Link> : label,
  };
}
const items = [
  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Dashboard</span>,
    "1",
    <img
      src={DashboardIcon}
      alt="Dashboard Icon"
      style={{ width: "18px", height: "18px" }}
    />,
    "/"
  ),

  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Reporting</span>,
    "sub2",
    <img
      src={ReportingIcon}
      alt="Reporting Icon"
      style={{ width: "18px", height: "18px" }}
    />,
    null,
    [
      getItem(<span style={{ fontSize: "16px", fontWeight: 400 }}>User Logs</span>, "5", null, "/reporting/user-logs"),
      getItem(<span style={{ fontSize: "16px", fontWeight: 400 }}>Audit Logs</span>, "6", null, "/reporting/audit-logs"),
    ]
  ),

  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Adminstrator</span>,
    "sub1",
    <img
      src={AdminIcon}
      alt="Admin Icon"
      style={{ width: "18px", height: "18px" }}
    />,
    null,
    [
      getItem(<span style={{ fontSize: "16px", fontWeight: 400 }}>Adminstrator List</span>, "2", null, "/adminstrator/list"),
      getItem(<span style={{ fontSize: "16px", fontWeight: 400 }}>Role & Permissions</span>, "3", null, "/adminstrator/role-permission"),
    ]
  ),

  getItem(
    <span style={{ fontSize: "16px", fontWeight: 400 }}>Setting</span>,
    "sub3",
    <img
      src={SettingIcon}
      alt="Setting Icon"
      style={{ width: "18px", height: "18px" }}
    />,
    null,
    [
      //   getItem("General Setting", "6", null, "/setting/general"),
      //   getItem("Email Setting", "7", null, "/setting/email"),
      //   getItem("SMS Setting", "8", null, "/setting/sms"),
      //   getItem("Payment Setting", "9", null, "/setting/payment"),
      //   getItem("Invoice Setting", "10", null, "/setting/invoice"),
      getItem(<span style={{ fontSize: "16px", fontWeight: 400 }}>Branch Setting</span>, "11", null, "setting/branch"),
      // getItem("Notification Setting", "12", null, "/setting/notification"),
      // getItem("Types of Area", "13", null, "/setting/area"),
      // getItem("Contract Setting", "14", null, "/setting/contract"),
      // getItem("Terms & Conditions", "15", null, "/setting/terms"),
    ]
  ),
];

const CentralizedLayout = () => {
  const userName = useSelector((state) => state.authSlice.name);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const LogoutModalForm = ({ visible, handleOk, handleCancel }) => (
    <Modal
      title="Are you sure to logout?"
      open={visible}
      okButtonProps={{ type: "danger" }}
      onOk={handleOk}
      onCancel={handleCancel}
      centered></Modal>
  );

  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const profileItems = [
    {
      label: <a> Edit Profile</a>,
      key: "edit",
    },
    {
      label: <a onClick={() => setLogoutModalVisible(true)}> Log out</a>,
      key: "logout",
    },
  ];

  const handleLogoutModalClose = () => {
    dispatch(logoutAccount());
    navigate("/sign-in", { replace: true });
  };

  const handleLogoutModalCancel = () => {
    setLogoutModalVisible(false);
  };

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerBg: "box-shadow: 0px 3px 10px 0px #00000033;",
              headerPadding: 0,
              siderBg:
                "linear-gradient(128.88deg, #03045E 1.86%, #0057FF 100%)",
            },
            Menu: {
              itemBg: "transparent",
              itemHoverColor: collapsed ? "black" : "white",
              itemSelectedColor: "black",
              itemColor: collapsed ? "black" : "white",
            },
          },
        }}>
        <Layout hasSider>
        <Sider
            style={siderStyle}
            // collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            width={280} // Set the width of the sidebar when expanded
            collapsedWidth={80} // Set the width of the sidebar when collapsed
          >
            <div
              style={{
                height: 100,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div style={{ margin: "auto" }}>
                {!collapsed && (
                  <img
                    style={{ width: 120, height: 52 }}
                    src={Logo}
                    alt="Logo"
                  />
                )}
              </div>
              <div>
                <Button
                  style={{
                    border: "1px solid white",
                    width: "15px",
                    height: "50px",
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                    background: "#FFFFFF",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                  }}
                  onClick={toggleCollapsed}>
                  <div style={{ margin: "auto" }}>
                    {collapsed ? <RightOutlined /> : <LeftOutlined />}
                  </div>
                </Button>
              </div>
            </div>
            <Divider
              style={{ backgroundColor: "white", margin: 0, marginBottom: 20 }}
            />
            <div className="demo-logo-vertical" />
            <Menu defaultSelectedKeys={["1"]} mode="inline" items={items} />
          </Sider>

          <Layout
            style={{
              marginInlineStart: collapsed ? 80 : 280, // Adjust margin when collapsed
            }}>
            <Header
              style={{
                margin: "20px",
                borderRadius: "5px",
                backgroundColor: "white",
              }}>
              <div className="header">
                <div className="setting"></div>
                <div className="user-setting">
                  <Dropdown
                    menu={{ items: profileItems }}
                    trigger={["click"]}
                    placement="bottomRight"
                    arrow>
                    <a onClick={(e) => e.preventDefault()}>
                      <div className="user">
                        <Avatar size="small" icon={<UserOutlined />} />
                        <p className="user-profile" style={{ color: "black" }}>
                          {userName}
                        </p>
                      </div>
                    </a>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Content style={{ margin: "24px" }}>
              <Outlet />
            </Content>
          </Layout>
          <LogoutModalForm
            visible={logoutModalVisible}
            handleOk={handleLogoutModalClose}
            handleCancel={handleLogoutModalCancel}
          />
        </Layout>
      </ConfigProvider>
    </>
  );
};
export default CentralizedLayout;
