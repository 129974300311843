import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, Select, Checkbox, Table, Breadcrumb } from "antd";
import styles from "./styles.css";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../../features/tenant/tenantApi";
import moment from "moment";

const { Option } = Select;

const columns = [
  {
    title: "No",
    dataIndex: "no",
    key: "no",
    render: (text, record, index) => index + 1,
  },
  {
    title: "Sub Tenant Name",
    dataIndex: "subTenantName",
    key: "subTenantName",
  },
  {
    title: "Outlet Name",
    dataIndex: "outletName",
    key: "outletName",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Township",
    dataIndex: "township",
    key: "township",
  },
  {
    title: "Ph No.",
    dataIndex: "phNum",
    key: "phNum",
  },
  {
    title: "Created Date",
    dataIndex: "createdDate",
    key: "createdDate",
  },
];

const OwnDetails = ({ propertyData, onBack }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  const tableData = propertyData?.data?.subTenants?.map((tenant, index) => ({
    key: tenant._id, // Or however the unique key is identified
    no: index + 1,
    subTenantName: tenant.name,
    outletName: tenant.outletName, // Update to correct field
    city: tenant.city,
    township: tenant.township,
    phNum: tenant.phoneNumber,
    createdDate: moment(tenant.createdAt).format("DD/MM/YYYY"), // Format date
  }));

  useEffect(() => {
    if (propertyData?.data?.billing_contact_person?.city) {
      setSelectedCity(propertyData?.data?.billing_contact_person?.city);
    }
  }, [propertyData]);

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`${id}/tenant/list`)}>
          Tenant List
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={onBack}>Property</Breadcrumb.Item>
        <Breadcrumb.Item>View Property</Breadcrumb.Item>
      </Breadcrumb>
      <Form layout="vertical">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginLeft: "8px" }}>Own Shop Information</span>
            </div>
          }>
          <Form.Item label="Outlet Name" className="tenant-label-group">
            <Input
              value={propertyData?.data?.outlet_name}
              readOnly
              style={{ height: 44 }}
              placeholder="Outlet Name"
            />
          </Form.Item>
          <Form.Item>
            <Checkbox>Billing contact person same as tenant info.</Checkbox>
          </Form.Item>
        </Card>

        <Card title="Billing Contact Person" style={{ marginTop: "15px" }}>
          <div className="add-new-tenant">
            <Form.Item className="tenant-label-group" label="Name">
              <Input
                value={propertyData?.data?.billing_contact_person?.name}
                readOnly
                style={{ height: 44 }}
                placeholder="Name"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Phone Number">
              <Input
                value={propertyData?.data?.billing_contact_person?.phoneNumber}
                readOnly
                style={{ height: 44 }}
                placeholder="Phone Number"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Email">
              <Input
                value={propertyData?.data?.billing_contact_person?.email}
                readOnly
                style={{ height: 44 }}
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="City">
              <Select
                readOnly
                value={propertyData?.data?.billing_contact_person?.city}
                style={{ height: 44 }}
                placeholder="Select city name"
                onChange={(value) => setSelectedCity(value)}
                loading={isCityListLoading}>
                {cityList?.data?.map((city) => (
                  <Option key={city._id} value={city._id}>
                    {city?.city_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Township">
              <Select
                readOnly
                value={propertyData?.data?.billing_contact_person?.township}
                style={{ height: 44 }}
                placeholder="Select a township"
                loading={isTownshipListLoading}>
                {townshipList?.data?.map((township) => (
                  <Option key={township._id} value={township._id}>
                    {township?.township_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Address" style={{ width: 705 }}>
              <Input
                value={propertyData?.data?.billing_contact_person?.address}
                readOnly
                style={{ height: 44 }}
                placeholder="Address"
              />
            </Form.Item>
          </div>
        </Card>

        <Card title="Property Information" style={{ marginTop: "15px" }}>
          <div className="add-new-tenant">
            <Form.Item className="tenant-label-group" label="Branch">
              <Input
                value={propertyData?.data?.shop?.branch?.name}
                readOnly
                style={{ height: 44 }}
                placeholder="Select Branch"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Building">
              <Input
                value={propertyData?.data?.shop?.building?.name}
                readOnly
                style={{ height: 44 }}
                placeholder="Select Building"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Floor">
              <Input
                value={propertyData?.data?.shop?.floor?.name}
                readOnly
                style={{ height: 44 }}
                placeholder="Select Floor"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Zone / Role">
              <Input
                value={propertyData?.data?.shop?.zone?.name}
                readOnly
                style={{ height: 44 }}
                placeholder="Select Zone / Role"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Shop">
              <Input
                value={propertyData?.data?.shop?.name}
                readOnly
                style={{ height: 44 }}
                placeholder="Shop Name"
              />
            </Form.Item>

            <Form.Item className="tenant-label-group" label="Price (MMK)">
              <Input
                value={propertyData?.data?.shop?.price}
                readOnly
                style={{ height: 44 }}
                placeholder="Price"
              />
            </Form.Item>
          </div>
        </Card>

        <Card title="Sub Tenant Information" style={{ marginTop: "15px" }}>
          <Table columns={columns} dataSource={tableData}></Table>
        </Card>
      </Form>
    </>
  );
};

export default OwnDetails;
