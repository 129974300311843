import { Card, ConfigProvider } from "antd";
import React from "react";
import { useGetReportQuery } from "../../features/invoice/invoiceApi";

const InvoiceCard = () => {
  const { data: getReport } = useGetReportQuery();

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            padding: 15,
            paddingLG: 0,
          },
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}>
          <div className="invoice-report-card">
            <Card style={{ width: 275, padding: "15px" }}>
              <div className="count-list">
                <div className="count-detail">
                  <div
                    className="count-box"
                    style={{
                      background: "rgba(255, 179, 38, 0.2)",
                      color: "rgba(255, 179, 38, 1)",
                    }}>
                    <p>{getReport?.data?.paid.count}</p>
                  </div>
                  <div className="count-info">
                    <p
                      style={{
                        color: "rgba(255, 179, 38, 1)",
                        fontSize: "14px",
                      }}>
                      Outstanding
                    </p>
                    <h3>{formatPrice(getReport?.data?.paid.totalAmount)} MMK</h3>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="invoice-report-card">
            <Card style={{ width: 275, padding: "15px" }}>
              <div className="count-list">
                <div className="count-detail">
                  <div
                    className="count-box"
                    style={{
                      background: "rgba(53, 222, 120, 0.2)",
                      color: "rgba(27, 206, 99, 1)",
                    }}>
                    <p>{getReport?.data?.paid.count}</p>
                  </div>
                  <div className="count-info">
                    <p
                      style={{
                        color: "rgba(40, 199, 111, 1)",
                        fontSize: "14px",
                      }}>
                      Paid
                    </p>
                    <h3>{formatPrice(getReport?.data?.paid.totalAmount)} MMK</h3>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="invoice-report-card">
            <Card style={{ width: 275, padding: "15px" }}>
              <div className="count-list">
                <div className="count-detail">
                  <div
                    className="count-box"
                    style={{
                      background: "rgba(0, 87, 255, 0.2)",
                      color: "rgba(0, 87, 255, 1)",
                    }}>
                    <p>{getReport?.data?.unpaid.count}</p>
                  </div>
                  <div className="count-info">
                    <p
                      style={{
                        color: "rgba(0, 87, 255, 1)",
                        fontSize: "14px",
                      }}>
                      Unpaid
                    </p>
                    <h3>{formatPrice(getReport?.data?.unpaid.totalAmount)} MMK</h3>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="invoice-report-card">
            <Card style={{ width: 275, padding: "15px" }}>
              <div className="count-list">
                <div className="count-detail">
                  <div
                    className="count-box"
                    style={{
                      background: "rgba(240, 30, 44, 0.2)",
                      color: "rgba(240, 30, 44, 1)",
                    }}>
                    <p>{getReport?.data?.due.count}</p>
                  </div>
                  <div className="count-info">
                    <p
                      style={{
                        color: "rgba(240, 30, 44, 1)",
                        fontSize: "14px",
                      }}>
                      Due
                    </p>
                    <h3>{formatPrice(getReport?.data?.due.totalAmount)} MMK</h3>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default InvoiceCard;
