import { Card, Table, Modal, Dropdown, Space, Pagination, Button } from 'antd'
import React, { useState } from 'react'
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAllInvoicesListQuery } from '../../features/invoice/invoiceApi';
import { DeleteOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';

const InvoicePreview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // Manage current page state

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isLoading } = useGetAllInvoicesListQuery({ page: currentPage, limit: 10, status: 0 });

  const showModal = () => {
    setIsModalOpen(true);
  };
  
  const handleOk = () => {
    setIsModalOpen(false);
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1, // Adjust based on current page
    },
    {
      title: "Invoice Type",
      dataIndex: 'invoice_type',
      key: 'invoice_type',
    },
    {
      title: "Tenant Name",
      dataIndex: 'name', // assuming tenantName is inside tenantpropertycontract
      key: 'name',
      render: (_, record) => record.tenant_name, // adjust accordingly
    },
    {
      title: "Outlet Name",
      dataIndex: 'outlet_name', // assuming outletName is inside tenantpropertycontract
      key: 'outlet_name',
      render: (_, record) => record.propertycontract?.outlet_name, // adjust accordingly
    },
    {
      title: "Tenant Type",
      dataIndex: 'tenant_type', // assuming outletName is inside tenantpropertycontract
      key: 'tenant_type',
      render: (_, record) => record.tenant_type, // adjust accordingly
    },
    {
      title: "Electricity (YESB)",
      dataIndex: 'yesb_meter',
      key: 'yesb_meter',
      render:(_,record) => `${record.yesb_meter.usage} Unit`
    },
    {
      title: "Electricity (Generator)",
      dataIndex: 'generator_meter',
      key: 'generator_meter',
      render:(_,record) => `${record.generator_meter.usage} Unit`
    },
    {
      title: "Electricity (Gas Line)",
      dataIndex: 'gas_line_meter',
      key: 'gas_line_meter',
      render:(_,record) => `${record.gas_line_meter.usage} Unit`
    },
    {
      title: "Other Fee (Water)",
      dataIndex: 'water_cost_meter.amount',
      key: 'water_cost_meter',
      render:(_,record) => `${record.water_cost_meter.usage} Unit`

    },
    {
      title: "Pest & Mosquito Control",
      dataIndex: 'poest_and_mosquito_control',
      key: 'poest_and_mosquito_control',
      render:(_,record) => `${record.poest_and_mosquito_control.amount} ${record.poest_and_mosquito_control.rate_type}`

    },
    {
      title: "Bill Board Advertising Charges",
      dataIndex: 'bill_board_advertising_charges.amount',
      key: 'bill_board_advertising_charges',
      render:(_,record) => `${record.bill_board_advertising_charges.amount} ${record.bill_board_advertising_charges.rate_type}`

    },
    {
      title: '',
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <>
                    <EditOutlined/> Edit
                    </>
                  ),
                  onClick: () => navigate(`/${id}/invoice/preview/edit/${record._id}`),
                },
                {
                  key: "2",
                  label: (
                    <>
                    <DeleteOutlined/> Delete
                    </>
                  ),
                  onClick: () => showModal(),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{border: '1px solid darkblue'}}>Action<DownOutlined/></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
     <h3 className="page-title">Preview Invoice</h3>
        <Table 
          columns={columns} 
          dataSource={data?.data} 
          loading={isLoading}
          pagination={false} 
          rowKey="_id" 
        />
        <Pagination
          current={currentPage}
          pageSize={10}
          total={data?.paginator?.totalCount || 0} 
          onChange={(page) => setCurrentPage(page)} 
          style={{ marginTop: "15px", textAlign: "right" }}
        />
      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isModalOpen}
        onCancel={handleCancel}
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button
              className="delete-btn"
              key="back"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
    </>
  )
}

export default InvoicePreview
