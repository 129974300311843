import React from 'react'
import { DashboardCard } from '../../component'

const CentralizedDashboard = () => {
  return (
    <div>
      <DashboardCard/>
    </div>
  )
}

export default CentralizedDashboard