import { Table, Space, Dropdown, Modal, message, Button } from "antd";
import React, { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  useDeleteSubPropertyMutation,
  useGetPropertyBySubTenantIdQuery,
} from "../../../features/tenant/subTenantApi";
import { useLocation } from "react-router-dom";
import { DeleteOutlined, DownOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

const TabProperty = ({ onEditDetail, onViewDetail }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);

  const location = useLocation();
  const subTenantId = location.state?.subTenantId;

  const {
    data: subtenantPropertyList,
    isLoading: isPropertyLoading,
    error,
  } = useGetPropertyBySubTenantIdQuery({ tenantId: subTenantId });
  console.log("Sub Tenant Property", subtenantPropertyList);
  

  const [deleteSubProperty] = useDeleteSubPropertyMutation();

  const showModal = (record) => {
    setCurrentRecord(record?.key);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteSubProperty({ propertyId: currentRecord }).unwrap();
        setIsModalOpen(false);
        message.success("Property is deleted successfully.");
      } catch (err) {
        console.error("Failed to delete this property.", err);
        message.error("Failed to delete this property;");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const viewDetails = (record) => {
    onViewDetail(record?.key); // Pass the property ID directly
  };

  const editDetails = (record) => {
    onEditDetail({
      propertyId: record.key,
      type: record.type,
    });
  };

  const items = (record) => [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: () => viewDetails(record),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: () => editDetails(record),
    },
    {
      key: "3",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: () => {
        showModal(record);
      },
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Shop Name",
      dataIndex: "shopName",
      key: "shopName",
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: '',
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items(record),
            }}
            placement="bottomRight">
            <Button style={{border: '1px solid darkblue'}}>Action <DownOutlined/></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = subtenantPropertyList?.data?.map((propertyList, index) => ({
    key: propertyList?._id,
    tenantName: propertyList?.tenant?.name,
    shopName: propertyList?.shop?.name,
    branch: propertyList?.shop?.branch?.name,
    building: propertyList?.shop?.building?.name,
    floor: propertyList?.shop?.floor?.name,
    zone: propertyList?.shop?.zone?.name,
  }));
  
  return (
    <>
      <Table columns={columns} dataSource={tableData}></Table>
      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button className="delete-btn" key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
    </>
  );
};

export default TabProperty;
