import React, { useState } from "react";
import {
  Card,
  Form,
  Select,
  Button,
  DatePicker,
  Modal,
  Dropdown,
  Space,
  Table,
  Tag,
  Input,
  Pagination,
  message,
  Divider,
  ConfigProvider,
} from "antd";
import PaymentIcon from "../../assets/icons/payment.svg";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import {
  useGetAllInvoicesListQuery,
  useUpdateInvoiceMutation,
} from "../../features/invoice/invoiceApi";
import moment from "moment";
import { DeleteOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";
import { InvoiceCard } from "../../component";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const InvoiceList = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Manage current page state
  const { id } = useParams();
  const [form] = Form.useForm(); // Create a form instance
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // API call to fetch invoice list
  const {
    data: invoiceList,
    isLoading,
    error,
  } = useGetAllInvoicesListQuery({ page: currentPage, limit: 10, status: 1 });

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };
  const [updateInvoice] = useUpdateInvoiceMutation();

  const handleExpandRow = (expanded, record) => {
    const keys = [...expandedRowKeys];
    const index = keys.indexOf(record.id);

    if (expanded && index === -1) {
      keys.push(record.id);
    } else if (!expanded && index !== -1) {
      keys.splice(index, 1);
    }

    setExpandedRowKeys(keys);
  };
  const handleUpdate = async (values) => {
    let payload = {
      status: values.status || currentRecord.status,
      amount: values.amount || currentRecord.amount,
      remaining_amount:
        values.remaining_amount || currentRecord.remaining_amount,
      transcation_type: values.transcationType || currentRecord.transcationType,
      payment_type: values.paymentType || currentRecord.paymentType,
      payment_amount: values.payment_amount || 0,
    };
    if (payload.payment_amount == 0) {
      message.warning(`Can not be 0`);
    } else {
      if (payload.payment_type == 2) {
        if (payload.remaining_amount != payload.payment_amount) {
          message.warning(
            ` Must be  Choose status Paid and Fully Payment. remaining_amount ${payload.remaining_amount} and payment_amount ${payload.payment_amount} ,`
          );
        } else if (payload.status == 1 || payload.status == 3) {
          message.warning(` Must be  Choose status Paid`);
        } else {
          await updateInvoice({
            params: payload,
            id: currentRecord.id,
          });
          message.success("updated successfully.");
          navigate(`/${id}/invoice/list`);
          setIsEditModalOpen(false);
        }
      } else if (payload.remaining_amount < payload.payment_amount) {
        message.warning(` Must be  Less than or equal remaining_amount`);
      } else if (
        payload.remaining_amount == payload.payment_amount &&
        payload.status != 2
      ) {
        message.warning(`status must be Paid`);
      } else {
        await updateInvoice({
          params: payload,
          id: currentRecord.id,
        });
        message.success("updated successfully.");
        navigate(`/${id}/invoice/list`);
        setIsEditModalOpen(false);
      }
    }
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    setIsEditModalOpen(true);
  };

  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const resetFilters = () => {
    setSearchQuery("");
    setStatusFilter(""); // Reset status filter to empty string ("All")
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 25,
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1, // Adjust index based on current page
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_code",
      key: "invoice_code",
    },
    {
      title: "Invoice Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenant_name",
      key: "tenant_name",
    },
    {
      title: "Outlet Name",
      dataIndex: "outlet_name",
      key: "outlet_name",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => {
        let color;
        let text;
        switch (status) {
          case 2:
            color = "rgba(40, 199, 111, 1)";
            text = "Paid";
            break;
          case 1:
            color = "#FF9F43";
            text = "Unpaid";
            break;
          case 3:
            color = "red";
            text = "Due";
            break;
          default:
            color = "red";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },

    {
      title: "Transcation Type",
      dataIndex: "transcationType",
      key: "transcationType",
      render: (transcationType) => {
        let transactionLabel;
        switch (transcationType) {
          case 1:
            transactionLabel = "KBZ Pay";
            break;
          case 2:
            transactionLabel = "Cash";
            break;
          case 3:
            transactionLabel = "AYA Pay";
            break;
          default:
            transactionLabel = "-";
        }
        return <p>{transactionLabel}</p>;
      },
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (paymentType) => {
        let paymentLabel;
        switch (paymentType) {
          case 1:
            paymentLabel = "Partially";
            break;
          case 2:
            paymentLabel = "Fully";
            break;
          default:
            paymentLabel = "-";
        }
        return <p>{paymentLabel}</p>;
      },
    },
    {
      title: "Amount (MMK)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Remaining Amount",
      dataIndex: "remaining_amount",
      key: "remaining_amount",
    },
    {
      title: "",
      key: "operation",
      align: "center",

      render: (text, record) => {
        const isPaid = record.status === 2; // Check if status is Paid
        return (
          <Space size="middle">
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <>
                        <EyeOutlined /> &nbsp;View
                      </>
                    ),
                    onClick: () =>
                      navigate(
                        `/${id}/invoice/list/invoice-details/${record.id}`
                      ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}>
                        <img
                          style={{
                            width: 18,
                            height: 18,
                            padding: 0,
                            margin: 0,
                            filter: isPaid
                              ? "brightness(0) saturate(0) opacity(0.5)"
                              : "none",
                          }}
                          src={PaymentIcon}
                          alt="payment-logo"
                        />{" "}
                        &nbsp; Payment
                      </div>
                    ),
                    onClick: () => !isPaid && showEditModal(record),
                    disabled: isPaid,
                  },
                  {
                    key: "3",
                    label: (
                      <>
                        <DeleteOutlined /> &nbsp;Delete
                      </>
                    ),
                    onClick: () => !isPaid && showDeleteModal(),
                    disabled: isPaid,
                  },
                ],
              }}
              placement="bottomRight">
              <Button style={{ border: "1px solid darkblue" }}>
                Action <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
  const expandedColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Payment Amount (MMK)",
      dataIndex: "payment_amount",
      key: "payment_amount",
    },
  ];

  const dataSource =
    invoiceList?.data?.map((invoice, index) => ({
      id: invoice._id,
      date: moment(invoice.createdAt).format("DD/MM/YYYY"),
      invoice_code: invoice.invoice_code,
      invoiceType: invoice.invoice_type,
      tenant_name: invoice.tenant_name,
      // outlet_name: invoice.propertycontract.outlet_name,
      status: invoice.status, // Assuming status is a string, adjust based on your data
      transcationType: invoice.transaction_type,
      paymentType: invoice.payment_type,
      amount: formatPrice(invoice.grand_total),
      remaining_amount: formatPrice(invoice.remaining_amount),
      payment_list: invoice.payment_list,
    })) || [];

    

  return (
    <>
      <div className="page-new-add">
        <div>
          <h3 className="page-title">Invoice List</h3>
          <p className="total-number">
            Total Invoice :{" "}
            <div className="total-no-display">
              {invoiceList?.data?.invoices}
            </div>
          </p>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Search by Invoice No., Tenant Name, Outlet Name"
          allowClear
          enterButton="Search"
          size="large"
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: 456,
          }}
        />
      </div>

      <div className="filter-card">
        <Card title="Filter by :" style={{ height: 180 }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
                colorTextPlaceholder: "#00062599",
                colorIcon: "#00062599",
              },
            }}>
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="filter-card-info">
                  <RangePicker style={{ height: 38, width: 233 }} />
                  <DatePicker
                    placeholder="Year"
                    picker="year"
                    style={{
                      width: 140,
                      height: 38,
                      borderRadius: "25px",
                    }}></DatePicker>
                  <DatePicker
                    placeholder="Month"
                    picker="month"
                    style={{
                      width: 140,
                      height: 38,
                      borderRadius: "25px",
                    }}></DatePicker>
                  <Select
                    placeholder="Invoice Status"
                    // value={statusFilter}
                    style={{
                      width: 140,
                      height: 38,
                    }}
                    onChange={(value) => setStatusFilter(value)} // Update status filter state
                  >
                    <Option value="">Paid</Option>
                    <Option value="Active">Unpaid</Option>
                    <Option value="Suspend">Due</Option>
                  </Select>

                  <Select
                    placeholder="Invoice Type"
                    // value={statusFilter}
                    style={{
                      width: 140,
                      height: 38,
                    }}>
                    <Option value="">Monthly</Option>
                    <Option value="Active">Yearly</Option>
                    <Option value="Suspend">Maintenance</Option>
                  </Select>

                  <Select
                    placeholder="Transcation Type"
                    // value={statusFilter}
                    style={{
                      width: 140,
                      height: 38,
                    }}>
                    <Option value="">KBZ QR</Option>
                    <Option value="Active">Cash</Option>
                  </Select>

                  <Select
                    placeholder="Payment Type"
                    // value={statusFilter}
                    style={{
                      width: 140,
                      height: 38,
                    }}>
                    <Option value="">Fully</Option>
                    <Option value="Active">Partially</Option>
                  </Select>
                </div>
                <div className="filter-btn">
                  <Button
                    className="filter-gp-btn clear"
                    onClick={resetFilters}>
                    Clear
                  </Button>
                  <Button className="filter-gp-btn filter">Filter</Button>
                </div>
              </div>
            </div>
          </ConfigProvider>
        </Card>
      </div>

      <InvoiceCard />

      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        pagination={false}
        scroll={{
          x: 1300,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={expandedColumns}
              dataSource={record.payment_list.map((payment, index) => ({
                key: index,
                payment_amount: payment.payment_amount,
                date: moment(payment.date).format("DD/MM/YYYY"),
              }))}
              pagination={false}
              rowKey={(record) => record.key}
            />
          ),
          rowExpandable: (record) =>
            record.paymentType === 1 &&
            record.payment_list &&
            record.payment_list.length > 0,
          expandedRowKeys: expandedRowKeys,
          onExpand: handleExpandRow,
        }}
        rowKey={(record) => record.id}
      />

      <div className="pagination">
        <div className="size-changer">
          <p className="pagination-name">Count Per Page</p>
          <Pagination
            current={currentPage}
            pageSizeOptions={["10", "20", "30"]}
            showSizeChanger={true}
            total={invoiceList?.data?.totalCount || 0}
            onChange={(page) => setCurrentPage(page)}
            style={{ marginTop: "6px" }}
            showQuickJumper={false}
            locale= {{items_per_page: ""}}
          />
        </div>

        <div className="pagination-number">
          <Pagination
            current={currentPage}
            pageSize={10}
            total={invoiceList?.data?.totalCount || 0} // Adjust based on your API response
            onChange={(page) => setCurrentPage(page)} // Update current page on page change
            // style={{ marginTop: "15px", textAlign: "right" }}
            align="end"
          />
        </div>
      </div>

      <Modal
        title={
          currentRecord?.paymentType === 1
            ? "Partially Payment"
            : "Fully Payment"
        }
        open={isEditModalOpen}
        onCancel={handleEditCancel}
        footer={
          <>
            <Button className="btn" key="back" onClick={handleEditCancel}>
              Cancel
            </Button>
            <Button
              className="btn filter"
              key="submit"
              type="primary"
              onClick={() => form.submit()}>
              Update
            </Button>
          </>
        }
        width={880}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flexWrap: "wrap",
            }}>
            <Form.Item label="Payment Type" name="paymentType">
              <Select
                defaultValue={currentRecord?.paymentType}
                style={{
                  width: 400,
                  height: 44,
                }}
                options={[
                  {
                    value: 1,
                    label: "Partially",
                  },
                  {
                    value: 2,
                    label: "Fully",
                  },
                ]}></Select>
            </Form.Item>
            <Form.Item style={{ width: 400 }} label="Amount" name="amount">
              <Input
                defaultValue={currentRecord?.amount}
                className="change-status-input"
                readOnly
                style={{
                  height: 44,
                }}></Input>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isDeleteModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button
              className="delete-btn"
              key="back"
              onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleDeleteOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
    </>
  );
};

export default InvoiceList;
