import { Card } from "antd";
import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const DigitalPayment = () => {
  const pData = [40, 50, 60, 30, 10, 50, 40, 30];
  const xLabels = [
    "KBZ QR",
    "KBZ PWD",
    "MPU_CARD",
    "AYA QR",
    "AYA PAY",
    "VISA CARD",
    "MASTER CARD",
    "JCB_CARD",
  ];

  return (
    <Card title="Digital Payment" style={{ marginBottom: "20px" }}>
      <BarChart
        width={1000}
        height={300}
        series={[{ data: pData }]}
        yAxis={[{ min: 0, max: 100 }]}
        xAxis={[
          {
            data: xLabels,
            scaleType: "band",
            colorMap: {
              type: "ordinal",
              colors: [
                "#3BC7D8",
                "#8840E6",
                "#28C76F",
                "#E90A16",
                "#8840E6",
                "#28C76F",
                "#E90A16",
                "#3BC7D8",
              ],
            },
          },
        ]}
      />
    </Card>
  );
};

export default DigitalPayment;
