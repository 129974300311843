import React from "react";
import { Card, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBranch } from "../../features/auth/authSlice";
import { PieChart } from "@mui/x-charts/PieChart";
import styles from "./styles.css";

const DashboardCard = () => {
  const branches = useSelector((state) => state.authSlice.branches);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoToDashboard = (branchId) => {
    dispatch(setSelectedBranch(branchId));
    navigate(`/${branchId}`);
  };

  const tenantData = [
    { label: "Owner", value: 400 },
    { label: "Lease", value: 300 },
  ];

  const propertyData = [
    { label: "Available", value: 400 },
    { label: "Unavailable", value: 300 },
  ];

  const tenantColor = ["#9261D2", "#3BC7D8"];
  const propertyColor = ["#28C76F", "#E90A16"];

  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "space-between",
        gap: "20px",
        flexDirection: "row",
        flexWrap: "wrap",
      }}>
      {branches?.map((branch) => (
        <Card
          key={branch._id}
          title={branch.name}
          extra={
            <a onClick={() => handleGoToDashboard(branch._id)}>
              Go to Dashboard
            </a>
          }
          style={{ width: "365px", marginBottom: "16px"}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              overflow: "hidden",
              height: "190px",
            }}>
            <span style={{ width: "200px", height: "150px" }}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: "2px",
                }}>
                Number of Tenant
              </p>
              <PieChart
                colors={tenantColor}
                series={[
                  {
                    startAngle: -90,
                    endAngle: 90,
                    data: tenantData,
                    innerRadius: 30,
                    outerRadius: 65,
                    cx: 65,
                  },
                ]}
                height={140}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 0,
                    labelStyle: {
                      fontSize: 10,
                    },
                  },
                }}
              />
            </span>
            <Divider type="vertical" style={{ height: "120px", margin: 0 }} />
            <span style={{ width: "200px", height: "150px" }}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: "2px",
                }}>
                Number of Property
              </p>
              <PieChart
                colors={propertyColor}
                series={[
                  {
                    startAngle: -90,
                    endAngle: 90,
                    data: propertyData,
                    innerRadius: 30,
                    outerRadius: 65,
                    cx: 80,
                  },
                ]}
                height={140}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 0,
                    labelStyle: {
                      fontSize: 10,
                    },
                  },
                }}
              />
            </span>
          </div>
          <Divider style={{ margin: 0 }} />
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>Invoice</p>
          <div className="dashboard-invoice-card">
            <div
              style={{
                boxShadow: "1px 1px 1px 1px grey",
                margin: 0,
                padding: 10,
                height: "70px",
                lineHeight: "7px",
                width: "150px",
                borderRadius: 0,
                alignSelf: "center",
                marginBottom:'10px'
              }}>
              <p style={{ fontSize: "10px" }}>Outstanding</p>
              <p style={{ fontSize: "16px", fontWeight: 700 }}>1,060,000 MMK</p>
            </div>
            <div
              style={{
                boxShadow: "1px 1px 1px 1px grey",
                margin: 0,
                padding: 10,
                height: "70px",
                lineHeight: "7px",
                width: "150px",
                borderRadius: 0,
                alignSelf: "center",
                marginBottom:'10px'
              }}>
              <p style={{ fontSize: "10px" }}>Paid</p>
              <p style={{ fontSize: "16px", fontWeight: 700 }}>860,000 MMK</p>
            </div>
            <div
              style={{
                boxShadow: "1px 1px 1px 1px grey",
                margin: 0,
                padding: 10,
                height: "70px",
                lineHeight: "7px",
                width: "150px",
                borderRadius: 0,
                alignSelf: "center",
                marginBottom:'10px'
              }}>
              <p style={{ fontSize: "10px" }}>Unpaid</p>
              <p style={{ fontSize: "16px", fontWeight: 700 }}>660,000 MMK</p>
            </div>
            <div
              style={{
                boxShadow: "1px 1px 1px 1px grey",
                margin: 0,
                padding: 10,
                height: "70px",
                lineHeight: "7px",
                width: "150px",
                borderRadius: 0,
                alignSelf: "center",
                marginBottom:'10px'
              }}>
              <p style={{ fontSize: "10px" }}>Due</p>
              <p style={{ fontSize: "16px", fontWeight: 700 }}>8,060,000 MMK</p>
            </div>
          </div>
          <div>
            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Notification</p>
            <div className="noti-dashboard-card">
              <Card
                style={{
                  boxShadow: "1px 1px 1px 1px grey",
                  margin: 0,
                  padding: 0,
                  lineHeight: "7px",
                  borderRadius: 0,
                  marginBottom: "10px",
                }}>
                <div className="noti-bar">
                  <p>Invoice Notification</p>
                  <div className="noti-box">
                    <p className="noti-number">5</p>
                  </div>
                </div>
              </Card>
              <Card
                style={{
                  boxShadow: "1px 1px 1px 1px grey",
                  margin: 0,
                  padding: 0,
                  lineHeight: "7px",
                  borderRadius: 0,
                  marginBottom: "10px",
                }}>
                <div className="noti-bar">
                  <p>Maintenance Notification</p>
                  <div className="noti-box">
                    <p className="noti-number">3</p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default DashboardCard;
