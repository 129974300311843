import { baseApi } from "../../app/baseApi";
import Cookies from "js-cookie";
import { setToken2 } from "./authSlice";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    loginAccount: builder.mutation({
      query: (credentials) => ({
        url: `/auth/login`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Auth"],
    }),
    getBranchData: builder.query({
      query: (branchId) => ({
        url: `/auth/branch/${branchId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${Cookies.get("token2")}`,
        },
      }),
      providesTags: ["BranchData"]
    }),
  }),
});

export const { useLoginAccountMutation, useGetBranchDataQuery } = authApi;
