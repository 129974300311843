import React, { useState } from "react";
import { DatePicker, message, Select } from "antd";
import { useParams } from "react-router-dom";
import "../styles.css";
import {
  useDeleteSubTenantMutation,
  useGetAllSubTenantQuery,
} from "../../../features/tenant/subTenantApi";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  DeleteModal,
  FilterCard,
  PageHeader,
  ReusablePagination,
  SearchFilter,
} from "../../../component";
import { SubTenantTable } from "../../../features";

const { Option } = Select;
const { RangePicker } = DatePicker;

const SubTenantList = () => {
  const { id } = useParams();

  const token = useSelector((state) => state.authSlice.token2);

  const {
    data: subTenantList,
    isLoading,
    error,
  } = useGetAllSubTenantQuery(token);

  const [deleteSubTenant] = useDeleteSubTenantMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const showModal = (record) => {
    setCurrentRecord(record.key);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (currentRecord) {
      try {
        await deleteSubTenant({ tenantId: currentRecord }).unwrap();
        setIsModalOpen(false);
        message.success("Tenant is deleted successfully.");
      } catch (err) {
        console.error("Failed to delete tenant:", err);
        message.error("Failed to delete this tenant.");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const filteredData = subTenantList?.data?.subTenants
    ?.filter((subTenant) =>
      subTenant.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.map((subTenant, index) => ({
      key: subTenant?._id,
      subTenantName: subTenant?.name,
      city: subTenant?.city?.city_name,
      township: subTenant?.township?.township_name,
      phNum: subTenant?.phoneNumber,
      status: subTenant.status === 1 ? "Active" : "Suspend",
      creDate: moment(subTenant?.createdAt).format("DD/MM/YYYY"),
    }));

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  // Calculate data for the current page
  const paginatedData = filteredData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <>
      <PageHeader
        title="Sub Tenant List"
        totalItems={subTenantList?.data?.totalSubTenants}
        linkTo={`/${id}/tenant/sub-list/add-new-sub-tenant`}
        addBtn="Add New Sub Tenant"
        totalName="Sub Tenants"
      />
      <SearchFilter
        searchPlaceHolder="Search by Sub Tenant Name"
        onSearch={setSearchQuery}
      />

      <FilterCard>
        <RangePicker style={{ height: 38, width: 233 }} />
        <Select
          placeholder="Status"
          style={{
            width: 150,
            height: 38,
          }}>
          <Option value="">All</Option>
          <Option value="Active">Active</Option>
          <Option value="Suspend">Suspend</Option>
        </Select>
      </FilterCard>
      
      <SubTenantTable
        data={paginatedData}
        isLoading={isLoading}
        currentPage={currentPage}
        pageSize={pageSize}
        total={subTenantList?.data?.totalSubTenants}
        onPageChange={handlePaginationChange}
        showModal={showModal}
      />

      <ReusablePagination
        className="single-page"
        currentPage={1} // You can manage pagination here as needed
        pageSize={10} // Set your page size
        total={subTenantList?.data?.totalSubTenants}
        onChange={handlePaginationChange}
      />
      <DeleteModal
        visible={isModalOpen}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default SubTenantList;
