import { baseApi } from "../../app/baseApi";

export const invoiceSettingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoiceSetting: builder.query({
      query: () => `/Setting/invoice-settings/list`,
      providesTags: ["InvoiceSettingList"],
    }),

    updateInvoiceSetting: builder.mutation({
      query: ({ invoiceId, updatedData }) => ({
        url: `/Setting/invoice-settings/update/${invoiceId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit invoice setting failed:", error);
      },
      invalidatesTags: ["InvoiceSettingList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllInvoiceSettingQuery, useUpdateInvoiceSettingMutation
} = invoiceSettingApi;
