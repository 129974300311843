import {
  Form,
  Input,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Button,
  Divider,
  ConfigProvider,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  useGetAllBuildingQuery,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
} from "../../features/property/propertyApi";
import { useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

const BuildingList = ({ searchTerm }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: buildingListData,
    isLoading,
    error,
  } = useGetAllBuildingQuery(token);

  const [updateBuilding, { isLoading: isUpdateLoading, error: isUpdateError }] =
    useUpdateBuildingMutation();
  const [deleteBuilding, { isLoading: isDeleteLoading, error: isDeleteError }] =
    useDeleteBuildingMutation();

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      width: "5%",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // Filtered data based on search term
  const [filteredBuildings, setFilteredBuildings] = useState([]);

  useEffect(() => {
    if (buildingListData) {
      // Filtering logic based on search term
      const filteredData = buildingListData.data.buildings.filter((building) =>
        building.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredBuildings(filteredData);
    }
  }, [searchTerm, buildingListData]);

  const tableData = filteredBuildings.map((building, index) => ({
    key: building._id,
    no: index + 1,
    building: building.name,
  }));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [form] = Form.useForm();

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteBuilding({ buildingId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Building is deleted successfully.");
      } catch (isDeleteError) {
        const errorMessage =
          isDeleteError?.data?.errors?.[0] || "Failed to delete building";
        message.error(errorMessage);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({ building: record.building }); // Pre-fill the form
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        buildingId: currentRecord.key,
        updatedData: {
          name: values.building, // Get value from form
        },
      };
      await updateBuilding(updatedData).unwrap();
      setIsEditModalOpen(false);
      message.success("Building is edited successfully.");
    } catch (isUpdateError) {
      const errorMessage =
        isUpdateError?.data?.errors?.[0] || "Failed to edit building";
      message.error(errorMessage);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSizeOptions: ["10", "20", "30"], // Options for 10, 20, or 50 items per page
            showSizeChanger: true, // Enables the dropdown for selecting page size
            defaultPageSize: 10, // Sets the default page size to 10
          }}
        />
        <Modal
          width={360}
          style={{ textAlign: "center" }}
          title="ARE YOU SURE?"
          open={isDeleteModalOpen}
          closable={false}
          centered
          footer={[
            <div className="delete-modal-btn">
              <Button
              type="text"
                className="delete-btn"
                key="back"
                onClick={handleDeleteCancel}>
                Cancel
              </Button>
              <Button
                className="delete-btn filter"
                key="submit"
                loading={isDeleteLoading}
                onClick={handleDeleteOk}>
                Delete
              </Button>
            </div>,
          ]}>
          <p>Do you want to delete this permanently?</p>
        </Modal>

        <Modal
          width={880}
          onCancel={handleEditCancel}
          title="Edit Building"
          open={isEditModalOpen}
          footer={[
            <>
              <Button type="text" className="btn" key="back" onClick={handleEditCancel}>
                Cancel
              </Button>
              <Button
                loading={isUpdateLoading}
                className="btn filter"
                key="submit"
                onClick={handleEditOk}>
                Update
              </Button>
            </>,
          ]}>
          <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
          <Form form={form} layout="vertical">
            <Form.Item label="Building" name="building">
              <Input style={{ height: 44 }} />
            </Form.Item>
          </Form>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default BuildingList;
