import { Card, DatePicker, Form, Input, Image, Table } from "antd";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const MaintenanceDetail = ({ onBack }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Type of Issue',
      dataIndex: 'issue',
      key: 'issue',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: "40%",
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount'
    }
  ]
  return (
    <>
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon onClick={onBack} style={{ cursor: "pointer" }} />
            <span style={{ marginLeft: "8px" }}>Maintenance Detail</span>
          </div>
        }>
        <Form layout="vertical">
          <div className="maintenance-form-group">
            <Form.Item label="Date">
              <DatePicker
                style={{ height: 44, width: 340 }}
                placeholder="Date"></DatePicker>
            </Form.Item>
            <Form.Item className="maintenance-input-gp" label="Tenant Name">
              <Input style={{ height: 44 }} placeholder="Tenant Name"></Input>
            </Form.Item>
            <Form.Item className="maintenance-input-gp" label="Outlet Name">
              <Input style={{ height: 44 }} placeholder="Outlet Name"></Input>
            </Form.Item>
            <Form.Item className="maintenance-input-gp" label="Type of Issue">
              <Input style={{ height: 44 }} placeholder="Type of Issue"></Input>
            </Form.Item>
            <Form.Item className="maintenance-input-gp" label="Status">
              <Input style={{ height: 44 }} placeholder="Status"></Input>
            </Form.Item>
          </div>
          <Form.Item label="Note">
            <TextArea style={{ height: 44 }} placeholder="Status"></TextArea>
          </Form.Item>
          <Form.Item label="Upload Photo">
            <Image
              width={200}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
          </Form.Item>
        </Form>
      </Card>
      <Card style={{ marginTop: "15px" }} title="Payment Information">
        <Form layout="vertical">
          <Form.Item label="Invoice No.">
            <Input
              addonBefore="INV-ED"
              className="invoice-input"
              style={{ height: 44 }}></Input>
          </Form.Item>
          <div className="maintenance-form-group">
            <Form.Item className="maintenance-input-gp" label="Total Amount">
              <Input style={{ height: 44 }} placeholder="Total Amount"></Input>
            </Form.Item>
            <Form.Item className="maintenance-input-gp" label="Status">
              <Input style={{ height: 44 }} placeholder="Status"></Input>
            </Form.Item>
            <Form.Item className="maintenance-input-gp" label="Type of Payment">
              <Input style={{ height: 44 }} placeholder="Type of Payment"></Input>
            </Form.Item>
            <Form.Item style={{width: '100%'}}>
              <Table columns={columns}></Table>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default MaintenanceDetail;
