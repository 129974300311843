import { Button, Card, Form, Input, InputNumber, Table } from "antd";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import styles from "./styles.css";
import { CheckBox } from "@mui/icons-material";

const MakeInvoice = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [currentData, setCurrentData] = useState({
    description: "",
    qty: 1,
    price: "",
    tax: "",
    amount: "",
  });

  const handleCheckboxClick = () => {
    setFormData([...formData, currentData]);
    setCurrentData({ description: "", qty: 1, price: "", tax: "", amount: "" });
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <>
      <Form layout="vertical">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon
                onClick={() => navigate("/maintenance/list")}
                style={{ cursor: "pointer" }}
              />
              <span style={{ marginLeft: "8px" }}>Maintenance Invoice</span>
            </div>
          }>
          <div className="maintenance-make-invoice-card">
            <Form.Item label="Tenant Name">
              <Input
                className="invoice-input"
                placeholder="Tenant Name"
                style={{ height: 44 }}></Input>
            </Form.Item>

            <Form.Item label="Outlet Name">
              <Input
                className="invoice-input"
                placeholder="Outlet Name"
                style={{ height: 44 }}></Input>
            </Form.Item>

            <Form.Item label="Invoice Validity">
              <Input
                className="invoice-input"
                placeholder="Invoice Validity"
                style={{ height: 44 }}></Input>
            </Form.Item>
          </div>
        </Card>

        <Card style={{ marginTop: "15px" }}>
          <div className="invoice-body-card">
            <Form.Item style={{ width: "532px" }} label="Description">
              <Input.TextArea
                style={{ minHeight: "140px" }}
                placeholder="Description"
                value={currentData.description}
                onChange={(e) =>
                  setCurrentData({
                    ...currentData,
                    description: e.target.value,
                  })
                }></Input.TextArea>
            </Form.Item>
            <Form.Item label="Qty">
              <InputNumber
                style={{ width: "86px", height: "44px" }}
                min={1}
                placeholder="Qty"
                value={currentData.qty}
                onChange={(value) =>
                  setCurrentData({ ...currentData, qty: value })
                }></InputNumber>
            </Form.Item>
            <Form.Item className="maintenance-invoice-price" label="Price">
              <Input
                style={{ height: "44px" }}
                min={1}
                placeholder="Price"
                value={currentData.price}
                onChange={(e) =>
                  setCurrentData({ ...currentData, price: e.target.value })
                }></Input>
            </Form.Item>
            <Form.Item className="maintenance-invoice-tax" label="Tax">
              <Input
                style={{ height: "44px" }}
                min={1}
                placeholder="Tax"
                value={currentData.tax}
                onChange={(e) =>
                  setCurrentData({ ...currentData, tax: e.target.value })
                }></Input>
            </Form.Item>
            <Form.Item className="maintenance-invoice-amount" label="Amount">
              <Input
                style={{ height: "44px" }}
                min={1}
                placeholder="Amount"
                value={currentData.amount}
                onChange={(e) =>
                  setCurrentData({ ...currentData, amount: e.target.value })
                }></Input>
            </Form.Item>
            <Form.Item className="maintenance-invoice-btn">
              <Button type="text" onClick={handleCheckboxClick}>
                <CheckBox />
              </Button>
            </Form.Item>
          </div>
        </Card>

        <Form.Item>
          <Table
            columns={columns}
            dataSource={formData.map((item, index) => ({
              key: index,
              ...item,
            }))}
            style={{ marginTop: "20px" }}
          />
        </Form.Item>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button className="btn">Cancel</Button>
          <Button className="btn filter">Update</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MakeInvoice;
