import { Card, Checkbox, Form, Table, Row, Col, Breadcrumb } from "antd";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetPermissionByIdQuery } from "../../features/adminstrator/adminApi";
import { useSelector } from "react-redux";

const RoleCreate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = location.state || {};

  const token = useSelector((state) => state.authSlice.token2);

  const {
    data: PermissionListData,
    isLoading,
    error,
  } = useGetPermissionByIdQuery({ roleId: role.key }, token);

  // Map the API response to the format expected by the Table's dataSource
  const dataSource = PermissionListData?.data?.map((permission, index) => ({
    key: index + 1, // Ensure each row has a unique key
    module: permission.module_name.replace("_", " "), // Replace underscores with spaces for better readability
    read: permission.read,
    write: permission.write,
    create: permission.create,
    delete: permission.delete,
  }));

  const columns = [
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      width: "40%",
      align: "center",
    },
    {
      title: "READ",
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (text, record) => <Checkbox checked={record.read} />,
    },
    {
      title: "WRITE",
      dataIndex: "write",
      key: "write",
      align: "center",
      render: (text, record) => <Checkbox checked={record.write} />,
    },
    {
      title: "CREATE",
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (text, record) => <Checkbox checked={record.create} />,
    },
    {
      title: "DELETE",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (text, record) => <Checkbox checked={record.delete} />,
    },
  ];

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item
          onClick={() => navigate(`/${id}/adminstrator/role-permission`)}>
          Role & Permissions
        </Breadcrumb.Item>
        <Breadcrumb.Item>View Role</Breadcrumb.Item>
      </Breadcrumb>
      <Card title="View Role" style={{ marginBottom: "20px" }}>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <p>
              <strong>Role</strong>
            </p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={16}>
            <p>{role.role}</p>
          </Col>
        </Row>
      </Card>
      <div className="permission-table">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon
                onClick={() => navigate(`/${id}/adminstrator/role-permission`)}
                style={{ cursor: "pointer" }}
              />
              <span style={{ marginLeft: "8px" }}>Group of Permissions</span>
            </div>
          }>
          <Form>
            <Form.Item>
              <Table
                pagination={false}
                columns={columns}
                dataSource={dataSource}
                loading={isLoading}
                rowKey="key"
              />
            </Form.Item>
            {/* <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button className="btn filter">Create</Button>
        </Form.Item> */}
          </Form>
        </Card>
      </div>
    </>
  );
};

export default RoleCreate;
