import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  DatePicker,
  message,
  Button,
  Spin,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import styles from "./styles.css";
import {
  useAddNewPropertyMutation,
  useGetAllCitiesListQuery,
  useGetPropertyByPropertyIdQuery,
  useGetTownshipByCityIdQuery,
  useUpdatePropertyMutation,
} from "../../../features/tenant/tenantApi";
import {
  useGetAllBuildingQuery,
  useGetFloorsByBuildingIdQuery,
  useGetShopByZoneIdQuery,
  useGetZoneByBuildingIdQuery,
} from "../../../features/property/propertyApi";
import { useGetAllContractsQuery } from "../../../features/setting/contractApi";
import moment from "moment";

const { Dragger } = Upload;
const { Option } = Select;

const EditProperty = ({ propertyId, onBack }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [contractFileList, setContractFileList] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [selectedZone, setSelectedZone] = useState("");

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  // const { data: shopList, isLoading: isShopListLoading } =
  //   useGetAllShopsQuery();
  const { data: contractTypeList, isLoading: isContractTypeLoading } =
    useGetAllContractsQuery();

  const [
    addNewProperty,
    { isLoading: isAddingProperty, error: addTenantError },
  ] = useAddNewPropertyMutation();

  const { data: BuildingList, isLoading: isBuildingListLoading } =
    useGetAllBuildingQuery();

  const { data: floorList, isLoading: isFloorListLoading } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });

  const { data: zoneList, isLoading: isLoadingZones } =
    useGetZoneByBuildingIdQuery(
      { floorId: selectedFloor },
      {
        skip: !selectedFloor,
      }
    );

  const { data: shopList, isLoading: isShopListLoading } =
    useGetShopByZoneIdQuery(
      { zoneId: selectedZone },
      {
        skip: !selectedZone,
      }
    );

  const {
    data: propertyListData,
    isLoading,
    error,
  } = useGetPropertyByPropertyIdQuery({ propertyId });

  const tenantId = propertyListData?.data?.tenant;
  const contractFile = propertyListData?.data?.contract_file;
  const contractId = propertyListData?.data?.contract_id?._id;

  const [updateProperty] = useUpdatePropertyMutation();

  useEffect(() => {
    form.setFieldValue({ township: undefined });
  }, [selectedCity, form]);

  useEffect(() => {
    if (selectedBuilding && selectedFloor) {
      console.log("Building ID:", selectedBuilding);
      console.log("Floor ID:", selectedFloor);
    }
  }, [selectedBuilding, selectedFloor]);

  const handleRadioChange = (e) => {
    console.log("Selected value:", e.target.value); // Log the selected value
    form.setFieldsValue({ type: e.target.value });
  };

  const handleFileChange = ({ fileList }) => {
    setContractFileList(fileList);
  };

  const handleBeforeFileUpload = (file) => {
    return false;
  };

  const billingContactPerson = typeof propertyListData?.data?.billing_contact_person === 'string'
  ? JSON.parse(propertyListData?.data?.billing_contact_person)
  : propertyListData?.data?.billing_contact_person || {};

  useEffect(() => {
    if (propertyListData) {
      if (propertyListData?.data?.contract_file) {
        setContractFileList([
          {
            uid: "-1",
            name: propertyListData?.data?.contract_file?.split("/").pop(), 
            status: "done",
            url: propertyListData?.data?.contract_file,
          },
        ]);
      }

      if (billingContactPerson?.city) {
        setSelectedCity(billingContactPerson?.city);
      }
      if (propertyListData?.data?.shop?.building?._id) {
        setSelectedBuilding(propertyListData?.data?.shop?.building?._id);
      }
      if (propertyListData?.data?.shop?.floor?._id) {
        setSelectedFloor(propertyListData?.data?.shop?.floor?._id);
      }
      if (propertyListData?.data?.shop?.zone?._id) {
        setSelectedZone(propertyListData?.data?.shop?.zone?._id);
      }
    }
  }, [propertyListData]);

  const initialValues = propertyListData
  ? {
      outletName: propertyListData?.data?.outlet_name || "",
      billingName: billingContactPerson?.name || "",
      billingPhoneNumber: billingContactPerson?.phoneNumber || "",
      billingEmail: billingContactPerson?.email || "",
      billingCity: billingContactPerson?.city || "",
      billingTownship: billingContactPerson?.township || "",
      billingAddress: billingContactPerson?.address || "",
      buildingName: propertyListData?.data?.shop?.building?._id,
      buildingFloor: propertyListData?.data?.shop?.floor?._id,
      buildingZone: propertyListData?.data?.shop?.zone?._id,
      shopId: propertyListData?.data?.shop?._id,
      shop: propertyListData?.data?.shop?.name,
      contract_name: propertyListData?.data?.contract_name,
      contract_type: propertyListData?.data?.contract_id?.contractType,
      type: propertyListData?.data?.type || 1,
      start_date: propertyListData?.data?.start_date
        ? moment(propertyListData?.data?.start_date)
        : null,
      due_date: propertyListData?.data?.due_date
        ? moment(propertyListData?.data?.due_date)
        : null,
    }
  : {};


  const onFinish = async (values) => {
    const shop_id = initialValues.shop === values.shop ? initialValues.shopId : values.shop;
    try {
      const payload = {
        outlet_name: values?.outletName,
        tenant: tenantId,
        type: values?.type,
        billing_contact_person: {
          name: values.billingName,
          phoneNumber: values.billingPhoneNumber,
          email: values.billingEmail,
          city: values.billingCity,
          township: values.billingTownship,
          address: values.billingAddress,
        },
        shop_id: shop_id,
        contract_name: values.contract_name,
        contract_id: contractId,
        start_date: values.start_date,
        due_date: values.due_date,
        contract_file: contractFileList[0]?.originFileObj
          ? contractFileList[0]?.originFileObj
          : contractFile,
      };
      console.log("payload", payload);

      await updateProperty({
        params: payload,
        propertyId: propertyId,
      }).unwrap();
      message.success("Property is updated successfully");
      onBack();
    } catch (error) {
      message.error(`Not available to update this property.`);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        />
      ) : (
        <Form
          initialValues={initialValues}
          form={form}
          layout="vertical"
          onFinish={onFinish}>
          <Card
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon onClick={onBack} style={{ cursor: "pointer" }} />
                <span style={{ marginLeft: "8px" }}>Edit Property</span>
              </div>
            }
            extra={
              <div style={{ paddingTop: "25px" }}>
                <Form.Item
                  name="type"
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <Radio.Group onChange={handleRadioChange} name="radiogroup">
                    <Radio value={1}>Lease</Radio>
                    <Radio value={2}>Owner</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            }>
            <div className="billing-contact-form">
              <Form.Item
                label="Outlet Name"
                className="tenant-label-group"
                name="outletName">
                <Input style={{ height: 44 }} placeholder="Outlet Name" />
              </Form.Item>
            </div>
          </Card>

          <Card title="Billing Contact Person" style={{ marginTop: "15px" }}>
            <div className="add-new-tenant">
              <Form.Item
                className="tenant-label-group"
                label="Name"
                name="billingName">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Phone Number"
                name="billingPhoneNumber">
                <Input style={{ height: 44 }} placeholder="Phone Number" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Email"
                name="billingEmail">
                <Input style={{ height: 44 }} placeholder="Email" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="City"
                name="billingCity">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select city name"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isCityListLoading}>
                  {cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Township"
                name="billingTownship">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a township"
                  loading={isTownshipListLoading}>
                  {townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Address"
                style={{ width: 705 }}
                name="billingAddress">
                <Input style={{ height: 44 }} placeholder="Address" />
              </Form.Item>
            </div>
          </Card>

          <Card title="Property Information" style={{ marginTop: "15px" }}>
            <div className="add-new-tenant">
              {/* <Form.Item className="tenant-label-group" label="Branch">
              <Select
                placeholder="Select Branch"
                style={{ height: 44 }}>
                {branches.map((branch) => (
                  <Option key={branch._id} value={branch._id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}

              <Form.Item
                name="buildingName"
                className="tenant-label-group"
                label="Building">
                <Select
                  onChange={(value) => setSelectedBuilding(value)}
                  style={{ height: 44 }}
                  placeholder="Select Building">
                  {BuildingList?.data?.buildings?.map((building) => (
                    <Select.Option key={building._id} value={building._id}>
                      {building.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="buildingFloor"
                className="tenant-label-group"
                label="Floor">
                <Select
                  onChange={(value) => setSelectedFloor(value)}
                  style={{ height: 44 }}
                  placeholder="Select Floor">
                  {floorList?.data.map((floor) => (
                    <Select.Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="buildingZone"
                className="tenant-label-group"
                label="Zone / Role">
                <Select
                  onChange={(value) => setSelectedZone(value)}
                  style={{ height: 44 }}
                  placeholder="Select Zone / Role">
                  {zoneList?.data?.map((zone) => (
                    <Select.Option key={zone._id} value={zone._id}>
                      {zone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="shop"
                className="tenant-label-group"
                label="Shop">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select shop name"
                  loading={isShopListLoading}>
                  {shopList?.data?.map((shop) => (
                    <Option key={shop._id} value={shop._id}>
                      {shop?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* <Form.Item className="tenant-label-group" label="Price (MMK)">
              <Input style={{ height: 44 }} placeholder="Price" />
            </Form.Item> */}
            </div>
          </Card>

          <Card title="Contract Information" style={{ marginTop: "15px" }}>
            <div className="contract-form">
              <Form.Item
                className="contract-label-group"
                name="contract_name"
                label="Name">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                className="contract-label-group"
                name="contract_type"
                label="Contract Type">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select contract type"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isContractTypeLoading}>
                  {contractTypeList?.data?.map((contract) => (
                    <Option key={contract._id} value={contract._id}>
                      {contract?.contractType}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="start_date" label="Start Date">
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="Start Date"
                />
              </Form.Item>

              <Form.Item name="due_date" label="Due Date">
                <DatePicker
                  style={{ width: 522, height: 44 }}
                  placeholder="Due Date"
                />
              </Form.Item>

              <Form.Item label="Contract">
                <Dragger
                  onChange={handleFileChange}
                  beforeUpload={handleBeforeFileUpload}
                  multiple={false}
                  maxCount={1}
                  listType="text"
                  accept=".pdf, .jpg, .jpeg, .png"
                  style={{ width: 522, height: 44 }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single upload.
                  </p>
                </Dragger>
              </Form.Item>
            </div>
          </Card>

          <Form.Item>
            <div className="add-tenant-btn-group">
              <Button
                onClick={() => navigate(`/${id}/tenant/list`)}
                className="btn">
                Cancel
              </Button>
              <Button
                type="text"
                htmlType="submit"
                loading={isAddingProperty}
                disabled={isAddingProperty}
                className="btn create">
                Create
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default EditProperty;
