import {
  Form,
  Input,
  Button,
  message,
  ConfigProvider,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useAddTenantCategoryMutation } from "./tenantCategoryApi";

const { TextArea } = Input;

const AddTenantCategory = ({ handleOk, handleCancel }) => {
  const token = useSelector((state) => state.authSlice.token2);

  const [ addNewTenantCategory, { isLoading, error }] = useAddTenantCategoryMutation(token);
  const [form] = Form.useForm(); 

  const handleSubmit = async (values) => {
    const payload = {
      name: values.name,
      description: values.description,
    };
    try {
      await addNewTenantCategory(payload).unwrap();
      form.resetFields(); 
      message.success("Tenant Category added successfully.");
      handleOk();
    } catch (error) {
      const errorMessage = error?.data?.errors?.[0] || "Failed to tenant category";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields(); 
    handleCancel(); 
  };

  return (
    <ConfigProvider  theme={{
      token: {
        colorBgContainer: "#f8f7f7",
      },
    }}>
      <Form
        form={form} // Assign the form instance to the Form component
        layout="vertical"
        onFinish={handleSubmit}>
        <Form.Item label="New Tenant Category" name="name">
          <Input placeholder="Tenant Category" style={{ height: 44 }} />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <TextArea rows={4} placeholder="Description" />
        </Form.Item>
        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="text" className="btn" onClick={handleFormCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="btn filter"
            loading={isLoading}>
            Create
          </Button>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default AddTenantCategory;
