import { Card, Form, Input, Button } from "antd";
import React, { useState } from "react";
import { AddNewShop, ShopList } from "../../features";
import AddIcon from "@mui/icons-material/Add";
import { Link, useParams } from "react-router-dom";

const { Search } = Input;

const AddShop = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { id } = useParams();

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  return (
    <>
     <div className="page-new-add">
        <h3 className="page-title">Shop List</h3>
        <div className="property-search">
          <Form.Item>
            <Link to={`/${id}/property/add-shop/create-shop`}>
            <Button className="property-add-btn" >
              <AddIcon />
              Add New Shop
            </Button>
            </Link>
          </Form.Item>
        </div>
      </div>

      <div className="search-picker-combo">
        <Search
          placeholder="Shop"
          allowClear
          enterButton="Search"
          onSearch={handleSearch}
          size="large"
          style={{ width: 456, marginBottom:"15px" }}
        />
      </div>
        <ShopList searchTerm={searchTerm} />
    </>
  );
};

export default AddShop;
