import React from "react";
import styles from "./styles.css";
import {
  useGetPropertyByPropertyIdQuery,
} from "../../../features/tenant/tenantApi";
import OwnDetail from "./OwnDetail";
import LeaseDetails from "./LeaseDetail";

const PropertyDetail = ({ type, onBack, propertyId }) => {
  const {
    data: propertyListData,
    isLoading,
    error,
  } = useGetPropertyByPropertyIdQuery({ propertyId });

  return (
    <div>
      {type === "Lease" ? (
        <LeaseDetails onBack={onBack} propertyData={propertyListData} />
      ) : (
        <OwnDetail onBack={onBack} propertyData={propertyListData} />
      )}
    </div>
  );
};

export default PropertyDetail;
