import { Card } from "antd";
import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import {
  LinePlot,
  MarkPlot,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts/LineChart";

const MonthlyInvoice = () => {
  const pData = [1660000, 860000, 660000, 1060000];
  const xLabels = ["Outstanding", "Paid", "Unpaid", "Due"];
  return (
    <div style={{ display: "flex", marginBottom: "20px" }}>
      <Card title="Monthly Invoice" style={{ width: "75%" }}>
        <BarChart
          margin={{ left: 80 }}
          width={780}
          height={300}
          series={[{ data: pData }]}
          yAxis={[{ min: 0 }]}
          xAxis={[
            {
              data: xLabels,
              scaleType: "band",
              colorMap: {
                type: "ordinal",
                colors: ["#3BC7D8", "#8840E6", "#28C76F", "#E90A16"],
              },
            },
          ]}
        />
      </Card>
      <Card title="Yearly" style={{ width: "25%" }}>
        <ChartContainer
          width={208}
          height={300}
          series={[{ type: "line", data: pData }]}
          xAxis={[{ scaleType: "point", data: xLabels }]}
          sx={{
            [`& .${lineElementClasses.root}`]: {
              stroke: "#8884d8",
              strokeWidth: 2,
            },
            [`& .${markElementClasses.root}`]: {
              stroke: "#8884d8",
              scale: "0.6",
              fill: "#fff",
              strokeWidth: 2,
            },
          }}
          disableAxisListener
          >
          <LinePlot />
          <MarkPlot />
        </ChartContainer>
      </Card>
    </div>
  );
};

export default MonthlyInvoice;
