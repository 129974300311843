import { Card, Form, Input, Button, message } from "antd";
import React, { useEffect } from "react";
import {
  useGetAllTermsNConditonsQuery,
  useUpdateTermNConditionMutation,
} from "../../features/setting/tcApi";
import { useSelector } from "react-redux";

const TermsNCondition = () => {
  const token = useSelector((state) => state.authSlice.token2);
  const { data: TCList, isLoading, error } = useGetAllTermsNConditonsQuery(token);
  const [updateTCList, { isLoading: updateTCLoading }] =
    useUpdateTermNConditionMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (TCList) {
      const description = TCList?.data?.TC?.[0]?.description;
      form.setFieldsValue({ description }); // Set the form field value
    }
  }, [TCList, form]);

  const handleSubmit = async (values) => {
    try {
      const tcId = TCList?.data?.TC?.[0]?._id; 
      console.log("Tc id", tcId);
      const payload = {
        tcId: tcId,
        updatedData: {
          description: values.description,
        }
      };
      console.log("payload", payload);
      await updateTCList(payload).unwrap();
      message.success("Term and Condition is updated successfully.");
    } catch (err) {
      console.error("Failed to update Term and Condition:", err);
      message.error("Failed to update Term and Condition.");
    }
  };

  return (
    <div>
      <Card title="Terms & Conditions Setting">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item label="Term & Condition" name="description">
            <Input.TextArea style={{ height: "100px" }} />
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button className="btn">Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn filter"
              loading={isLoading || updateTCLoading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default TermsNCondition;
