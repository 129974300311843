import React, { useState } from "react";
import {
  Card,
  Form,
  DatePicker,
  Select,
  Button,
  Dropdown,
  Space,
  Modal,
  Table,
  Input,
} from "antd";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DownOutlined } from "@ant-design/icons";

const NotificationList = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewDetailModal, setIsViewDetailModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  //Delete Modal
  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  // View Detail Modal Process
  const showViewDetailModal = (record) => {
    setCurrentRecord(record);
    setIsViewDetailModal(true);
  };

  const handleViewDetailCancel = () => {
    setIsViewDetailModal(false);
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
    },
    {
      title: "Outlet Name",
      dataIndex: "outletName",
      key: "outletName",
    },
    {
      title: '',
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: "View Details",
                  onClick: () => showViewDetailModal(record),
                },
                {
                  key: "2",
                  label: "Delete",
                  onClick: () => showDeleteModal(),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{border: '1px solid darkblue'}}>Action<DownOutlined/></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      date: "11-11-2023",
      type: "Maintenance",
      tenantName: "Tenant 1",
      outletName: "Outlet 1",
    },
    {
      key: "2",
      date: "11-11-2023",
      type: "Invoice",
      tenantName: "Tenant 2",
      outletName: "Outlet 2",
    },
  ];

  return (
    <>
       <div className="page-new-add">
        <div>
          <h3 className="page-title">Notification List</h3>
          <p className="total-number">
            Unread :{" "}
            <div className="total-no-display">
              {/* {announcementListData?.data?.announcements} */}
            </div>
          </p>
        </div>
        </div>

      <Table
        style={{ marginTop: "15px" }}
        columns={columns}
        dataSource={dataSource}></Table>

      {/* delete modal */}
      <Modal
        title="Are you sure to delete this property list?"
        open={isDeleteModalOpen}
        okText="Confirm"
        okType="danger"
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}></Modal>

      {/* View Detail modal */}
      <Modal
        title="Notification Details"
        open={isViewDetailModal}
        onCancel={handleViewDetailCancel}
        footer={null}
        width={780}>
        <Form layout="vertical">
          <Form.Item label="Date">
            <Input style={{ height: 44 }} defaultValue={currentRecord?.date} />
          </Form.Item>

          <Form.Item label="Type">
            <Input style={{ height: 44 }} defaultValue={currentRecord?.type} />
          </Form.Item>

          <Form.Item label="Tenant Name">
            <Input style={{ height: 44 }} defaultValue={currentRecord?.tenantName} />
          </Form.Item>

          <Form.Item label="Outlet Name">
            <Input style={{ height: 44 }} defaultValue={currentRecord?.outletName} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NotificationList;
