import { baseApi } from "../../app/baseApi";
export const propertyApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBuilding: builder.query({
      query: () => `/Property/buildings/list`,
      providesTags: ["BuildingList"],
    }),

    getAllFloorList: builder.query({
      query: () => `/Property/floors/list`,
      providesTags: ["FloorList"],
    }),

    getAllZone: builder.query({
      query: () => `/Property/zones/list`,
      providesTags: ["ZoneList"],
    }),

    getAllShops: builder.query({
      query: () => `/Property/shops/list`,
      providesTags: ["ShopList"],
    }),

    AddNewBuilding: builder.mutation({
      query: ({ name }) => ({
        url: `/Property/buildings/create`,
        method: "POST",
        body: {
          name,
        },
      }),
      invalidatesTags: ["BuildingList"],
    }),

    AddNewFloor: builder.mutation({
      query: ({ name, number_of_shop, area, price_per_sq, building }) => ({
        url: `/Property/floors/create`,
        method: "POST",
        body: {
          name,
          number_of_shop,
          area,
          price_per_sq,
          building,
        },
      }),
      invalidatesTags: ["FloorList"],
    }),

    addNewZone: builder.mutation({
      query: ({ name, building, floor }) => ({
        url: `/Property/zones/create`,
        method: "POST",
        body: {
          name,
          building,
          floor,
        },
      }),
      invalidatesTags: ["ZoneList"],
    }),

    addNewShop: builder.mutation({
      query: ({
        name,
        code,
        status,
        total_area,
        price,
        floor,
        building,
        zone,
      }) => ({
        url: `/Property/shops/create`,
        method: "POST",
        body: {
          name,
          code,
          status,
          total_area,
          price,
          floor,
          building,
          zone,
        },
      }),
      invalidatesTags: ["ShopList"],
    }),

    updateBuilding: builder.mutation({
      query: ({ buildingId, updatedData }) => ({
        url: `/Property/buildings/update/${buildingId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit building name failed:", error);
      },
      invalidatesTags: ["BuildingList"],
    }),

    updateFloor: builder.mutation({
      query: ({ floorId, updatedData }) => ({
        url: `/Property/floors/update/${floorId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit floor is failed:", error);
      },
      invalidatesTags: ["FloorList"],
    }),

    updateZone: builder.mutation({
      query: ({ zoneId, updatedData }) => ({
        url: `Property/zones/update/${zoneId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit zone is failed:", error);
      },
      invalidatesTags: ["ZoneList"],
    }),

    updateShop: builder.mutation({
      query: ({ shopId, updatedData }) => ({
        url: `/Property/shops/update/${shopId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit Shop is failed:", error);
      },
      invalidatesTags: ["ShopList"],
    }),

    deleteBuilding: builder.mutation({
      query: ({ buildingId }) => ({
        url: `/Property/buildings/delete/${buildingId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BuildingList"],
    }),

    deleteFloor: builder.mutation({
      query: ({ floorId }) => ({
        url: `/Property/floors/delete/${floorId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FloorList"],
    }),

    deleteZone: builder.mutation({
      query: ({ zoneId }) => ({
        url: `/Property/zones/delete/${zoneId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ZoneList"],
    }),

    deleteShop: builder.mutation({
      query: ({ shopId }) => ({
        url: `/Property/shops/delete/${shopId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ShopList"],
    }),

    getFloorsByBuildingId: builder.query({
      query: (buildingId) => `common/property-filter?building=${buildingId}`,
      providesTags: ["FloorList", "ZoneList"],
    }),
    
    getZoneByBuildingId: builder.query({
      query: ({ floorId }) =>
        `/common/property-filter?floor=${floorId}`,
      providesTags: ["ZoneList"],
    }),

    getShopByZoneId: builder.query({
      query: ({zoneId}) => `/common/property-filter?zone=${zoneId}`,
      providesTags: ["ShopList"],
    })
    
  }),
  overrideExisting: false,
});

export const {
  useGetAllBuildingQuery,
  useAddNewBuildingMutation,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
  useGetAllFloorListQuery,
  useAddNewFloorMutation,
  useUpdateFloorMutation,
  useDeleteFloorMutation,
  useGetAllZoneQuery,
  useUpdateZoneMutation,
  useDeleteZoneMutation,
  useAddNewZoneMutation,
  useGetAllShopsQuery,
  useAddNewShopMutation,
  useUpdateShopMutation,
  useDeleteShopMutation,
  useGetFloorsByBuildingIdQuery,
  useGetZoneByBuildingIdQuery,
  useGetShopByZoneIdQuery
} = propertyApi;
