import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Button,
  Upload,
  Select,
  message,
  Modal,
  Breadcrumb,
} from "antd";
import styles from "../property/styles.css"; // Ensure this path is correct.
import { isJPGorPNG } from "../../utils";
import {
  useUpdateAdminDataMutation,
  useGetAllRolesQuery,
} from "../../features/adminstrator/adminApi";
import { ChangePwdAdmin } from "..";
import ImgCrop from "antd-img-crop";

const { Option } = Select;

const UpdateAdmin = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const adminData = location.state?.adminData;
  const adminId = adminData.key;

  const [loading, setLoading] = useState(false);
  const [updateAdminData, { isLoading }] = useUpdateAdminDataMutation();
  const [fileList, setFileList] = useState([]);

  const [imageObj, setImageObj] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const {
    data: roleDataList,
    isLoading: isRoleLoading,
    error,
  } = useGetAllRolesQuery();

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setImgUrl(reader.result);
      },
      false
    );
    if (imageObj) {
      reader.readAsDataURL(imageObj);
    }
  }, [imageObj]);

  useEffect(() => {
    if (adminData?.profile) {
      setFileList([
        {
          uid: "-1",
          name: "profile.png",
          status: "done",
          url: adminData.profile,
        },
      ]);
      setImgUrl(adminData.profile);
    }
  }, [adminData]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.originFileObj) {
        return {
          ...file,
          preview: URL.createObjectURL(file.originFileObj),
        };
      }
      return file;
    });

    setFileList(newFileList);
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return false;
  };

  const handleShowPreview = () => {
    setShowPreviewModal(true);
  };

  const handlePreviewCancel = () => {
    setShowPreviewModal(false);
  };

  const handleFormFinish = async (values) => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
        phone_number: values.phone_number,
        status: values.status,
        role: values.role, // Role ID
        profile: imageObj, // Include the uploaded profile image
      };
      console.log("Payload in update admin", payload);

      // Perform the update mutation
      const response = await updateAdminData({
        adminId: id,
        updatedData: payload,
      }).unwrap();
      console.log("response", response);

      message.success("Admin updated successfully!");
      navigate(`/${id}/adminstrator/list`);
    } catch (error) {
      message.error("Failed to update admin. Please try again.");
      console.error("Error updating admin:", error);
    }
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/adminstrator/list`)}>
          Admin List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Admin</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        style={{ width: 880, margin: "auto" }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: "8px" }}>Edit Admin</span>
          </div>
        }>
        <Form layout="vertical" onFinish={handleFormFinish}>
          <Form.Item
            rules={[
              { required: true, message: "Please upload tenant profile" },
            ]}>
            <ImgCrop rotationSlider>
              <Upload
                onPreview={handleShowPreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                listType="picture-circle"
                accept=".png,.jpg,image/png,image/jpeg"
                multiple={false}
                maxCount={1}
                fileList={fileList}>
                {fileList.length === 0 && "+ Upload"}
              </Upload>
            </ImgCrop>
          </Form.Item>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}>
            <Form.Item
              className="input-group"
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input the name!" }]}
              initialValue={adminData?.name}>
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              className="input-group"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email!",
                },
              ]}
              initialValue={adminData?.email}>
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phone_number"
              className="input-group"
              rules={[
                { required: true, message: "Please input the phone number!" },
              ]}
              initialValue={adminData?.phone_number}>
              <Input style={{ height: 44 }} />
            </Form.Item>

            <Form.Item
              label="Role"
              name="role"
              className="input-group"
              rules={[{ required: true, message: "Please select a role!" }]}
              initialValue={adminData?.role}>
              <Select
                loading={isRoleLoading}
                placeholder="Select a role"
                style={{ height: 44 }}>
                {error && <Option disabled>Error loading roles</Option>}
                {!isRoleLoading && roleDataList?.data?.roles?.length > 0 ? (
                  roleDataList.data.roles.map((role) => (
                    <Option key={role._id} value={role._id}>
                      {role.name}
                    </Option>
                  ))
                ) : (
                  <Option disabled>No roles available</Option>
                )}
              </Select>
            </Form.Item>

            <Form.Item
              label="Status"
              className="tenant-label-group"
              name="status">
              <Select style={{ height: 44 }} placeholder="Select Status">
                <Option value={1}>Active</Option>
                <Option value={2}>Suspend</Option>
              </Select>
            </Form.Item>

            <Form.Item
              style={{
                width: "1130px",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}>
              <Button
                className="btn"
                onClick={() => navigate(`/${id}/adminstrator/list`)}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn update"
                loading={isLoading}>
                Update
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
      {/* <ChangePwdAdmin adminId={adminId} /> */}

      <Modal
        visible={showPreviewModal}
        title="Image Preview"
        footer={null}
        onCancel={handlePreviewCancel}>
        <img alt="preview" style={{ width: "100%" }} src={imgUrl} />
      </Modal>
    </>
  );
};

export default UpdateAdmin;
