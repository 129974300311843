import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import styles from "./styles.css";

const ChangePwd = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isPasswordMismatch, setIsPasswordMismatch] = useState(false);

  const onFinish = (values) => {
    console.log("Success:", values);
    navigate("/sign-in");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onValuesChange = (changedValues, allValues) => {
    const { newPassword, confirmPassword } = allValues;

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setIsPasswordMismatch(true);
    } else {
      setIsPasswordMismatch(false);
    }
  };

  return (
    <div className="sign-in-form">
      <Form
        form={form}
        className="sign-in"
        layout="vertical"
        name="sign-in"
        style={{ width: "450px" }}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
        autoComplete="off">
        <h2 className="header">TENANT MANAGEMENT SYSTEM</h2>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
            {
              pattern:
                /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message:
                "Your password must have minimum eight characters with at least one uppercase letter, one number and one special character.",
            },
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your confirm password!",
            },
            {
              validator: (_, value, callback) => {
                if (!value || value === form.getFieldValue("newPassword")) {
                  setIsPasswordMismatch(false);
                  callback();
                } else {
                  setIsPasswordMismatch(true);
                  callback("Passwords do not match.");
                }
              },
            },
          ]}
          validateStatus={isPasswordMismatch ? "error" : ""}
          help={isPasswordMismatch && "Passwords do not match."}>
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            className="sign-in-submit-btn"
            type="primary"
            htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePwd;
