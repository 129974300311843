import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Statistic } from "antd";
import OtpInput from "react-otp-input";
import styles from "./styles.css";

const VerifyOtp = () => {
  const { Countdown } = Statistic;
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);
    navigate("change-pwd");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (val) => {
    if (typeof val === "number" && 4.95 * 1000 < val && val < 5 * 1000) {
      console.log("changed!");
    }
  };

  return (
    <div className="sign-in-form">
      <Form
        className="sign-in"
        layout="vertical"
        name="sign-in"
        style={{ width: "450px" }}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
          
        <h2 className="header">TENANT MANAGEMENT SYSTEM</h2>
        <p className="otp-msg">We will send to your papa@gmail.com</p>

        <Form.Item>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle={"otp-form"}
            shouldAutoFocus={true}
            renderInput={(props) => <input {...props} />}
          />
        </Form.Item>

        <Form.Item>
          <div className="resent-otp">
            <Countdown
              value={Date.now() + 10 * 1000}
              onChange={onChange}
              style={{ fontSize: "14px" }}
            />
            <Button className="send-again-msg" type="link">
              Send Again
            </Button>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="sign-in-submit-btn">
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VerifyOtp;
