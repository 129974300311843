import React, { useState, useEffect } from "react";
import { Button, Table, Dropdown, Space, Modal, message, Divider } from "antd";
import { useSelector } from "react-redux";
import {
  useDeleteTenantCategoryMutation,
  useGetAllTenantCategoryQuery,
} from "../tenant/tenantCategoryApi";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import EditTenantCategory from "./EditTenantCategory";

const TenantCategoryList = ({ searchTerm }) => {
  const token = useSelector((state) => state.authSlice.token2);
  const { data: tenantCategoryList, isLoading } =
    useGetAllTenantCategoryQuery(token);

  const [deleteTenantCategory] = useDeleteTenantCategoryMutation(token);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteTenantCategory({ categoryId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Tenant Category is successfully deleted.");
        // Optionally refresh the table data
      } catch (err) {
        console.error("Failed to delete contract:", err);
        message.error("Failed to delete this contract type");
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setSelectedRecord(record);
    setIsEditModalOpen(true);
  };

  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "Tenant Category",
      dataIndex: "tenantCategory",
      key: "tenantCategory",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "20%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const [filteredTenantCategory, setFilteredTenantCategory] = useState([]);

  useEffect(() => {
    if (tenantCategoryList) {
      // Filtering logic based on search term
      const filteredData = tenantCategoryList.data.filter((category) =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredTenantCategory(filteredData);
    }
  }, [searchTerm, tenantCategoryList]);

  const tableData = filteredTenantCategory.map((category, index) => ({
    key: category._id,
    no: index + 1,
    tenantCategory: category.name,
    description: category.description,
  }));

  return (
    <div>
      <Table
        loading={isLoading}
        style={{ marginTop: "20px" }}
        columns={columns}
        dataSource={tableData}></Table>

      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isDeleteModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button
              className="delete-btn"
              key="back"
              onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleDeleteOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>

      <Modal
        width={880}
        title="Edit Tenant Category"
        open={isEditModalOpen}
        onOk={handleEditOk}
        closeIcon={null}
        onCancel={handleEditCancel}
        footer={null}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <EditTenantCategory
          selectedRecord={selectedRecord}
          handleOk={handleEditOk}
          handleCancel={handleEditCancel}
        />
      </Modal>
    </div>
  );
};

export default TenantCategoryList;
