import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, DatePicker, Breadcrumb } from "antd";
import styles from "./styles.css";

const OwnerDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <>
       <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/list`)}>
          Property List
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/list/owner-history`)}>
          Owner History
        </Breadcrumb.Item>
        <Breadcrumb.Item>Owner Details</Breadcrumb.Item>
      </Breadcrumb>

      <Form>
        <Card title="Current Owner">
          <div className="view-detail-form">
          <Form.Item className="input-group">
            <h4 className="input-label">Owner Name</h4>
            <Input
              placeholder="Owner Name"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Email</h4>
            <Input
              placeholder="Email"
              style={{
                height: 44,
              }}
              rules={[
                {
                  type: "email",
                  message: "Please input email!",
                },
              ]}
              hasFeedback></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Phone Number</h4>
            <Input
              placeholder="09XXXXXXXXX"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">City</h4>
            <Input
              placeholder="City"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Township</h4>
            <Input
              placeholder="Township"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Address</h4>
            <Input
              style={{
                height: 44,
              }}
              placeholder="Address"
            />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Start Date</h4>
            <DatePicker
              style={{
                height: 44,
                width: 340,
              }}
              placeholder="Select Date"
            />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">End Date</h4>
            <DatePicker
              style={{
                height: 44,
                width: 340,
              }}
              placeholder="Select Date"
            />
          </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
}

export default OwnerDetail