import React from 'react'
import { Input, Table } from 'antd';

const { Search } = Input;

const AuditLogs = () => {

  const columns = [
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },

    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },

    {
      title: "Date Time",
      dataIndex: "dateTime",
      key: "dateTime",
    },
  ];

  const dataSource = [
    {
      key: 1,
      message: 'Create New Tenant',
      users: "spencer@gmail.com",
      role: "Super Admin",
      ipAddress: '49.37.209.145',
      action: 'Create',
      platform:'Window 10',
      agent: 'Firefox 128.0',
      dateTime: 'a day ago',
    }
  ]

  return (
    <div>
       <div className="page-new-add">
        <div>
          <h3 className="page-title">Audit Logs</h3>
          <p className="total-number">
          Here you can find the recent activity within your organization.
          </p>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Search"
          allowClear
          enterButton="Search"
          size="large"
          style={{
            width: 456,
          }}
        />
      </div>
      <Table style={{marginTop: 20}} dataSource={dataSource} columns={columns}/>
    </div>
  )
}

export default AuditLogs