import {
  Button,
  Card,
  Table,
  Dropdown,
  Space,
  Form,
  Select,
  Input,
  Tag,
  Modal,
  Divider,
  Row,
  Col,
  ConfigProvider
} from "antd";
import React, { useState } from "react";
import FilterIcon from "../../assets/icons/Vector.svg";
import TransferIcon from "../../assets/icons/Transfer.svg";
import OwnerHistoryIcon from "../../assets/icons/ownerHistory.svg";
import "./styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetAllShopsQuery } from "../../features/property/propertyApi";
import AddIcon from "@mui/icons-material/Add";
import {
  EyeOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";

const { Search } = Input;
const { Option } = Select;

const PropertyList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const token = useSelector((state) => state.authSlice.token2);
  const { data: shopListData, isLoading: isLoadingShops } =
    useGetAllShopsQuery(token);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleViewDetails = (shop) => {
    setSelectedShop(shop); // Set the selected shop to show details
    setIsViewModalOpen(true); // Open the modal when a shop is selected
  };

  const hanleViewCancel = () => {
    setIsViewModalOpen(false);
  };

  const tableData = shopListData?.data?.map((shop, index) => ({
    key: shop._id,
    no: index + 1,
    building: shop.building.name,
    floor: shop.floor?.name,
    shopName: shop.name,
    code: shop.code,
    area: shop.total_area,
    price: shop.price,
    zone: shop.zone?.name,
    status: shop.status === 1 ? "Available" : "Unavailable",
  }));

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> &nbsp; View
        </span>
      ),
      onClick: (shop) => handleViewDetails(shop),
    },
    {
      key: "2",
      label: (
        <span>
          <EditOutlined /> &nbsp; Edit
        </span>
      ),
      onClick: (shop) =>
        navigate(`edit-details/${shop.key}`, { state: { shop } }),
    },
    {
      key: "3",
      label: (
        <div style={{display:'flex', justifyContent:'center'}}>
          <img src={TransferIcon} alt="transfer icon" /> &nbsp; Transfer Owner
        </div>
      ),
      onClick: () => navigate("transfer-owner"),
    },
    {
      key: "4",
      label: (
        <span>
          <img src={OwnerHistoryIcon} alt="owner-history" /> &nbsp; Owner History
        </span>
      ),
      onClick: () => navigate("owner-history"),
    },
    {
      key: "5",
      label: (
        <span>
          <DeleteOutlined /> &nbsp; Delete
        </span>
      ),
      onClick: () => showModal(),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shop Name",
      dataIndex: "shopName",
      key: "shopName",
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Tag color={status === "Available" ? "green" : "red"}>{status}</Tag>
      ),
    },
    {
      title: '',
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record (shop data) to the onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{border: '1px solid darkblue'}}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return (
    <>
      <div className="page-new-add">
        <h3 className="page-title">Property List</h3>
        <div className="property-search">
          <Form.Item>
            <Link to={`/${id}/property/list/add-new-property`}>
              <Button className="property-add-btn">
                <AddIcon />
                Add New Property
              </Button>
            </Link>
          </Form.Item>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Search by Property Name"
          allowClear
          enterButton="Search"
          size="large"
          // onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: 456,
          }}
        />
      </div>
      <div className="filter-card">
        <Card title="Filter by :">
        <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
                colorTextPlaceholder: "#00062599",
                colorIcon:"#00062599",
              },
            }}>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="filter-card-info">
                <Select
                  placeholder="Status"
                  style={{
                    width: 150,
                    height: 38,
                  }}>
                  <Option value="">All</Option>
                  <Option value="Active">Active</Option>
                  <Option value="Suspend">Suspend</Option>
                </Select>
              </div>
              <div className="filter-btn">
                <Button className="filter-gp-btn clear">Clear</Button>
                <Button className="filter-gp-btn filter"><img src={FilterIcon} alt="filter icon"/>Filter</Button>
              </div>
            </div>
          </div>
          </ConfigProvider>
        </Card>
      </div>
      <Form>
        <Form.Item>
          <Table
            loading={isLoadingShops}
            columns={columns}
            dataSource={tableData}
            onChange={onChange}
            showSorterTooltip={{
              target: "sorter-icon",
            }}
            pagination={{
              pageSizeOptions: ["10", "20", "30"], // Options for 10, 20, or 50 items per page
              showSizeChanger: true, // Enables the dropdown for selecting page size
              defaultPageSize: 10, // Sets the default page size to 10
            }}
          />
        </Form.Item>
      </Form>

      {/* Modal to display shop details */}
      <Modal
        width={730}
        centered
        open={isViewModalOpen}
        onCancel={hanleViewCancel}
        footer={null}
        title="View Details">
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        {selectedShop && (
          <Row gutter={[16, 16]}>
          {/* Property Type and Building */}
          <Col span={4}>
            <p><strong>Property Type</strong></p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={8}>
            <p>{selectedShop.propertyType}</p>
          </Col>
          <Col span={4}>
            <p><strong>Building</strong></p>
          </Col>

          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={6}>
            <p>{selectedShop.building}</p>
          </Col>


          {/* Shop Name and Floor */}
          <Col span={4}>
            <p><strong>Shop Name</strong></p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={8}>
            <p>{selectedShop.shopName}</p>
          </Col>
          <Col span={4}>
            <p><strong>Floor</strong></p>
          </Col>

          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={6}>
            <p>{selectedShop.floor}</p>
          </Col>

          {/* Shop Code and Zone */}
          <Col span={4}>
            <p><strong>Shop Code</strong></p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={8}>
            <p>{selectedShop.code}</p>
          </Col>
          <Col span={4}>
            <p><strong>Zone</strong></p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={6}>
            <p>{selectedShop.zone}</p>
          </Col>

          {/* Total Area and Status */}
          <Col span={4}>
            <p><strong>Total Area</strong></p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={8}>
            <p>{selectedShop.area} sq ft</p>
          </Col>
          <Col span={4}>
            <p><strong>Status</strong></p>
          </Col>

          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={6}>
            <p>
              <Tag color={selectedShop.status === "Available" ? "green" : "red"}>
                {selectedShop.status}
              </Tag>
            </p>
          </Col>

          {/* Price */}
          <Col span={4}>
            <p><strong>Price</strong></p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={8}>
            <p>{selectedShop.price} MMK</p>
          </Col>
        </Row>
        )}
      </Modal>

      {/* Delete Modal */}
      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button className="delete-btn" key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
    </>
  );
};

export default PropertyList;
