import { baseApi } from "../../app/baseApi";

export const invoiceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoicesList: builder.query({
      query: ({ page = 1, limit = 10, status } = {}) => 
        `/Invoice/list?page=${page}&limit=${limit}&status=${status}`,
      providesTags: ["InvoiceList"],
    }),
    createInvoice: builder.mutation({
      query: ({ invoice }) => {      
        return {
          url: `/Invoice/upload/create`,
          method: "POST",
          body: invoice, // Pass the FormData directly
        };
      },
      invalidatesTags: ["InvoiceList"],
    }),    
    getDownloadExcelFile: builder.query({
      query: () => ({
        url: `/Invoice/excel-format-download/tenant`,
        method: 'GET',
        responseType: 'arraybuffer' 
      }),
      providesTags: ["InvoiceList"],
    }),  
    getReport: builder.query({
      query: () => ({
        url: `/Invoice/invoice-report`,
        method: 'GET',
      }),
      providesTags: ["InvoiceList"],
    }),   
    getInvoiceById: builder.query({
      query: ({ invoice_id }) =>
        `/Invoice/preview/edit/${invoice_id}`,
      providesTags: ["InvoiceList"],
    }),
    
    // updatePreviewInvoice: builder.mutation({
    //   query: ({  params , id }) => ({
    //     url: `/Invoice/preview/update/${id}`,
    //     method: "PATCH",
    //     body: updatedData,
    //     return {
    //       url: `/Tenant/tenants/get-properties-list/update/${propertyId}`,
    //       method: "PATCH",
    //       body: formData,
    //     };
    //   }),
    //   onError: (error) => {
    //     console.error("Edit type of issue failed:", error);
    //   },
    //   invalidatesTags: ["InvoiceList"],
    // }),

    updatePreviewInvoice: builder.mutation({
      query: ({ params, id }) => { 
        return {
          url: `/Invoice/preview/update/${id}`,
          method: "PATCH",
          body: JSON.stringify(params),
          headers: {
            // No need to set Content-Type when using FormData; the browser will automatically handle it
            'Content-Type': 'application/json',
          },
        };
      },
      invalidatesTags: ["InvoiceList"],
    }),  

    updateInvoice: builder.mutation({
      query: ({ params, id }) => { 
        return {
          url: `/Invoice/update/${id}`,
          method: "PATCH",
          body: JSON.stringify(params),
          headers: {
            // No need to set Content-Type when using FormData; the browser will automatically handle it
            'Content-Type': 'application/json',
          },
        };
      },
      invalidatesTags: ["InvoiceList"],
    }),  

   

    // addNewMaintenanceType: builder.mutation({
    //   query: ({ type, description }) => ({
    //     url: `/Maintenance/typeOfIssues/create`,
    //     method: "POST",
    //     body: {
    //       type,
    //       description,
    //     },
    //   }),
    //   invalidatesTags: ["MaintenanceTypes"],
    // }),

    // updateMaintenanceType: builder.mutation({
    //   query: ({ typeId, updatedData }) => ({
    //     url: `/Maintenance/typeOfIssues/update/${typeId}`,
    //     method: "PATCH",
    //     body: updatedData,
    //   }),
    //   onError: (error) => {
    //     console.error("Edit type of issue failed:", error);
    //   },
    //   invalidatesTags: ["MaintenanceTypes"],
    // }),

    // deleteMaintenanceType: builder.mutation({
    //   query: ({ typeId }) => ({
    //     url: `/Maintenance/typeOfIssues/delete/${typeId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["MaintenanceTypes"],
    // }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllInvoicesListQuery,
  useGetDownloadExcelFileQuery,
  useGetReportQuery,
  useCreateInvoiceMutation,
  useGetInvoiceByIdQuery,
  useUpdatePreviewInvoiceMutation,
  useUpdateInvoiceMutation,
} = invoiceApi;
