import { baseApi } from "../../app/baseApi";

export const tcApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTermsNConditons: builder.query({
      query: () => `/Setting/terms-conditions/list`,
      providesTags: ["TCList"],
    }),

    updateTermNCondition: builder.mutation({
      query: ({ tcId, updatedData }) => ({
        url: `/Setting/terms-conditions/update/${tcId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit contract failed:", error);
      },
      invalidatesTags: ["TCList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTermsNConditonsQuery, useUpdateTermNConditionMutation
} = tcApi;
