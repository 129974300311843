import { Form, Input, Button, message, ConfigProvider, Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetTenantCategoryByIdQuery,
  useUpdateTenantCategoryMutation,
} from "../tenant/tenantCategoryApi";

const { TextArea } = Input;

const EditTenantCategory = ({ selectedRecord, handleOk, handleCancel }) => {
  const token = useSelector((state) => state.authSlice.token2);

  const [updateTenantCategory, { isLoading: isUpdateLoading }] =
    useUpdateTenantCategoryMutation(token);
  const [form] = Form.useForm();

  const categoryId = selectedRecord?.key;

  const { data: tenantCategoryById, isLoading: isCategoryLoading } =
    useGetTenantCategoryByIdQuery(categoryId);

  useEffect(() => {
    if (tenantCategoryById?.data) {
      form.setFieldsValue({
        name: tenantCategoryById?.data?.name,
        description: tenantCategoryById?.data?.description,
      });
    }
  }, [tenantCategoryById, form]);

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        categoryId: categoryId,
        updatedData: {
          name: values.name,
          description: values.description,
        },
      };
      await updateTenantCategory(updatedData).unwrap();
      message.success("Tenant Category is updated successfully.");
      handleOk();
    } catch (isUpdateError) {
      const errorMessage =
        isUpdateError?.data?.errors?.[0] || "Failed to edit tenant category";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "#f8f7f7",
        },
      }}>
      <Spin spinning={isCategoryLoading}>
        <Form
          form={form} // Assign the form instance to the Form component
          layout="vertical"
          onFinish={handleEditOk}>
          <Form.Item label="Tenant Category" name="name">
            <Input placeholder="Tenant Category" style={{ height: 44 }} />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <TextArea rows={4} placeholder="Description" />
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="text" className="btn" onClick={handleFormCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn filter"
              loading={isUpdateLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </ConfigProvider>
  );
};

export default EditTenantCategory;
