import { baseApi } from "../../app/baseApi";

export const subTenantApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubTenant: builder.query({
      query: () => `/Tenant/subtenants/list`,
      providesTags: ["SubTenantLists"],
    }),

    getSubTenantProfileById: builder.query({
      query: ({ subTenantId }) =>
        `/Tenant/subtenants/get-profile/${subTenantId}`,
      providesTags: ["SubTenantProfile", "SubTenantLists"],
    }),

    getShopByTenantId: builder.query({
      query: ({ tenantId }) =>
        `/common/tenant-property-filter?tenant=${tenantId}`,
      providesTags: ["TenantList"],
    }),

    getPropertyBySubTenantId: builder.query({
      query: ({ tenantId }) => `/Tenant/subtenants/get-property/${tenantId}`,
      providesTags: ["SubTenantPropertyList"],
    }),

    getSubTenantDetailById: builder.query({
      query: ({ subTenantId }) => `/Tenant/subtenants/edit/${subTenantId}`,
      providesTags: ["SubTenantProfile", "SubTenantLists"],
    }),

    getSubPropertyBySubPropertyId: builder.query({
      query: ({subPropertyId}) => `/Tenant/subtenants/get-property/edit/${subPropertyId}`,
      providesTags: ["SubTenantProfile", "SubTenantLists", "SubTenantPropertyList"],
    }),

    addNewSubTenant: builder.mutation({
      query: (params) => {
        const formData = new FormData();

        // Append simple fields
        formData.append("photo", params.photo);
        formData.append("name", params.name);
        formData.append("outlet_name", params?.outlet_name);
        formData.append("phoneNumber", params.phoneNumber);
        formData.append("email", params.email);
        formData.append("city", params.city);
        formData.append("township", params.township);
        formData.append("address", params.address);
        formData.append("status", params.status);
        formData.append("password", params.password);

        // Append nested objects as JSON strings
        formData.append(
          "billing_contact_person",
          JSON.stringify(params.billing_contact_person)
        );
        formData.append(
          "property_information",
          JSON.stringify(params.property_information)
        );

        return {
          url: `/Tenant/subtenants/create`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["SubTenantLists"],
    }),

    addNewSubTenantProperty: builder.mutation({
      query: ({data, subTenantId}) => ({
        url: `/Tenant/subtenants/add-propery/${subTenantId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SubTenantLists", "SubTenantPropertyList"],
    }),

    addNewSubPassword: builder.mutation({
      query: ({ newPassword, subTenantId }) => ({
        url: `/Tenant/subtenants/reset-password/${subTenantId}`,
        method: "POST",
        body: {
          newPassword,
        },
      }),
      invalidatesTags: ["SubTenantLists", "SubTenantPropertyList", "SubTenantProfile"],
    }),   

    updateSubTenantDetail: builder.mutation({
      query: ({params, subTenantId}) => {
        const formData = new FormData();
  
        formData.append("name", params.name);
        formData.append("phoneNumber", params.phoneNumber);
        formData.append("email", params.email);
        formData.append("city", params.city);
        formData.append("township", params.township);
        formData.append("address", params.address);
        formData.append("status", params.status)
    
        // Append the profile image/file as binary data
        if (params.photo) {
          formData.append("photo", params.photo); // Assuming `params.profile` is a File object
        }
    
        return {
          url: `/Tenant/subtenants/update/${subTenantId}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          body: formData,
        };
      },
      invalidatesTags: ["SubTenantLists", "SubTenantPropertyList", "SubTenantProfile"],
    }),

    updateSubProperty: builder.mutation({
      query: ({data, subPropertyId}) => ({
        url: `/Tenant/subtenants/get-property/update/${subPropertyId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["SubTenantLists", "SubTenantPropertyList"],
    }),

    deleteSubTenant: builder.mutation({
      query: ({ tenantId }) => ({
        url: `/Tenant/subtenants/delete/${tenantId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubTenantLists"],
    }),

    deleteSubProperty: builder.mutation({
      query: ({ propertyId }) => ({
        url: `/Tenant/subtenants/get-property/delete/${propertyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubTenantPropertyList", "SubTenantProfile"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllSubTenantQuery,
  useGetSubTenantProfileByIdQuery,
  useAddNewSubTenantMutation,
  useGetShopByTenantIdQuery,
  useDeleteSubTenantMutation,
  useGetPropertyBySubTenantIdQuery,
  useGetSubTenantDetailByIdQuery,
  useUpdateSubTenantDetailMutation,
  useGetSubPropertyBySubPropertyIdQuery,
  useAddNewSubTenantPropertyMutation,
  useDeleteSubPropertyMutation,
  useUpdateSubPropertyMutation,
  useAddNewSubPasswordMutation
} = subTenantApi;
