import { Button, Card, DatePicker, Form, Input, Select } from "antd";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextArea from "antd/es/input/TextArea";
import styles from "./styles.css";

const AddNewReminder = ({ onBack }) => {
  return (
    <>
      <Card
        style={{ width: "60%" }}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon onClick={onBack} style={{ cursor: "pointer" }} />
            <span style={{ marginLeft: "8px" }}>Send Reminder</span>
          </div>
        }>
        <Form layout="vertical">
          <Form.Item label="Date">
            <DatePicker
              placeholder="Select Date"
              style={{ width: "100%", height: 44 }}></DatePicker>
          </Form.Item>
          <Form.Item label="Date">
            <Select
              mode="multiple"
              placeholder="Select Date"
              defaultValue={["Shop 1", "Shop 2"]}
              style={{ width: "100%", height: 44 }}></Select>
          </Form.Item>
          <Form.Item label="Title">
            <Input
              placeholder="Title"
              style={{ width: "100%", height: 44 }}></Input>
          </Form.Item>
          <Form.Item label="Description">
            <TextArea
              placeholder="Description"
              style={{ width: "100%", height: 44 }}></TextArea>
          </Form.Item>
          <div className="filter-btn">
            <Form.Item>
              <Button className="btn">Cancel</Button>
            </Form.Item>
            <Form.Item>
              <Button className="btn filter">Send</Button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddNewReminder;
