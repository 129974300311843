import React from "react";
import styles from "./auth.css";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { Content } from "antd/es/layout/layout";

const AuthLayout = () => {
  return (
    <Layout>
      <Content
        style={{
          width: "100%",
          backgroundColor: "transparent",
          zIndex: 0,
        }}>
        <div className="login-bg"></div>
        <div className="logo"></div>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AuthLayout;
