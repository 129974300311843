import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input, Button, DatePicker, Breadcrumb } from "antd";
import styles from "./styles.css";

const TransferOwner = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <>
       <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/list`)}>
          Property List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Transfer Owner</Breadcrumb.Item>
      </Breadcrumb>

      <Form>
        <Card title="Transfer Owner">
          <div className="view-detail-form">
          <Form.Item className="input-group">
            <h4 className="input-label">Owner Name</h4>
            <Input
              placeholder="Owner Name"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Email</h4>
            <Input
              placeholder="Email"
              style={{
                height: 44,
              }}
              rules={[
                {
                  type: "email",
                  message: "Please input email!",
                },
              ]}
              hasFeedback></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Phone Number</h4>
            <Input
              placeholder="09XXXXXXXXX"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">City</h4>
            <Input
              placeholder="City"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Township</h4>
            <Input
              placeholder="Township"
              style={{
                height: 44,
              }}></Input>
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Address</h4>
            <Input
              style={{
                height: 44,
              }}
              placeholder="Address"
            />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Start Date</h4>
            <DatePicker
              style={{
                height: 44,
                width: 340,
              }}
              placeholder="Select Date"
            />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Set Password</h4>
            <Input.Password
              style={{
                height: 44,
              }}
              placeholder="Set Password"
            />
          </Form.Item>
          </div>
        </Card>

        <Form.Item style={{width: '1130px', display: 'flex', justifyContent: 'flex-end', marginTop: "15px"}}>
          <Button className="btn update">Transfer</Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default TransferOwner