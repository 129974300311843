import React from "react";
import { Form, Input, Button } from "antd";
import styles from "./styles.css";
import { useNavigate } from "react-router-dom";

const ForgotPwd = () => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);
    navigate("verify-otp");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="sign-in-form">
      <Form
        className="sign-in"
        layout="vertical"
        name="sign-in"
        style={{ width: "450px" }}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <h2 className="header">TENANT MANAGEMENT SYSTEM</h2>
        <p className="email-msg">
          We will send you an One Time Password (OTP) to your email.
        </p>

        <Form.Item
          className="email"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
          hasFeedback>
          <Input />
        </Form.Item>
        
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="sign-in-submit-btn">
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ForgotPwd;
