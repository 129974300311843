import { Form, Input, Button, Select, message, Space } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useAddNewContractMutation } from "./contractApi";

const AddNewContract = ({handleOk, handleCancel}) => {
  const token = useSelector((state) => state.authSlice.token2);

  const [addNewBuilding, { isLoading, error }] =
    useAddNewContractMutation(token);
  const [form] = Form.useForm(); // Get the form instance

  const handleSubmit = async (values) => {
    const payload = {
      contractName: values.contractName,
      contractType: values.contractType,
      contractNumber: values.contractNumber,
      aboutToExpire: values.aboutToExpire,
    };
    try {
      await addNewBuilding(payload).unwrap();
      form.resetFields(); // Clear the input fields after successful submission
      message.success("Contract added successfully.");
      handleOk();
    } catch (err) {
      console.error("Failed to add new building:", err);
      message.error("Failed to add new contract type.");
    }
  };

  return (
    <Form
      form={form} // Assign the form instance to the Form component
      layout="vertical"
      onFinish={handleSubmit}>
        <Form.Item
        label="Contract Name"
        name="contractName"
        rules={[{ required: true, message: "Please input the expire date!" }]}>
        <Input style={{ height: 44 }} />
      </Form.Item>
      <Form.Item label="Contract Type">
        <Space.Compact style={{ width: "100%" }}>
          <Form.Item
            noStyle
            name="contractNumber"
            rules={[
              { required: true, message: "Please input the contract number!" },
            ]}>
            <Input style={{ height: 44, width:"80%" }} />
          </Form.Item>
          <Form.Item
            name="contractType"
            noStyle
            rules={[
              { required: true, message: "Please choose the contract type!" },
            ]}>
            <Select style={{ width: "20%", height: 44 }}>
              <Select.Option value="day">Day</Select.Option>
              <Select.Option value="month">Month</Select.Option>
              <Select.Option value="year">Year</Select.Option>
            </Select>
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="About to Expire"
        name="aboutToExpire"
        rules={[{ required: true, message: "Please input the expire date!" }]}>
        <Input suffix="Days" style={{ height: 44 }} />
      </Form.Item>
      <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button className="btn" onClick={handleCancel}>Cancel</Button>
        <Button
          type="primary"
          htmlType="submit"
          className="btn filter"
          loading={isLoading}>
          Create
        </Button>
      </Form.Item>
      {error && (
        <p style={{ color: "red" }}>
          {error.data?.message ||
            "Failed to create building. Please try again."}
        </p>
      )}
    </Form>
  );
};

export default AddNewContract;
