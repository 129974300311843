import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Space,
  Modal,
  Table,
  Form,
  Input,
  Select,
  message,
  Upload,
  Divider,
  ConfigProvider,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  useDeleteAnnouncementMutation,
  useGetAllAnnouncementListQuery,
  useAddNewAnnouncementMutation,
} from "../../features/announcement/announcementApi";
import { isJPGorPNG } from "../../utils";
import AddIcon from "@mui/icons-material/Add";
import { EyeOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const { Dragger } = Upload;

const AnnouncementList = () => {
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: announcementListData,
    isLoading,
    error,
  } = useGetAllAnnouncementListQuery(token);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isViewDetailModal, setIsViewDetailModal] = useState(false);
  const [isAddNewModal, setIsAddNewModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [form] = Form.useForm();
  const [imageObj, setImageObj] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [deleteAnnouncement] = useDeleteAnnouncementMutation();
  const [addNewAnnouncement] = useAddNewAnnouncementMutation();

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteAnnouncement({
          announcementId: currentRecord.key,
        }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Announcement is deleted successfully.");
      } catch (err) {
        console.error("Failed to delete announcement", err);
        message.error("Failed to delete this announcement.");
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showViewDetailModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      sendTo: record.sendTo,
      title: record.title,
      description: record.description,
    });
    setIsViewDetailModal(true);
  };

  const handleViewDetailCancel = () => {
    setIsViewDetailModal(false);
  };

  const showAddNewModal = () => {
    form.resetFields();
    setCurrentRecord(null);
    setIsAddNewModal(true);
  };

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setImgUrl(reader.result);
      },
      false
    );
    if (imageObj) {
      reader.readAsDataURL(imageObj);
    }
  }, [imageObj]);

  const handleChange = (info) => {
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return false;
  };

  const handleShowPreview = (file) => {
    if (file?.originFileObj instanceof Blob) {
      const url = URL.createObjectURL(file.originFileObj);
      setPreviewImage(url); // Set the image URL for preview
      setPreviewVisible(true); // Open the modal for preview
    } else {
      console.error("Invalid file for preview.");
      message.error("Cannot preview this file.");
    }
  };

  // Close preview modal
  const handlePreviewCancel = () => setPreviewVisible(false);

  const handleNewOk = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        sendTo: values.sendTo,
        title: values.title,
        description: values.description,
        image: imageObj,
      };

      if (addNewAnnouncement) {
        // Ensure the mutation function is a valid promise
        const response = await addNewAnnouncement(payload).unwrap();
        message.success("New Announcement is created successfully.");
        setIsAddNewModal(false);
        form.resetFields();
      } else {
        throw new Error("addNewAnnouncement is not available.");
      }
    } catch (error) {
      message.error("Failed to create new announcement.");
      console.error("Error creating announcement:", error);
    }
  };

  const handleNewCancel = () => {
    setIsAddNewModal(false);
  };

  const handlePreview = (file) => {
    if (file?.originFileObj instanceof Blob) {
      const url = URL.createObjectURL(file.originFileObj);
      const img = new Image();
      img.src = url;
      const imgWindow = window.open(url);
      imgWindow?.document.write(img.outerHTML);
    } else {
      console.error("Invalid file for preview.");
      message.error("Cannot preview this file.");
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 25,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "50%",
      render: (text) => (
        <div
          dangerouslySetInnerHTML={{ __html: text }} // Render HTML safely
        />
      ),
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span>
                      <EyeOutlined /> View
                    </span>
                  ),
                  onClick: () => showViewDetailModal(record),
                },
                {
                  key: "3",
                  label: (
                    <span>
                      <DeleteOutlined /> Delete
                    </span>
                  ),
                  onClick: () => showDeleteModal(record),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = announcementListData?.data?.announcements?.map(
    (announcement, index) => ({
      key: announcement._id,
      date: moment(announcement.createdAt).format("DD/MM/YYYY"),
      title: announcement.title,
      description: announcement.description,
      sendTo:
        announcement.sendTo === 0
          ? "All"
          : announcement.sendTo === 1
          ? "Owner"
          : "Lease",
      photo: announcement.imageUrl, // Display the image URL
    })
  );

  return (
    <>
      <div className="page-new-add">
        <div>
          <h3 className="page-title">Announcement List</h3>
          <p className="total-number">
            Total Announcements :{" "}
            <div className="total-no-display">
              {announcementListData?.data?.totalAnnouncements}
            </div>
          </p>
        </div>
        <div className="property-search">
          <Form.Item>
            <Button className="property-add-btn" onClick={showAddNewModal}>
              <AddIcon />
              New Announcement
            </Button>
          </Form.Item>
        </div>
      </div>
      <Table
        loading={isLoading}
        style={{ marginTop: "15px" }}
        columns={columns}
        dataSource={tableData}
      />
      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isDeleteModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button
              className="delete-btn"
              key="back"
              onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleDeleteOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
      <Modal
        title="View Details"
        open={isViewDetailModal}
        onCancel={handleViewDetailCancel}
        closeIcon={null}
        footer={null}
        width={780}>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
          <Form form={form} layout="vertical">
            <Form.Item name="sendTo" label="Send to">
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>

            <Form.Item name="title" label="Title">
              <Input style={{ height: 44 }} readOnly />
            </Form.Item>

            <Form.Item label="Description">
              <div
                style={{
                  padding: "10px",
                  background: "#f0f0f0",
                  borderRadius: "8px",
                }}
                dangerouslySetInnerHTML={{
                  __html: form.getFieldValue("description"),
                }}
              />
            </Form.Item>
            {currentRecord?.photo && (
              <Form.Item label="Photo">
                <img
                  src={currentRecord.photo}
                  alt="Announcement"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </Form.Item>
            )}
          </Form>
        </ConfigProvider>
      </Modal>

      <Modal
        title="Add New Announcement"
        open={isAddNewModal}
        onCancel={handleNewCancel}
        okText="Confirm"
        width={880}
        footer={[
          <>
            <Button
              type="text"
              className="btn"
              key="back"
              onClick={handleNewCancel}>
              Cancel
            </Button>
            <Button className="btn filter" key="submit" onClick={handleNewOk}>
              Update
            </Button>
          </>,
        ]}>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
          <Form form={form} layout="vertical">
            <Form.Item
              name="sendTo"
              label="Send to"
              rules={[
                { required: true, message: "Please select a recipient." },
              ]}>
              <Select placeholder="Select recipient" style={{ height: 44 }}>
                <Select.Option value={0}>All</Select.Option>
                <Select.Option value={1}>Owner</Select.Option>
                <Select.Option value={2}>Lease</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please enter a title." }]}>
              <Input placeholder="Title" style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please enter a description." },
              ]}>
              <ReactQuill
                theme="snow"
                placeholder="Write a description here..."
              />
            </Form.Item>
            <Form.Item
              name="photo"
              label="Upload"
              rules={[{ required: true, message: "Please upload an image." }]}>
              <Dragger
                onChange={handleChange}
                beforeUpload={beforeUpload}
                // previewFile={handlePreview}
                listType="text"
                accept=".png,.jpg,image/png,image/jpeg"
                multiple={true}
                maxCount={3}
                onPreview={handlePreview}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single image upload. Strictly prohibited from
                  uploading company data or other files.
                </p>
              </Dragger>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </Modal>
    </>
  );
};

export default AnnouncementList;
