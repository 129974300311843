import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialToken = Cookies.get("token")  ? Cookies.get("token") : null;
const initialToken2 = Cookies.get("token2") ? Cookies.get("token2") : null;

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    email: null,
    token: initialToken,
    token2: initialToken2,
    name: null,
    branches: [],
    selectedBranch: null,
    permissions: [],
  },
  reducers: {
    setCredentials: (state, action) => {
      const { email, accessToken, name, branches, selectedBranch } =
        action.payload;
      state.email = email;
      state.token = accessToken;
      state.name = name;
      state.branches = branches;
      state.selectedBranch = selectedBranch;
      Cookies.set("token", accessToken, { expires: 1 }); // Save token in cookies
    },

    logoutAccount: (state) => {
      state.email = null;
      state.token = null;
      state.token2 = null;
      state.name = null;
      state.branches = [];
      state.selectedBranch = null;
      Cookies.remove("token");
      Cookies.remove("token2");
    },

    setToken2: (state, action) => {
      const token2 = action.payload;
      state.token2 = token2;
      Cookies.set("token2", token2, { expires: 1 }); // Save token2 in cookies
    },

    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload;
    },

    tokenExpired: (state) => {
      state.email = null;
      state.token = null;
      state.token2 = null;
      state.name = null;
      state.branches = [];
      state.selectedBranch = null;
      Cookies.remove("token");
      Cookies.remove("token2");
    },
  },
});

export const {
  setCredentials,
  logoutAccount,
  setToken2,
  setSelectedBranch,
  tokenExpired,
} = authSlice.actions;
export default authSlice.reducer;
