import { baseApi } from "../../../../app/baseApi";

export const contractApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBranches: builder.query({
      query: () => `/Setting/branch/list`,
      providesTags: ["BranchList"],
    }),

    addNewBranch: builder.mutation({
      query: ({ name, contact_person, email, phone_number, city, township, address }) => ({
        url: `/Setting/branch/create`,
        method: "POST",
        body: {
          name,
          contact_person,
          email,
          phone_number,
          city,
          township,
          address
        },
      }),
      invalidatesTags: ["ContractList"],
    }),

    // updateContract: builder.mutation({
    //   query: ({ contractId, updatedData }) => ({
    //     url: `/Setting/contracts/update/${contractId}`,
    //     method: "PATCH",
    //     body: updatedData,
    //   }),
    //   onError: (error) => {
    //     console.error("Edit contract failed:", error);
    //   },
    //   invalidatesTags: ["ContractList"],
    // }),

    // deleteContract: builder.mutation({
    //   query: ({ contractId }) => ({
    //     url: `/Setting/contracts/delete/${contractId}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["ContractList"],
    // }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBranchesQuery, useAddNewBranchMutation
} = contractApi;
