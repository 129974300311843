import React, { useState } from "react";
import { Button, Card, Select, Table, Tag, Modal, Space, Dropdown } from "antd";
import "./styles.css";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import FilterLogo from "../../../assets/icons/Vector.svg"

const Invoices = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: () => {
        showModal();
      },
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags?.map((tag) => {
            let color;
            switch (tag) {
              case "Paid":
                color = "green";
                break;
              case "Unpaid":
                color = "orange";
                break;
              case "Due":
                color = "red";
                break;
              default:
                color = "green";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Transcation Type",
      dataIndex: "transcation",
      key: "transcation",
    },
    {
      title: "Payment Type",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Amount(MMK)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Remaining Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: () => (
        <Space size="middle">
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      date: "11-11-2024",
      invoiceNo: "#0001",
      tags: ["Paid"],
      transcation: "Partially",
      payment: "Kpay",
      amount: "250000 MMK",
      remainingAmount: "25000 MMK",
      children: [
        {
          key: "2",
          date: "11-11-2024",
          invoiceNo: "#0001",
          tags: ["Paid"],
          transcation: "Partially",
          payment: "Kpay",
          amount: "250000 MMK",
          remainingAmount: "25000 MMK",
        },
        {
          key: "3",
          date: "11-11-2024",
          invoiceNo: "#0001",
          tags: ["Paid"],
          transcation: "Partially",
          payment: "Kpay",
          amount: "250000 MMK",
          remainingAmount: "25000 MMK",
        },
      ],
    },
    {
      key: "4",
      date: "11-11-2024",
      invoiceNo: "#0001",
      tags: ["Unpaid"],
      transcation: "Partially",
      payment: "Kpay",
      amount: "250000 MMK",
      remainingAmount: "25000 MMK",
    },
    {
      key: "5",
      date: "11-11-2024",
      invoiceNo: "#0001",
      tags: ["Due"],
      transcation: "Partially",
      payment: "Kpay",
      amount: "250000 MMK",
      remainingAmount: "25000 MMK",
    },
  ];

  return (
    <>
      <div className="filter-card">
        <Card title="Filter by :">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="filter-card-info">
                <Select
                  placeholder="Outlet Name"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}
                  // onChange={(value) => setStatusFilter(value)} // Update status filter state
                ></Select>
                <Select
                  placeholder="Invoice Type"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}></Select>
                <Select
                  placeholder="Invoice Status"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}></Select>
                <Select
                  placeholder="Transaction Type"
                  // value={statusFilter}
                  style={{
                    // width: 150,
                    height: 38,
                  }}></Select>
                <Select
                  placeholder="Payment Type"
                  style={{
                    width: 150,
                    height: 38,
                  }}></Select>
              </div>
              <div className="filter-btn">
                <Button
                  className="filter-gp-btn"
                  // onClick={resetFilters}
                >
                  Clear
                </Button>
                <Button className="filter-gp-btn filter">
                  <img src={FilterLogo} alt="fiter logo" />
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Table columns={columns} dataSource={dataSource}></Table>
      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button className="delete-btn" key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
    </>
  );
};

export default Invoices;
