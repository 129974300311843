import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Card, Select, Input, Spin } from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetSubPropertyBySubPropertyIdQuery } from "../../../features/tenant/subTenantApi";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../../features/tenant/tenantApi";

const { Option } = Select;

const TabPropertyDetail = ({ onBack, propertyId }) => {
  const navigate = useNavigate();

  const {
    data: subPropertyData,
    isLoading: isPropertyLoading,
    error,
  } = useGetSubPropertyBySubPropertyIdQuery({ subPropertyId: propertyId });
  console.log("Sub property data", subPropertyData);

  console.log("sub property data", subPropertyData);
  

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  useEffect(() => {
      if (subPropertyData?.data?.billing_contact_person?.city) {
        setSelectedCity(subPropertyData?.data?.billing_contact_person?.city);
      }
    }, [subPropertyData]);

  const initialValues = subPropertyData
    ? {
        outlet_name: subPropertyData?.data?.outlet_name || "",
        tenant_name: subPropertyData?.data?.tenant?.name || "",
        shop: subPropertyData?.data?.shop?.name || "",
      }
    : {};

  // If data is loading, display a loading spinner
  if (isPropertyLoading || isCityListLoading || isTownshipListLoading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  // Handle error state
  if (error) {
    return <div>Error loading property details</div>;
  }

  return (
    <>
      <Form layout="vertical" initialValues={initialValues}>
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon onClick={onBack} style={{ cursor: "pointer" }} />
              <span style={{ marginLeft: "8px" }}>Shop Information</span>
            </div>
          }
        >
          <div className="add-new-tenant">
            <Form.Item
              className="tenant-label-group"
              name="outlet_name"
              label="Outlet Name"
            >
              <Input readOnly style={{ height: 44 }} placeholder="Outlet Name" />
            </Form.Item>
          </div>
        </Card>

        <Card title="Property Information" style={{ marginTop: "15px" }}>
          <div className="add-new-tenant">
            <Form.Item
              className="tenant-label-group"
              name="tenant_name"
              label="Tenant Name">
             <Input style={{height: 44}}/>
            </Form.Item>

            <Form.Item
              name="shop"
              className="tenant-label-group"
              label="Shop Name">
              {/* <Select style={{ height: 44 }} placeholder="Select Shop" /> */}
              <Input style={{height: 44}}/>
            </Form.Item>

            <Form.Item
              name="code"
              className="tenant-label-group"
              label="Shop Code">
              <Input readOnly style={{ height: 44 }} placeholder="Code" />
            </Form.Item>

            <Form.Item
              name="zone"
              className="tenant-label-group"
              label="Zone / Role">
              <Input readOnly style={{ height: 44 }} placeholder="Zone / Role" />
            </Form.Item>

            <Form.Item
              name="floor"
              className="tenant-label-group"
              label="Floor">
              <Input readOnly style={{ height: 44 }} placeholder="Floor" />
            </Form.Item>

            <Form.Item
              name="building"
              className="tenant-label-group"
              label="Building">
              <Input readOnly style={{ height: 44 }} placeholder="Building" />
            </Form.Item>

            <Form.Item
              name="area"
              className="tenant-label-group"
              label="Total Area (Sq ft)">
              <Input readOnly style={{ height: 44 }} placeholder="Total Area" />
            </Form.Item>

            <Form.Item
              name="price"
              className="tenant-label-group"
              label="Price (MMK)">
              <Input readOnly style={{ height: 44 }} placeholder="Price" />
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};

export default TabPropertyDetail;
