import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Form, Select, Input, Button, Breadcrumb } from "antd";
import styles from "./styles.css";

const AddNewProperty = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/list`)}>
          Property List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create New Property</Breadcrumb.Item>
      </Breadcrumb>

      <Form layout="vertical">
        <Card title="Create New Property">
          <div className="view-detail-form">
            <Form.Item label="Property Type" className="input-group">
              <Select
                placeholder="Select Property Type"
                style={{
                  height: 44,
                }}></Select>
            </Form.Item>

            <Form.Item label="Building" className="input-group">
              <Select
                placeholder="Select Building"
                style={{
                  height: 44,
                }}></Select>
            </Form.Item>

            <Form.Item label="Floor" className="input-group">
              <Select
                placeholder="Select Floor"
                style={{
                  height: 44,
                }}></Select>
            </Form.Item>

            <Form.Item label="Zone / Role" className="input-group">
              <Select
                placeholder="Select Zone / Floor"
                style={{
                  height: 44,
                }}></Select>
            </Form.Item>

            <Form.Item label="Shop Name" className="input-group">
              <Select
                placeholder="Shop Name"
                style={{
                  height: 44,
                }}></Select>
            </Form.Item>

            <Form.Item label="Shop Code" className="input-group">
              <Input
                style={{
                  height: 44,
                }}
                placeholder="Shop Code"
              />
            </Form.Item>

            <Form.Item label="Total Area (sq ft)" className="input-group">
              <Input
                style={{
                  height: 44,
                }}
                placeholder="Total Area"
              />
            </Form.Item>

            <Form.Item label="Price (MMK)" className="input-group">
              <Input
                style={{
                  height: 44,
                }}
                placeholder="Price (MMK)"
              />
            </Form.Item>
          </div>
          <Form.Item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}>
            <Link to={`/${id}/property/list`}>
              <Button className="btn">Cancel</Button>
            </Link>
            <Button className="btn update">Create</Button>
          </Form.Item>
        </Card>
      </Form>
    </>
  );
};

export default AddNewProperty;
