import { Card, Form, Input, Button, Modal, Divider } from "antd";
import React, { useState } from "react";
import { AddNewBuildingForm, BuildingList } from "../../features";
import AddIcon from "@mui/icons-material/Add";

const { Search } = Input;

const AddBuilding = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="page-new-add">
        <h3 className="page-title">Building</h3>
        <div className="property-search">
          <Form.Item>
            <Button className="property-add-btn" onClick={showModal}>
              <AddIcon />
              Add New Building
            </Button>
          </Form.Item>
        </div>
      </div>

      <div className="search-picker-combo">
        <Search
          placeholder="Building"
          allowClear
          enterButton="Search"
          onSearch={handleSearch}
          size="large"
          style={{ width: 456, marginBottom:"15px" }}
        />
      </div>

          <Form>
            <Form.Item>
              <BuildingList searchTerm={searchTerm} />
            </Form.Item>
          </Form>

      {/* Modal for Adding New Building */}
      <Modal
        width={880}
        title="Add New Building"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <AddNewBuildingForm  handleOk={handleOk} handleCancel={handleCancel}  />
      </Modal>
    </>
  );
};

export default AddBuilding;
