import { Form, Input, Button, Select, message, ConfigProvider } from "antd";
import React, { useEffect, useState } from "react";
import {
  useAddNewZoneMutation,
  useGetAllBuildingQuery,
  useGetFloorsByBuildingIdQuery,
} from "./propertyApi";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddNewZone = ({ handleOk, handleCancel }) => {
  const token = useSelector((state) => state.authSlice.token2);

  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetAllBuildingQuery(token);

  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding, // Skip query if no building is selected
    });

  const [addNewZone, { isLoading, error }] = useAddNewZoneMutation(token);

  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const payload = {
      name: values.zone,
      building: values.building,
      floor: values.floor,
    };
    try {
      await addNewZone(payload).unwrap();
      form.resetFields();
      message.success("Zone is added successfully.");
      handleOk();
    } catch (error) {
      const errorMessage = error?.data?.errors?.[0] || "Failed to add floor";
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ floor: undefined });
  }, [selectedBuilding, form]);

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields when Cancel is clicked
    handleCancel(); // Call parent cancel handler
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flexWrap: "wrap",
            }}>
            <Form.Item label="Building" name="building">
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a building"
                loading={isLoadingBuildings}
                onChange={(value) => setSelectedBuilding(value)}>
                {buildingListData?.data?.buildings?.map((building) => (
                  <Option key={building._id} value={building._id}>
                    {building.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Floor Name" name="floor">
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a floor"
                loading={isLoadingFloors}>
                {floorListData?.data?.map((floor) => (
                  <Option key={floor._id} value={floor._id}>
                    {floor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Zone / Role" name="zone" style={{ width: 400 }}>
              <Input style={{ height: 44 }} placeholder="Zone / Role" />
            </Form.Item>
          </div>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="text" onClick={handleFormCancel} className="btn">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="btn filter"
              loading={isLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </>
  );
};

export default AddNewZone;
