import {
  Button,
  Card,
  Form,
  InputNumber,
  message,
  Select,
  Space,
  Input,
  ConfigProvider
} from "antd";
import React, { useEffect } from "react";
import styles from "./styles.css";
import { useSelector } from "react-redux";
import {
  useGetAllInvoiceSettingQuery,
  useUpdateInvoiceSettingMutation,
} from "../../features/setting/invoiceSettingApi";

const { Option } = Select;

const penaltyType = {
  percent: "percent",
  flat: "flat",
};

const InvoiceSetting = () => {
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: invoiceSettingList,
    isLoading,
    error,
  } = useGetAllInvoiceSettingQuery(token);

  const [updateInvoiceSetting, { isLoading: isUpdateLoading }] =
    useUpdateInvoiceSettingMutation();

  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const invoiceId = invoiceSettingList?.data[0]?._id;
      console.log("invoice id", invoiceId);
      const payload = {
        invoiceId: invoiceId,
        updatedData: {
          invoice_validity: values.invoice_validity,
          due_validity: values.due_validity,
          penalty_type: values.penaltyType,
          penalty_fee: values.penalty_fee,
        },
      };
      console.log("payload", payload);
      await updateInvoiceSetting(payload).unwrap();
      message.success("Invoice Setting is updated successfully.");
    } catch (err) {
      console.error("Failed to update Invoice Setting", err);
      message.error("Failed to update Invoice Setting.");
    }
  };

  useEffect(() => {
    if (invoiceSettingList) {
      const invoice_validity = invoiceSettingList?.data[0]?.invoice_validity;
      const due_validity = invoiceSettingList?.data[0]?.due_validity;
      const penalty_fee = invoiceSettingList?.data[0]?.penalty_fee;
      const penaltyType = invoiceSettingList?.data[0]?.penalty_type;
      form.setFieldsValue({ invoice_validity });
      form.setFieldsValue({ due_validity });
      form.setFieldsValue({ penalty_fee });
      form.setFieldsValue({ penaltyType });
    }
  }, [invoiceSettingList, form]);

  const selectAfter = (
    <Form.Item name="penaltyType" noStyle>
      <Select style={{ width: 100, height: 44 }}>
        <Option value={penaltyType.percent}>percent</Option>
        <Option value={penaltyType.flat}>flat</Option>
      </Select>
    </Form.Item>
  );
  return (
    <ConfigProvider
      theme={{
        token: {
          controlHeight: 44,
        },
      }}>
      <Card title="Invoice Setting">
        <Form onFinish={handleSubmit} form={form} layout="vertical">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "space-between",
            }}>
            <Form.Item
              className="invoice-setting"
              name="invoice_validity"
              label="Invoice Validity (Days)">
              <InputNumber min={1} placeholder="Days" style={{ width: 330 }} />
            </Form.Item>
            <Form.Item
              className="invoice-setting"
              name="due_validity"
              label="Due Validity (Days)">
              <InputNumber min={1} placeholder="Days" style={{ width: 330 }} />
            </Form.Item>
            <Form.Item
              name="penalty_fee"
              label="Penalty Fee"
              className="invoice-setting"
              // style={{height: 44}}
              rules={[
                { required: true, message: "Please enter penalty fee!" },
              ]}>
              <InputNumber
                // style={{ width: 330, height: 44 }}
                className="rate card-item"
                placeholder="Penalty Fee"
                addonAfter={selectAfter}
              />
            </Form.Item>
          </div>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button className="btn">Cancel</Button>
            <Button type="primary" htmlType="submit" className="btn filter">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </ConfigProvider>
  );
};

export default InvoiceSetting;
