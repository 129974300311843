import React, { useState } from "react";
import {
  Profile,
  ResetPassword,
  TabProperty,
  TabPropertyDetail,
} from "../../index";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Tabs, Breadcrumb } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditSubProperty from "./EditProperty";

const SubTenantDetails = () => {
  const [tab, setTab] = useState("1");
  const [propertyView, setPropertyView] = useState("list");
  const [propertyId, setPropertyId] = useState('');
  const location = useLocation();
  const subTenantId = location.state?.subTenantId;
  const { id } = useParams();

  const navigate = useNavigate();

  const handleViewDetail = (id) => {
    setPropertyView("detail");
    setPropertyId(id); 
  };

  const handleEditDetail = ({propertyId }) => {
    setPropertyId(propertyId);
    setPropertyView("edit");
  }

  const items = [
    {
      key: "1",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PermIdentityOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Profile</p>
        </div>
      ),
      children: <Profile subTenantId={subTenantId} />,
    },
    {
      key: "2",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LayersOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Property</p>
        </div>
      ),
      children:
        propertyView === "list" ? (
          <TabProperty onEditDetail={handleEditDetail} onViewDetail={handleViewDetail} /> // Pass the handler to Property component
        ) : propertyView === "edit" ? (
          <EditSubProperty 
            propertyId={propertyId} 
            onBack={() => setPropertyView("list")} 
          />
        ) : (
          <TabPropertyDetail
            onBack={() => setPropertyView("list")}
            propertyId={propertyId}
          /> // Render PropertyDetail with type and back handler
        ),
    },
    {
      key: "3",
      label: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LockOutlinedIcon style={{ margin: "auto" }} />
          &nbsp;<p>Reset Password</p>
        </div>
      ),
      children: <ResetPassword subTenantId={subTenantId} />,
    },
  ];

  return (
    <>
    <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
    <Breadcrumb.Item onClick={() => navigate(`/${id}/tenant/sub-list`)}>
      Sub Tenant List
    </Breadcrumb.Item>
    <Breadcrumb.Item>Sub Tenant Details</Breadcrumb.Item>
  </Breadcrumb>
      <Tabs
        onChange={(key) => {
          setTab(key);
          setPropertyView("list");
        }}
        activeKey={tab}
        items={items}
      />
    </>
  );
};

export default SubTenantDetails;
