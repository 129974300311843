import React from "react";
import { Modal, Button } from "antd";

const DeleteModal = ({
  visible,
  onConfirm,
  onCancel,
  title = "ARE YOU SURE?",
  confirmText = "Delete",
  cancelText = "Cancel",
  message = "Do you want to delete this permanently?",
}) => {
  return (
    <Modal
      width={360}
      style={{ textAlign: "center" }}
      title={title}
      open={visible}
      okText={confirmText}
      okType="danger"
      closable={false}
      centered
      footer={[
        <div className="delete-modal-btn" key="footer">
          <Button className="delete-btn" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button className="delete-btn filter" onClick={onConfirm}>
            {confirmText}
          </Button>
        </div>,
      ]}>
      <p>{message}</p>
    </Modal>
  );
};

export default DeleteModal;
