import { Breadcrumb, Card, Col, Row, Table, Tag } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import {
  useGetInvoiceByIdQuery,
} from "../../features/invoice/invoiceApi";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const { id, invoice_id } = useParams();
  const { data } = useGetInvoiceByIdQuery({ invoice_id });
  
  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "_id",
      key: "_id",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const dataSource = [
    {
      key: "1",
      no: "1",
      description: "ဆိုင်ခန်းငှားရမ်းခ",
      unit: data?.data[0].rental_fee.unit,
      qty: data?.data[0].rental_fee.qty,
      rate: data?.data[0].rental_fee.rate,
      amount: formatPrice(data?.data[0].rental_fee.amount),
    },
    {
      key: "2",
      no: "2",
      description: "ကုန်သွယ်လုပ်ငန်းခွန်",
      unit: data?.data[0].commercial_tax.unit,
      qty: data?.data[0].commercial_tax.qty,
      rate: `${data?.data[0].commercial_tax.rate} %`,
      amount: formatPrice(data?.data[0].commercial_tax.amount),
    },
    {
      key: "3",
      no: "3",
      description: "ကြော်ငြာစရိတ် (ဆိုင်ခန်းငှားခ 2%)",
      unit: data?.data[0].advertising_fee.unit,
      qty: data?.data[0].advertising_fee.qty,
      rate: `${data?.data[0].advertising_fee.rate} %`,
      amount: formatPrice(data?.data[0].advertising_fee.amount),
    },
    {
      key: "4",
      no: "4",
      description: "သန့်ရှင်းရေးနှင့်လုံခြုံရေးစရိတ်",
      unit: data?.data[0].cleaning_security_fee.unit,
      qty: data?.data[0].cleaning_security_fee.qty,
      rate: `${data?.data[0].cleaning_security_fee.rate} %`,
      amount: formatPrice(data?.data[0].cleaning_security_fee.amount),
    },
    {
      key: "5",
      no: "5",
      description: "Air Conditioning & Electricity",
      unit: data?.data[0].public_area_meter.unit,
      qty: data?.data[0].public_area_meter.qty,
      rate: `${data?.data[0].public_area_meter.rate}`,
      amount: formatPrice(data?.data[0].public_area_meter.amount),
    },
    {
      key: "6",
      no: "6",
      description: "Electricity",
      children: [
        {
          key: 9,
          description: "YESB",
          name: "Meter No.",
          unit: "unit",
          qty: data?.data[0].yesb_meter.usage,
          rate: "-",
          amount: formatPrice(data?.data[0].yesb_meter.amount),
          children: [
            {
              key: 10,
              name: "Previous Month",
              unit: data?.data[0].yesb_meter.last_month,
            },
            {
              key: 11,
              name: "This Month",
              unit: data?.data[0].yesb_meter.this_month,
            },
          ],
        },
        {
          key: 15,
          description: "Generator",
          name: "Meter No.",
          unit: "unit",
          qty: data?.data[0].generator_meter.usage,
          rate: "-",
          amount: formatPrice(data?.data[0].generator_meter.amount),
          children: [
            {
              key: 10,
              name: "Previous Month",
              unit: data?.data[0].generator_meter.last_month,
            },
            {
              key: 11,
              name: "This Month",
              unit: data?.data[0].generator_meter.this_month,
            },
          ],
        },
        {
          key: 16,
          description: "Gas Line",
          name: "Meter No.",
          unit: "unit",
          qty: data?.data[0].gas_line_meter.usage,
          rate: "-",
          amount: formatPrice(data?.data[0].gas_line_meter.amount),
          children: [
            {
              key: 10,
              name: "Previous Month",
              unit: data?.data[0].gas_line_meter.last_month,
            },
            {
              key: 11,
              name: "This Month",
              unit: data?.data[0].gas_line_meter.this_month,
            },
          ],
        },
      ],
    },
    {
      key: "7",
      no: "7",
      description: "Other Fees",
      name: "Water",
      unit: "Sq ft",
      qty: data?.data[0].water_cost_meter.usage,
      rate: "-",
      amount: formatPrice(data?.data[0].water_cost_meter.amount),
      children: [
        {
          key: 10,
          name: "Previous Month",
          unit: data?.data[0].water_cost_meter.last_month,
        },
        {
          key: 11,
          name: "This Month",
          unit: data?.data[0].water_cost_meter.this_month,
        },
      ],
    },
    {
      key: "8",
      no: "8",
      description: "Pest & Mosquito Control",
      unit: data?.data[0].poest_and_mosquito_control.unit,
      qty: data?.data[0].poest_and_mosquito_control.qty,
      rate: data?.data[0].poest_and_mosquito_control.rate,
      amount: formatPrice(data?.data[0].poest_and_mosquito_control.amount),
    },
  ];

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/invoice/list`)}>
          Invoice List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Invoice Details</Breadcrumb.Item>
      </Breadcrumb>
      <Card title="Invoice Details" style={{ width: "100%", padding: "20px" }}>
        <Row gutter={[16]}>
          <Col span={4}>
            <p>
              <strong>Tenant Name</strong>
            </p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={16}>
            <p>{data?.data[0]?.tenant_name}</p>
          </Col>

          <Col span={4}>
            <p>
              <strong>Outlet Name</strong>
            </p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={16}>
            <p>{data?.data[0]?.outlet_name}</p>
          </Col>

          <Col span={4}>
            <p>
              <strong>Invoice Status</strong>
            </p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={16}>
            <p>{data?.data[0]?.status === 1 ?<Tag color="red">Unpaid</Tag>: <Tag color="green">Paid</Tag>}</p>
          </Col>
        </Row>
        <Table
          className="custom-table"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          summary={(pageData) => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>SUB TOTAL</Table.Summary.Cell>
                  <Table.Summary.Cell>
                  {formatPrice(data?.data[0].sub_total)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>PENALTY</Table.Summary.Cell>
                  <Table.Summary.Cell>{formatPrice(0)}</Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>DISCOUNT</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {formatPrice(data?.data[0].descount)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={6}>
                    GRAND TOTAL
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {formatPrice(data?.data[0].grand_total)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
    </>
  );
};

export default InvoiceDetails;
