import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Button,
  message,
  Spin
} from "antd";
import styles from "../styles.css";
import {
  useGetAllCitiesListQuery,
  useGetAllTenantQuery,
  useGetTownshipByCityIdQuery,
} from "../../../features/tenant/tenantApi";
import {
  useAddNewSubTenantPropertyMutation,
  useGetShopByTenantIdQuery,
  useGetSubPropertyBySubPropertyIdQuery,
  useUpdateSubPropertyMutation,
} from "../../../features/tenant/subTenantApi";

const { Option } = Select;

const EditSubProperty = ({ onBack, propertyId }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const subTenantId = location.state?.subTenantId;

  const { data: tenantListData, isLoading: isTenantLoading } =
    useGetAllTenantQuery();
  const [selectedTenant, setSelectedTenant] = useState();
  const { data: shopListDataByTenant, isLoaidng: isShopLoading } =
    useGetShopByTenantIdQuery(
      { tenantId: selectedTenant },
      { skip: !selectedTenant }
    );
    
  const [updateSubProperty, {isLoading: isUpdateLoading}] = useUpdateSubPropertyMutation();

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );
    
  const { data: propertyDetail, isLoading: isPropertyLoading, error } = useGetSubPropertyBySubPropertyIdQuery({subPropertyId: propertyId});
  
  useEffect(() => {
    if (propertyDetail?.data?.tenant?._id) {
      setSelectedTenant(propertyDetail?.data?.tenant?._id);
    }
    if (propertyDetail?.data?.billing_contact_person?.city ) {
      setSelectedCity(propertyDetail?.data?.billing_contact_person?.city );
    }
  }, [propertyDetail]);


  const initialValues = propertyDetail
  ? {
    tenant: propertyDetail?.data?.tenant?._id,
    shop: propertyDetail?.data?.shop?._id,
    outlet_name: propertyDetail?.data?.outlet_name,
    billingName: propertyDetail?.data?.billing_contact_person?.name || "",
    billingPhoneNumber: propertyDetail?.data?.billing_contact_person?.phoneNumber  || "",
    billingEmail: propertyDetail?.data?.billing_contact_person?.email || "",
    billingCity: propertyDetail?.data?.billing_contact_person?.city  || "",
    billingTownship: propertyDetail?.data?.billing_contact_person?.township || "",
    billingAddress: propertyDetail?.data?.billing_contact_person?.address  || "",
    }
  : {};
        
  const onFinish = async(values) => {
    try {
      const payload = {
        tenant: selectedTenant,
        shop: values.shop,
        outlet_name: values.outlet_name,
        billing_contact_person: {
          name: values.billingName || "",
          phoneNumber: values.billingPhoneNumber || "",
          email: values.billingEmail || "",
          city: values.billingCity || "",
          township: values.billingTownship || "",
          address: values.billingAddress || "",
        },
      };
      console.log("Payload", payload);
      await updateSubProperty({data: payload, subPropertyId: propertyId}).unwrap();
      message.success("Property is updated successfully.");
      onBack();
    } catch (error) {
      message.error(`Not available to update this property.`);
    }
  };

  const [selectedOutletName, setSelectedOutletName] = useState("");

  const handleShopChange = (shopId) => {
    const selectedShop = shopListDataByTenant?.data?.find(
      (shopItem) => shopItem.shop._id === shopId
    );
    setSelectedOutletName(selectedShop?.outlet_name || "");
    form.setFieldsValue({
      outlet_name: selectedShop?.outlet_name || "",
    });
  };

  return (
    <>
    {isPropertyLoading ? (
        <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        />
      ) : (
      <Form initialValues={initialValues} form={form} onFinish={onFinish} layout="vertical">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon
                onClick={onBack}
                style={{ cursor: "pointer" }}
              />
              <span style={{ marginLeft: "8px" }}>Edit Property</span>
            </div>
          }>
          <div className="add-new-tenant">
            <Form.Item
              className="tenant-label-group"
              name="tenant"
              label="Tenant Name">
              <Select
                style={{ height: 44 }}
                placeholder="Select tenant name"
                onChange={(value) => setSelectedTenant(value)}
                loading={isTenantLoading}>
                {tenantListData?.data?.map((tenant) => (
                  <Option key={tenant._id} value={tenant._id}>
                    {tenant?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="shop" className="tenant-label-group" label="Shop">
              <Select
                style={{ height: 44 }}
                onChange={handleShopChange}
                placeholder="Select shop"
                loading={isShopLoading}>
                {shopListDataByTenant?.data?.map((shop) => (
                  <Option key={shop.shop._id} value={shop.shop._id}>
                    {shop?.shop?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="outlet_name"
              className="tenant-label-group"
              label="Outlet Name">
              <Input
                style={{ height: 44 }}
                placeholder="Outlet Name"
                value={selectedOutletName}
                readOnly
              />
            </Form.Item>
          </div>
        </Card>

        <Card style={{marginTop: '20px'}} title="Billing Contact Person">
          <div className="add-new-tenant">
            <Form.Item
              className="tenant-label-group"
              name="billingName"
              label="Name">
              <Input style={{ height: 44 }} placeholder="Name" />
            </Form.Item>

            <Form.Item
              className="tenant-label-group"
              name="billingPhoneNumber"
              label="Phone Number">
              <Input style={{ height: 44 }} placeholder="Phone Number" />
            </Form.Item>

            <Form.Item
              className="tenant-label-group"
              name="billingEmail"
              label="Email">
              <Input style={{ height: 44 }} placeholder="Email" />
            </Form.Item>

            <Form.Item
              className="tenant-label-group"
              name="billingCity"
              label="City">
              <Select
                style={{ height: 44 }}
                placeholder="Select city name"
                onChange={(value) => setSelectedCity(value)}
                loading={isCityListLoading}>
                {cityList?.data?.map((city) => (
                  <Option key={city._id} value={city._id}>
                    {city?.city_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className="tenant-label-group"
              name="billingTownship"
              label="Township">
              <Select
                style={{ height: 44 }}
                placeholder="Select a township"
                loading={isTownshipListLoading}>
                {townshipList?.data?.map((township) => (
                  <Option key={township._id} value={township._id}>
                    {township?.township_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="billingAddress"
              label="Address"
              style={{ width: 705 }}>
              <Input style={{ height: 44 }} placeholder="Address" />
            </Form.Item>
          </div>
        </Card>

        <Form.Item>
          <div className="add-tenant-btn-group">
            <Button className="btn">Cancel</Button>
            <Button
              type="text"
              htmlType="submit"
              loading={isUpdateLoading}
              disabled={isUpdateLoading}
              className="btn create">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
      )}
    </>
  );
};

export default EditSubProperty;
