import { Button, Card, Form, Input, message } from "antd";
import React from "react";
import { useAddNewPasswordMutation } from "../../../features/tenant/tenantApi";
import { useAddNewSubPasswordMutation } from "../../../features/tenant/subTenantApi";
import GenerateIcon from "../../../assets/icons/refresh.svg";

const ResetPassword = ({ tenantId, subTenantId }) => {
  // Determine which mutation hook to use based on the presence of tenantId or subTenantId
  const [addNewPassword, { isLoading: tenantLoading, error: tenantError }] =
    useAddNewPasswordMutation();
  const [
    addNewSubPassword,
    { isLoading: subTenantLoading, error: subTenantError },
  ] = useAddNewSubPasswordMutation();

  const [form] = Form.useForm();

  // Combine loading and error states based on whether tenantId or subTenantId is present
  const isLoading = tenantId ? tenantLoading : subTenantLoading;
  const error = tenantId ? tenantError : subTenantError;

  const handleSubmit = async (values) => {
    const payload = {
      newPassword: values.newPassword,
      ...(tenantId ? { tenantId } : { subTenantId }), // Dynamically set tenantId or subTenantId
    };

    try {
      const response = tenantId
        ? await addNewPassword(payload).unwrap() // Call tenant mutation
        : await addNewSubPassword(payload).unwrap(); // Call sub-tenant mutation

      form.resetFields();
      message.success(response.message); // Display success message from the response
    } catch (error) {
      const errorMessage = error?.data?.message || "Failed to reset password";
      message.error(errorMessage);
    }
  };

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return password;
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ newPassword: newPassword }); // Update form field with new password
  };

  return (
    <>
      <Card title="Generate Password" style={{ width: "50%" }}>
        <Form onFinish={handleSubmit} form={form} layout="vertical">
          {/* <Form.Item
            name="newPassword"
            label="Password"
            // rules={[{ required: true, message: "Please enter a new password" }]} // Add validation
          >
            <Input.Password style={{ width: 500, height: 44 }} />
          </Form.Item> */}

          <Form.Item
            label="Password"
            name="newPassword"
            style={{ width: 504 }}
            className="tenant-label-group">
            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
              <Form.Item name="newPassword" style={{ flex: 1, margin: 0 }}>
                <Input.Password
                  style={{ height: 44 }}
                  placeholder="Set Password"
                />
              </Form.Item>
              <Button
                style={{ width: 44, height: 44 }}
                onClick={handleGeneratePassword}>
                <img
                  style={{ width: 20, height: 20 }}
                  src={GenerateIcon}
                  alt="Generate"
                />
              </Button>
            </div>
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              htmlType="submit"
              className="btn filter"
              loading={isLoading} // Disable button while loading
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ResetPassword;
