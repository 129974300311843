import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  canNavigateToCentralized: false,
};

const navigationSlice = createSlice({
  name: 'navigationSlice',
  initialState,
  reducers: {
    setCanNavigateToCentralized: (state, action) => {
      state.canNavigateToCentralized = action.payload;
    },
  },
});

export const { setCanNavigateToCentralized } = navigationSlice.actions;
export default navigationSlice.reducer;
