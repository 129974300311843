import React from 'react'
import { BranchList } from '../../../features'

const BranchSetting = () => {
  return (
    <div>
      <BranchList/>
    </div>
  )
}

export default BranchSetting