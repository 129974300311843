import React, { useState } from "react";
import { Button, Card, Select, Table, Tag, Dropdown, Space, Modal } from "antd";
import styles from "./styles.css";
import { DownOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import FilterLogo from "../../../assets/icons/Vector.svg";

const Maintenance = ({ onViewDetail }) => {
  const dataSource = [
    {
      key: "1",
      date: "11-11-2024",
      outletName: "Queen Fashion",
      typeOfIssue: "Electricity",
      tags: ["Finished"],
    },
    {
      key: "2",
      date: "10-09-2024",
      outletName: "Cosmetic",
      typeOfIssue: "Other",
      tags: ["Processing"],
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const viewDetails = (record) => {
    onViewDetail(record);
  };

  const items = (record) => [
    {
      key: "1",
      label: (
        <span>
          <EyeOutlined /> View
        </span>
      ),
      onClick: () => viewDetails(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: () => {
        setSelectedRecord(record);
        showModal();
      },
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Outlet Name",
      dataIndex: "outletName",
      key: "outletName",
    },
    {
      title: "Type of Issue",
      dataIndex: "typeOfIssue",
      key: "typeOfIssue",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags?.map((tag) => {
            let color;
            switch (tag) {
              case "Finished":
                color = "purple";
                break;
              case "Processing":
                color = "orange";
                break;
              default:
                color = "purple";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items(record),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="filter-card">
        <Card title="Filter by :">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="filter-card-info">
                <Select
                  placeholder="Outlet Name"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}
                  // onChange={(value) => setStatusFilter(value)} // Update status filter state
                ></Select>
                <Select
                  placeholder="Type of Issue"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}></Select>
                <Select
                  placeholder="Status"
                  // value={statusFilter}
                  style={{
                    width: 150,
                    height: 38,
                  }}></Select>
              </div>
              <div className="filter-btn">
                <Button
                  className="filter-gp-btn"
                  // onClick={resetFilters}
                >
                  Clear
                </Button>
                <Button className="filter-gp-btn filter">
                  <img src={FilterLogo} alt="fiter logo" />
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <Table columns={columns} dataSource={dataSource} />

      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button className="delete-btn" key="back" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
    </>
  );
};

export default Maintenance;
