import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Card, Form, Input } from "antd";
import "../property/styles.css"; // Ensure this path is correct.
import { useGetAdminByIdQuery } from "../../features/adminstrator/adminApi"; // Adjust import based on your setup

const AdminViewDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [imageUrl, setImageUrl] = useState();
  const [adminData, setAdminData] = useState(null);
  const location = useLocation();
  const adminId = location.state?.adminId;
  const adminName = location.state?.name;

  const { data: profileData, isLoading, error } = useGetAdminByIdQuery({ adminId });
  
  return (
    <>
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowBackIcon
              onClick={() => navigate(`/${id}/adminstrator/list`)}
              style={{ cursor: "pointer" }}
            />
            {adminName}'s Details
          </div>
        }>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error loading data</p>
        ) : (
          <Form layout="vertical">
            <Form.Item>
              {/* <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                disabled>
                {imageUrl ? (
                  <img
                    src={adminData?.profile}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <Avatar size={64} icon={<UserOutlined />} />
                )}
              </Upload> */}
              <img
                src={profileData?.data[0]?.profile}
                alt="avatar"
                style={{ borderRadius: "50%", width: "100px", height: "100px" }}
              />
            </Form.Item>

            <div className="view-detail-form">
              <Form.Item className="input-group" label="Name">
                <Input
                  value={profileData?.data[0]?.name}
                  readOnly
                  style={{ height: 44 }}
                />
              </Form.Item>

              <Form.Item label="Email" className="input-group">
                <Input
                  value={profileData?.data[0]?.email}
                  readOnly
                  style={{ height: 44 }}
                />
              </Form.Item>

              <Form.Item label="Phone Number" className="input-group">
                <Input
                  value={profileData?.data[0]?.phone_number}
                  readOnly
                  style={{ height: 44 }}
                />
              </Form.Item>

              <Form.Item label="Role" className="input-group">
                <Input
                  value={profileData?.data[0]?.role?.name}
                  readOnly
                  style={{ height: 44 }}
                />
              </Form.Item>

              <Form.Item label="Password" className="input-group">
                <Input.Password
                  value="******"
                  readOnly
                  style={{ height: 44 }}
                />
              </Form.Item>

              {/* <Form.Item
                style={{
                  width: "1130px",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "15px",
                }}>
                <Button type="primary" onClick={() => navigate(`/${id}/adminstrator/list`)} className="btn update">
                  Back to List
                </Button>
              </Form.Item> */}
            </div>
          </Form>
        )}
      </Card>
    </>
  );
};

export default AdminViewDetail;
