import {
  Form,
  Input,
  Button,
  Table,
  Modal,
  Select,
  message,
  Space,
  Dropdown,
  Divider,
} from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteContractMutation,
  useGetAllContractsQuery,
  useUpdateContractMutation,
} from "./contractApi";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

const ContractList = () => {
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: contractListData,
    isLoading,
    error,
  } = useGetAllContractsQuery(token);

  const [updateContract] = useUpdateContractMutation();
  const [deleteContract] = useDeleteContractMutation();

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "Contract Name",
      dataIndex: "contractName",
      key: "contractName",
    },
    {
      title: "Contract Type",
      dataIndex: "contractType",
      key: "contractType",
    },
    {
      title: "About to Expire",
      dataIndex: "aboutToExpire",
      key: "aboutToExpire",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = contractListData?.data?.map((contract, index) => ({
    key: contract._id,
    no: index + 1,
    contractName: contract.contractName,
    contractNumber: contract.contractNumber,
    contractType: `${contract.contractNumber} ${contract.contractType}`,
    aboutToExpire: `${contract.aboutToExpire} Days`, // Display "Days" in the table
  }));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [form] = Form.useForm();

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteContract({ contractId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Contract type is successfully deleted.");
        // Optionally refresh the table data
      } catch (err) {
        console.error("Failed to delete contract:", err);
        message.error("Failed to delete this contract type");
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      contractName: record.contractName,
      contractType: record.contractType.split(" ")[1], // Pre-fill the form with the contractType
      contractNumber: record.contractNumber, // Pre-fill the form with the contractNumber
      aboutToExpire: record.aboutToExpire.replace(" Days", ""), // Remove "Days" for editing
    });
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        contractId: currentRecord.key,
        updatedData: {
          contractName: values.contractName,
          contractType: values.contractType, // Get value from form
          contractNumber: values.contractNumber,
          aboutToExpire: values.aboutToExpire.replace(" Days", ""),
        },
      };
      await updateContract(updatedData).unwrap();
      setIsEditModalOpen(false);
      message.success("Contract is updated successfully.");
    } catch (err) {
      console.error("Failed to update contract:", err);
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  return (
    <>
      <Table defaultPageSize={3} columns={columns} dataSource={tableData} />

      <Modal
        width={360}
          style={{ textAlign: "center" }}
          title="ARE YOU SURE?"
          open={isDeleteModalOpen}
          okText="Confirm"
          okType="danger"
          closable={false}
          centered
          footer={[
            <div className="delete-modal-btn">
            <Button className="delete-btn" key="back" onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button className="delete-btn filter" key="submit" onClick={handleDeleteOk}>
              Delete
            </Button>
            </div>
          ]}>
          <p>Do you want to delete this permanently?</p>
        </Modal>

      <Modal
        width={880}
        onCancel={handleEditCancel}
        title="Edit Building"
        open={isEditModalOpen}
        footer={[
          <>
            <Button className="btn" key="back" onClick={handleEditCancel}>
              Cancel
            </Button>
            <Button className="btn filter" key="submit" onClick={handleEditOk}>
              Update
            </Button>
          </>,
        ]}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <Form form={form} layout="vertical">
          <Form.Item
            label="Contract Name"
            name="contractName"
            rules={[
              { required: true, message: "Please input the contract number!" },
            ]}>
            <Input style={{ height: 44 }} />
          </Form.Item>
          <Form.Item label="Contract Type">
            <Space.Compact style={{ width: "100%" }}>
              <Form.Item
                noStyle
                name="contractNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input the contract number!",
                  },
                ]}>
                <Input style={{ height: 44, width: "80%" }} />
              </Form.Item>
              <Form.Item
                name="contractType"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please choose the contract type!",
                  },
                ]}>
                <Select style={{ width: "20%", height: 44 }}>
                  <Select.Option value="day">Day</Select.Option>
                  <Select.Option value="month">Month</Select.Option>
                  <Select.Option value="year">Year</Select.Option>
                </Select>
              </Form.Item>
            </Space.Compact>
          </Form.Item>
          <Form.Item
            label="About to Expire"
            name="aboutToExpire"
            rules={[
              { required: true, message: "Please input the expire date!" },
            ]}>
            <Input style={{ height: 44 }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ContractList;
