import { Button, Card, Form, message, Upload, Input } from "antd";
import React, { useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { InboxOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetDownloadExcelFileQuery,
  useCreateInvoiceMutation,
} from "../../features/invoice/invoiceApi";
import { baseApi } from "../../app/baseApi";

const { Dragger } = Upload;

const props = {
  name: "file",
  multiple: true,
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const InvoiceBulkUpload = () => {
  const { refetch, isLoading } = useGetDownloadExcelFileQuery();
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [invoice, setInvoice] = useState([]);
  const [
    createInvoice,
    { isLoading: isCreateLoading, error: createInvoiceError },
  ] = useCreateInvoiceMutation();

  const handleDownload = async () => {
    try {
      const response = await fetch(
        `https://tms-b.obs.com.mm/api/v1/Invoice/excel-format-download/tenant/${id}`,
        { method: "GET" }
      );
      // const response = await fetch(`http://localhost:4000/api/v1/Invoice/excel-format-download/tenant/${id}`, { method: 'GET' });
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "TenantInvoiceFormat.xlsx"); // Filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  const handleFileChange = ({ fileList }) => {
    setInvoice(fileList);
  };
  const handleBeforeFileUpload = (file) => {
    return false;
  };
  const handleSubmit = async () => {
    let payload = {
      invoice: invoice[0]?.originFileObj,
    };

    const formData = new FormData();
    if (payload.invoice) {
      formData.append("invoice", payload.invoice);
    }

    try {
      await createInvoice({ invoice: formData }).unwrap();
      message.success("Invoice created successfully");
      navigate(`/${id}/invoice/preview`);
    } catch (error) {
      // Log and display the error message
      console.error("Create Invoice Error:", error);
      const errorMessage = error?.data?.message || "Failed to create invoice";
      message.error(errorMessage);
    }
  };

  return (
    <>
      <Card
        style={{ width: "60%" }}
        title="File Upload"
       >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item style={{ width: 400 }} label="Sample File">
            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
              <Form.Item style={{ flex: 1, margin: 0 }}>
                <Input
                  readOnly
                  value="Sample.csv"
                  style={{ height: 44, border: "1px solid darkblue" }}
                />
              </Form.Item>

              <Button
                style={{
                  height: 44,
                  background:
                    "linear-gradient(128.88deg, #03045E 1.86%, #0057FF 100%)",
                  color: "white",
                }}
                onClick={handleDownload}
                disabled={isLoading}>
                <FileDownloadOutlinedIcon />
              </Button>
            </div>
          </Form.Item>
          <Form.Item rules={[{ required: true }]} label="Invoice">
            <Dragger
              onChange={handleFileChange}
              beforeUpload={handleBeforeFileUpload}
              multiple={false}
              maxCount={1}
              listType="text"
              accept=".xlsx">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item>
            {/* <Link to={`/${id}/invoice/preview`}>
            
            </Link> */}
            <Button
              type="primary"
              htmlType="submit"
              className="btn filter"
              loading={isCreateLoading}
              disabled={isCreateLoading}
              style={{
                marginTop: "15px",
                backgroundColor: "rgba(115, 103, 240, 1)",
                color: "white",
                height: 40,
              }}>
              Send
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default InvoiceBulkUpload;
