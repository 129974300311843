import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const PageHeader = ({ title, totalItems, linkTo, addBtn, totalName }) => {
  return (
    <div className="page-new-add">
      <div>
        <h3 className="page-title">{title}</h3>
        <p className="total-number">
          Total {totalName}:
          <div className="total-no-display">{totalItems}</div>
        </p>
      </div>
      <div className="property-search">
        <Link to={linkTo}>
          <Button className="property-add-btn">
            <AddIcon />
            {addBtn}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default PageHeader;
