import {
  Form,
  Input,
  Table,
  Modal,
  Select,
  Button,
  message,
  Space,
  Dropdown,
  Divider,
  ConfigProvider,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllFloorListQuery,
  useGetAllBuildingQuery,
  useDeleteFloorMutation,
  useUpdateFloorMutation,
} from "./propertyApi";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

const { Option } = Select;

const FloorList = ({ searchTerm }) => {
  const token = useSelector((state) => state.authSlice.token2);

  // Fetch floors and buildings
  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetAllFloorListQuery(token);
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetAllBuildingQuery(token);

  // Mutations
  const [deleteFloor, { error: isDeleteError }] = useDeleteFloorMutation(token);
  const [updateFloor, { loading: isUpdateLoading, error: isUpdateError }] =
    useUpdateFloorMutation(token);

  const [form] = Form.useForm();

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const unformatPrice = (formattedPrice) => {
    return Number(formattedPrice.replace(/[^0-9.-]+/g, ""));
  };

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Number of Shops",
      dataIndex: "numberOfShops",
      key: "numberOfShops",
    },
    {
      title: "Area (sq ft)",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Price (per sq ft)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  //Search
  const [filteredFloor, setFilteredFloor] = useState([]);

  useEffect(() => {
    if (floorListData) {
      // Filter data based on search term
      const filteredData =
        searchTerm.trim() === ""
          ? floorListData.data.floors
          : floorListData.data.floors.filter((floor) =>
              floor.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
      setFilteredFloor(filteredData);
    }
  }, [searchTerm, floorListData]);

  const tableData = filteredFloor?.map((floor, index) => ({
    key: floor._id,
    no: index + 1,
    building: floor.building?.name,
    floor: floor.name,
    numberOfShops: floor.number_of_shop,
    area: floor.area,
    price: formatPrice(floor.price_per_sq),
    // price: floor.price_per_sq,
  }));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteFloor({ floorId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Floor is deleted successfully.");
      } catch (err) {
        const errorMessage =
          isDeleteError?.data?.errors?.[0] || "Failed to delete floor";
        message.error(errorMessage);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      building: record.building,
      floorName: record.floor,
      numberOfShops: record.numberOfShops,
      area: record.area,
      // Use the raw price value here if needed
      pricePerSq: formatPrice(record.price), // Assuming you have an unformatPrice function
    });
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    if (currentRecord) {
      const selectedBuilding = form.getFieldValue("building");
      const buildingId = buildingListData?.data?.buildings?.find(
        (b) => b.name === selectedBuilding || b._id === selectedBuilding
      )?._id;

      const rawPrice = unformatPrice(form.getFieldValue("pricePerSq")); // The price is already unformatted in onChange

      const updatedData = {
        floorId: currentRecord.key,
        updatedData: {
          name: form.getFieldValue("floorName"),
          number_of_shop: form.getFieldValue("numberOfShops"),
          area: form.getFieldValue("area"),
          price_per_sq: rawPrice, // Send the raw price value
          building: buildingId || form.getFieldValue("building"),
        },
      };

      try {
        await updateFloor(updatedData).unwrap();
        setIsEditModalOpen(false);
        message.success("Floor updated successfully.");
      } catch (isUpdateError) {
        const errorMessage =
          isUpdateError?.data?.errors?.[0] || "Failed to add floor";
        message.error(errorMessage);
      }
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isLoadingFloors}
          pagination={{
            pageSizeOptions: ["10", "20", "30"], // Options for 10, 20, or 50 items per page
            showSizeChanger: true, // Enables the dropdown for selecting page size
            defaultPageSize: 10, // Sets the default page size to 10
          }}
        />
        <Modal
          width={360}
          style={{ textAlign: "center" }}
          title="ARE YOU SURE?"
          open={isDeleteModalOpen}
          closable={false}
          centered
          footer={[
            <div className="delete-modal-btn">
              <Button
                className="delete-btn"
                key="back"
                onClick={handleDeleteCancel}>
                Cancel
              </Button>
              <Button
                className="delete-btn filter"
                key="submit"
                onClick={handleDeleteOk}>
                Delete
              </Button>
            </div>,
          ]}>
          <p>Do you want to delete this permanently?</p>
        </Modal>
        <Modal
          width={880}
          onCancel={handleEditCancel}
          title="Edit Floor"
          open={isEditModalOpen}
          footer={[
            <>
              <Button type="text" className="btn" key="back" onClick={handleEditCancel}>
                Cancel
              </Button>
              <Button
                loading={isUpdateLoading}
                className="btn filter"
                key="submit"
                onClick={handleEditOk}>
                Update
              </Button>
            </>,
          ]}>
          <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
          <Form layout="vertical" form={form}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flexWrap: "wrap",
              }}>
              <Form.Item label="Building" name="building">
                <Select
                  style={{ height: 44, width: 400 }}
                  placeholder="Select a building"
                  loading={isLoadingBuildings}>
                  {buildingListData?.data?.buildings?.map((building) => (
                    <Option key={building._id} value={building._id}>
                      {building.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Floor Name"
                name="floorName"
                style={{ width: 400 }}>
                <Input style={{ height: 44 }} placeholder="Floor Name" />
              </Form.Item>
              <Form.Item
                label="Number of Shops"
                name="numberOfShops"
                style={{ width: 400 }}>
                <Input
                  min={1}
                  type="number"
                  style={{ height: 44 }}
                  placeholder="Number of Shops"
                />
              </Form.Item>
              <Form.Item
                label="Unit (sq ft)"
                name="area"
                style={{ width: 400 }}>
                <Input
                  type="number"
                  style={{ height: 44 }}
                  placeholder="Area (sq ft)"
                />
              </Form.Item>
              <Form.Item
                label="Price per sq ft (MMK)"
                name="pricePerSq"
                style={{ width: 400 }}>
                <Input
                  style={{ height: 44 }}
                  placeholder="Price per sq ft (MMK)"
                  onChange={(e) => {
                    // Remove formatting (commas) from the value and set it as a raw number
                    const rawValue = unformatPrice(e.target.value);
                    form.setFieldsValue({ pricePerSq: rawValue });
                  }}
                  value={formatPrice(form.getFieldValue("pricePerSq"))} // Show the formatted price
                />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </ConfigProvider>
    </>
  );
};

export default FloorList;
