import {
  ConfigProvider,
  Form,
  Input,
  Button,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Divider,
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useAddNewMaintenanceTypeMutation,
  useDeleteMaintenanceTypeMutation,
  useGetAllMaintenanceTypeListQuery,
  useUpdateMaintenanceTypeMutation,
} from "../../features/maintenance/maintenanceApi";
import AddIcon from "@mui/icons-material/Add";
import {
  EyeOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const { Search } = Input;

const TypesOfIssues = () => {
  const token = useSelector((state) => state.authSlice.token2);
  const {
    data: IssueListData,
    isLoading,
    error,
  } = useGetAllMaintenanceTypeListQuery(token);

  const [addNewMaintenanceType] = useAddNewMaintenanceTypeMutation();
  const [deleteMaintenanceType] = useDeleteMaintenanceTypeMutation();
  const [updateMaintenanceType] = useUpdateMaintenanceTypeMutation();

  const [form] = Form.useForm();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(
      IssueListData?.data?.map((issue, index) => ({
        key: issue._id,
        no: index + 1,
        type: issue.type,
        description: issue.description,
      })) || []
    );
  }, [IssueListData]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "60%",
    },
    {
      title: (
        <SettingsOutlinedIcon style={{ display: "flex", margin: "auto" }} />
      ),
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <span>
                      <EditOutlined /> Edit
                    </span>
                  ),
                  onClick: () => showEditModal(record),
                },
                {
                  key: "2",
                  label: (
                    <span>
                      <DeleteOutlined /> Delete
                    </span>
                  ),
                  onClick: () => showDeleteModal(record),
                },
              ],
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleSearch = (value) => {
    const filtered = IssueListData?.data
      ?.filter((issue) =>
        issue.type.toLowerCase().includes(value.toLowerCase())
      )
      .map((issue, index) => ({
        key: issue._id,
        no: index + 1,
        type: issue.type,
        description: issue.description,
      }));
    setFilteredData(filtered);
  };

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteMaintenanceType({ typeId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Type of Issue deleted successfully.");
      } catch (err) {
        console.error("Failed to delete type of issue:", err);
        message.error("Failed to delete type of issue.");
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord(record);
    form.setFieldsValue({
      type: record.type,
      description: record.description,
    });
    setIsEditModalOpen(true);
  };

  const handleEditOk = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = {
        typeId: currentRecord.key,
        updatedData: {
          type: values.type,
          description: values.description,
        },
      };
      await updateMaintenanceType(updatedData).unwrap();
      setIsEditModalOpen(false);
      message.success("Type of Issue updated successfully.");
    } catch (err) {
      console.error("Failed to update type of issue:", err);
      message.error("Failed to update type of issue.");
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const showCreateModal = () => {
    form.resetFields();
    setCurrentRecord(null);
    setIsCreateModalOpen(true);
  };

  const handleCreateOk = () => {
    form.submit(); // Trigger form submission
  };

  const handleCreateCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleSubmit = async (values) => {
    const payload = {
      type: values.type,
      description: values.description,
    };
    try {
      await addNewMaintenanceType(payload).unwrap();
      form.resetFields();
      message.success("New Type of Issue added successfully.");
      setIsCreateModalOpen(false); // Close the modal after successful submission
    } catch (err) {
      console.error("Failed to add new issue.", err);
      message.error("Failed to add new issue.");
    }
  };

  return (
    <>
      <div className="page-new-add">
        <div>
          <h3 className="page-title">Types of Issue</h3>
          <p className="total-number">
            Types of Issue :{" "}
            <div className="total-no-display">
              {IssueListData?.data?.announcements}
            </div>
          </p>
        </div>
        <div className="property-search">
          <Form.Item>
            <Button className="property-add-btn" onClick={showCreateModal}>
              <AddIcon />
              New Type of Issue
            </Button>
          </Form.Item>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Types of Issue"
          allowClear
          enterButton="Search"
          onSearch={handleSearch}
          size="large"
          style={{ width: 456, marginBottom: "15px" }}
        />
      </div>
      <Table columns={columns} dataSource={filteredData} />

      {/* Delete Modal */}
      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isDeleteModalOpen}
        okText="Confirm"
        okType="danger"
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button
              className="delete-btn"
              key="back"
              onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleDeleteOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>

      {/* Edit Modal */}
      <Modal
        width={880}
        title="Edit Type of Issue"
        open={isEditModalOpen}
        onCancel={handleEditCancel}
        footer={[
          <>
            <Button className="btn" key="back" onClick={handleEditCancel}>
              Cancel
            </Button>
            <Button className="btn filter" key="submit" onClick={handleEditOk}>
              Update
            </Button>
          </>,
        ]}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <Form form={form} layout="vertical">
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Please input the type!" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>

      {/* Create Modal */}
      <Modal
        width={880}
        title="Add New Type of Issue"
        open={isCreateModalOpen}
        okText="Confirm"
        onCancel={handleCreateCancel}
        footer={[
          <>
            <Button
              type="text"
              className="btn"
              key="back"
              onClick={handleCreateCancel}>
              Cancel
            </Button>
            <Button
              className="btn filter"
              key="submit"
              onClick={handleCreateOk}>
              Create
            </Button>
          </>,
        ]}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
            },
          }}>
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                { required: true, message: "Please enter the type of issue" },
              ]}>
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please enter the description" },
              ]}>
              <Input.TextArea />
            </Form.Item>
          </Form>
        </ConfigProvider>
      </Modal>
    </>
  );
};

export default TypesOfIssues;
