import React, { useState, useEffect } from "react";
import { Form, Input, Button, Table, Modal, Space, Dropdown, message, Divider } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAddNewRoleMutation, useGetAllRolesByCAdminQuery } from "../../../features/adminstrator/adminApi";
import AddIcon from "@mui/icons-material/Add";
import { DownOutlined } from "@ant-design/icons";

const { Search } = Input;

const RolePermission = () => {
  const token = useSelector((state) => state.authSlice.token);
  const { data: roleListData, isLoading, error } = useGetAllRolesByCAdminQuery(token);
  
  const [addNewRole] = useAddNewRoleMutation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const { id } = useParams();

  // State to manage filtered roles
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(
      roleListData?.data?.roles?.map((role, index) => ({
        key: role._id,
        no: index + 1,
        role: role.name,
      })) || []
    );
  }, [roleListData]);

  const handleSubmit = async (values) => {
    const payload = { name: values.name };
    try {
      await addNewRole(payload).unwrap();
      form.resetFields();
      message.success("New Role Added Successfully.");
    } catch (err) {
      console.error("Failed to add new role", err);
      message.error("Failed to add new role.");
    }
  };

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = () => {
    // Perform the delete operation here using currentRecord
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  // Search function to filter roles
  const handleSearch = (value) => {
    const filtered = roleListData?.data?.roles?.filter((role) =>
      role.name.toLowerCase().includes(value.toLowerCase())
    ).map((role, index) => ({
      key: role._id,
      no: index + 1,
      role: role.name,
    }));
    setFilteredData(filtered);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Options",
      key: "option",
      align: "center",
      width: '20%',
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: "View",
                  onClick: () =>
                    navigate(`view-details`, { state: { role: record } }),
                },
                {
                  key: "2",
                  label: "Edit",
                  onClick: () =>
                    navigate(`update-role`, { state: { role: record } }),
                },
                // {
                //   key: "3",
                //   label: "Delete",
                //   onClick: () => showDeleteModal(record),
                // },
              ],
            }}
            placement="bottomRight">
            <Button style={{border:'1px solid darkblue'}}>Action <DownOutlined/></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <>
    <div className="page-new-add">
        <div>
          <h3 className="page-title">Role List</h3>
        </div>
        <div className="property-search">
          <Form.Item>
            {/* <Link to={`/adminstrator/role-permission/add-new-role`}> */}
              <Button onClick={showModal} className="property-add-btn">
                <AddIcon />
                Add New Role
              </Button>
            {/* </Link> */}
          </Form.Item>
        </div>
      </div>
      <div style={{marginBottom: '20px'}} className="search-picker-combo">
        <Search
          placeholder="Search"
          allowClear
          enterButton="Search"
          size="large"
          // onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: 456,
          }}
        />
      </div>
          <Form>
            <Form.Item>
              <Table
                columns={columns}
                dataSource={filteredData} // Use filteredData as the dataSource
                loading={isLoading}
                rowKey="key" // Ensure each row has a unique key
              />
            </Form.Item>
          </Form>

          <Modal
            title="Are you sure you want to delete this role?"
            open={isDeleteModalOpen}
            okText="Confirm"
            okType="danger"
            onOk={handleDeleteOk}
            onCancel={handleDeleteCancel}
          />


        <Modal
        width={880}
        title="Create New Role"
        open={isModalVisible}
        onOk={handleOk}
        closeIcon={null}
        onCancel={handleCancel}
        footer={null}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item name="name" label="Role">
              <Input style={{ height: 44 }} placeholder="Role" />
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className="btn" onClick={handleCancel}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="btn filter">
                Create
              </Button>
            </Form.Item>
          </Form>
        </Modal>
    </>
  );
};

export default RolePermission;
