import React from "react";
import { Input } from "antd";

const { Search } = Input;

const SearchFilter = ({ onSearch, searchPlaceHolder }) => {
  return (
    <div className="search-picker-combo">
      <Search
        placeholder={searchPlaceHolder}
        allowClear
        enterButton="Search"
        size="large"
        onChange={(e) => onSearch(e.target.value)}
        style={{ width: 456 }}
      />
    </div>
  );
};

export default SearchFilter;
