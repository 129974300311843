import { baseApi } from "../../app/baseApi";

export const mainteinanceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllMaintenanceTypeList: builder.query({
      query: () => `/Maintenance/typeOfIssues/list`,
      providesTags: ["MaintenanceTypes"],
    }),

    addNewMaintenanceType: builder.mutation({
      query: ({ type, description }) => ({
        url: `/Maintenance/typeOfIssues/create`,
        method: "POST",
        body: {
          type,
          description,
        },
      }),
      invalidatesTags: ["MaintenanceTypes"],
    }),

    updateMaintenanceType: builder.mutation({
      query: ({ typeId, updatedData }) => ({
        url: `/Maintenance/typeOfIssues/update/${typeId}`,
        method: "PATCH",
        body: updatedData,
      }),
      onError: (error) => {
        console.error("Edit type of issue failed:", error);
      },
      invalidatesTags: ["MaintenanceTypes"],
    }),

    deleteMaintenanceType: builder.mutation({
      query: ({ typeId }) => ({
        url: `/Maintenance/typeOfIssues/delete/${typeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MaintenanceTypes"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllMaintenanceTypeListQuery, useAddNewMaintenanceTypeMutation,
  useDeleteMaintenanceTypeMutation,
  useUpdateMaintenanceTypeMutation
} = mainteinanceApi;
