import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Card, Form, Select, Input, Breadcrumb } from "antd";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { Link } from "react-router-dom";
import styles from "./styles.css";

const ViewDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { shop } = location.state || {}; // Retrieve the shop data from state
  
  if (!shop) {
    return <div>No data available</div>; // Handle the case where no data is available
  }

  const statusColor = shop.status === 'Available' ? 'green' : 'red';

  return (
    <>
    <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/list`)}>
          Property List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Property</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
          <ArrowBackIcon
            onClick={() => navigate(`/${id}/property/list`)}
            style={{ cursor: "pointer" }}
          />
          <span style={{ marginLeft: "8px" }}>{shop.shopName}</span>
          <span
            style={{
              marginLeft: "16px",
              color: statusColor,
              fontWeight: 'bold',
            }}
          >
            {shop.status}
          </span>
        </div>
        }
        extra={
          <Link to={`/${id}/property/list/edit-details/${shop.key}`} state={{ shop }} >
            <EditNoteOutlinedIcon style={{ width: "24px", height: "24px" }} />
          </Link>
        }
      >
        <Form className="view-detail-form">
          <Form.Item className="input-group">
            <h4 className="input-label">Shop Name</h4>
            <Input value={shop.shopName} readOnly style={{ height: 44 }} />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Building</h4>
            <Input value={shop.building} readOnly style={{ height: 44 }} />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Floor</h4>
            <Input value={shop.floor} readOnly style={{ height: 44 }} />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Zone / Role</h4>
            <Input value={shop.zone} readOnly style={{ height: 44 }} />
          </Form.Item>

          {/* <Form.Item className="input-group">
            <h4 className="input-label">Type</h4>
            <Input value={shop.type} readOnly style={{ height: 44 }} />
          </Form.Item> */}

          <Form.Item className="input-group">
            <h4 className="input-label">Code</h4>
            <Input value={shop.code} readOnly style={{ height: 44 }} />
          </Form.Item>

          {/* <Form.Item className="input-group">
            <h4 className="input-label">Description</h4>
            <Input value={shop.description} readOnly style={{ height: 44 }} />
          </Form.Item> */}

          <Form.Item className="input-group">
            <h4 className="input-label">Total Area (sq ft)</h4>
            <Input value={shop.area} readOnly style={{ height: 44 }} />
          </Form.Item>

          <Form.Item className="input-group">
            <h4 className="input-label">Price (MMK)</h4>
            <Input value={shop.price} readOnly style={{ height: 44 }} />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ViewDetail;
