import React from 'react'
import { Input, Table } from 'antd';

const { Search } = Input;

const UserLogs = () => {

  const columns = [
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
    {
      title: "Login Date",
      dataIndex: "loginDate",
      key: "loginDate",
    },
    {
      title: "User Agent",
      dataIndex: "agent",
      key: "agent",
    },
  ];

  const dataSource = [
    {
      key: 1,
      users: "spencer@gmail.com",
      role: "Super Admin",
      ipAddress: '49.37.209.145',
      loginDate: '2/8/2024, 16:32:00',
      agent: 'Chrome 127.6.8.0, Mac OS',
    }
  ]

  return (
    <div>
       <div className="page-new-add">
        <div>
          <h3 className="page-title">User Logs</h3>
          <p className="total-number">
          Here you can find the recent activity within your organization.
          </p>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Search"
          allowClear
          enterButton="Search"
          size="large"
          style={{
            width: 456,
          }}
        />
      </div>
      <Table style={{marginTop: 20}} dataSource={dataSource} columns={columns}/>
    </div>
  )
}

export default UserLogs