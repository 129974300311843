import { baseApi } from "../../app/baseApi";

export const tenantApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenant: builder.query({
      query: () => `/Tenant/tenants/list`,
      providesTags: ["TenantList"],
    }),

    getTenantProfileById: builder.query({
      query: ({ tenantId }) => `/Tenant/tenants/profile/${tenantId}`,
      providesTags: ["TenantProfile", "TenantList"],
    }),

    getTenantPropertyById: builder.query({
      query: ({ tenantId }) =>
        `/Tenant/tenants/get-properties-list/${tenantId}`,
      providesTags: ["TenantList", "TenantPropertyList"],
    }),

    getPropertyByPropertyId: builder.query({
      query: ({ propertyId }) =>
        `/Tenant/tenants/get-properties-list/edit/${propertyId}`,
      providesTags: ["TenantPropertyList"],
    }),

    getAllCitiesList: builder.query({
      query: () => `/common/location`,
      providesTags: ["TenantList", "CityList"]
    }),

    getTownshipByCityId: builder.query({
      query: ({cityId}) => `/common/location?city_id=${cityId}`,
      providesTags: ["TenantList", "TownshipList"]
    }),

    getTenantDetailById: builder.query({
      query: ({tenantId}) => `/Tenant/tenants/edit/${tenantId}`,
      providesTags: ["TenantList", "TenantProfile"]
    }),

    addNewTenant: builder.mutation({
      query: (params) => {
        const formData = new FormData();
        
        // Append simple fields
        formData.append("photo", params.photo);
        formData.append("name", params.name);
        formData.append("outlet_name", params?.outlet_name);
        formData.append("phoneNumber", params.phoneNumber);
        formData.append("email", params.email);
        formData.append("city", params.city);
        formData.append("township", params.township);
        formData.append("address", params.address);
        formData.append("status", params.status);
        formData.append("type", params.type);
        formData.append("password", params.password);
        
        // Append nested objects as JSON strings
        formData.append("billing_contact_person", JSON.stringify(params.billing_contact_person));
        formData.append("shop_id", params.shop_id);
        formData.append("contract_info", JSON.stringify(params.contract_info));
        
        // Handle files in contract_info separately if needed
        if (params.contract_info?.contract_file) {
          formData.append("contract_file", params.contract_info.contract_file);
        }
        
        return {
          url: `/Tenant/tenants/create`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["TenantList"],
    }),

    addNewProperty: builder.mutation({
      query: ({ params, tenantId }) => {      
        const formData = new FormData();
        formData.append("outlet_name", params?.outlet_name);
        formData.append("type", params?.type);
        formData.append("billing_contact_person", JSON.stringify(params?.billing_contact_person));
        formData.append("shop_id", params?.shop_id);
        formData.append("contract_info", JSON.stringify(params?.contract_info));
    
        if (params?.contract_info?.contract_file) {
          formData.append("contract_file", params?.contract_info.contract_file);
        }
    
        return {
          url: `/Tenant/tenants/add-property/${tenantId}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["TenantList", "TenantPropertyList"],
    }),  
    
    addNewPassword: builder.mutation({
      query: ({ newPassword, tenantId }) => ({
        url: `/Tenant/tenants/reset-password/${tenantId}`,
        method: "POST",
        body: {
          newPassword,
        },
      }),
      invalidatesTags: ["TenantList", "TenantPropertyList", "TenantProfile"],
    }),    
    
    updateTenantDetail: builder.mutation({
      query: ({params, tenantId}) => {
        const formData = new FormData();
  
        formData.append("name", params.name);
        formData.append("phoneNumber", params.phoneNumber);
        formData.append("email", params.email);
        formData.append("city", params.city);
        formData.append("township", params.township);
        formData.append("address", params.address);
        formData.append("status", params.status)
    
        // Append the profile image/file as binary data
        if (params.photo) {
          formData.append("photo", params.photo); // Assuming `params.profile` is a File object
        }
    
        return {
          url: `/Tenant/tenants/update/${tenantId}`,
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          body: formData,
        };
      },
      invalidatesTags: ["TenantList", "TenantPropertyList", "TenantProfile"],
    }),

    updateProperty: builder.mutation({
      query: ({ params, propertyId }) => {      
        const formData = new FormData();
        formData.append("outlet_name", params?.outlet_name);
        formData.append("type", params?.type);
        formData.append("tenant", params?.tenant);
        formData.append("billing_contact_person", JSON.stringify(params?.billing_contact_person));
        formData.append("shop", params?.shop_id);
        formData.append("contract_id", params?.contract_id);
        formData.append("contract_name", params?.contract_name);
        formData.append("start_date", params?.start_date);
        formData.append("due_date", params?.due_date);
        formData.append("contract_file", params?.contract_file);
        
    
        return {
          url: `/Tenant/tenants/get-properties-list/update/${propertyId}`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["TenantPropertyList", "TenantProfile", "TenantList"],
    }),   

    deleteTenant: builder.mutation({
      query: ({ tenantId }) => ({
        url: `/Tenant/tenants/delete/${tenantId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TenantList"],
    }),

    deleteProperty: builder.mutation({
      query: ({propertyId}) => ({
        url: `/Tenant/tenants/get-properties-list/delete/${propertyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TenantPropertyList", "TenantProfile", "TenantList"]
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllTenantQuery,
  useGetTenantProfileByIdQuery,
  useGetTenantPropertyByIdQuery,
  useGetPropertyByPropertyIdQuery,
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
  useAddNewTenantMutation,
  useDeleteTenantMutation,
  useAddNewPropertyMutation,
  useGetTenantDetailByIdQuery,
  useUpdateTenantDetailMutation,
  useUpdatePropertyMutation,
  useDeletePropertyMutation,
  useAddNewPasswordMutation
} = tenantApi;
