import { Form, Input, Button, message } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useAddNewBuildingMutation } from "./propertyApi";

const AddNewBuildingForm = ({ handleOk, handleCancel }) => {
  const selectedBranchId = useSelector(
    (state) => state.authSlice.selectedBranch
  );

  const [addNewBuilding, { isLoading, error }] = useAddNewBuildingMutation();
  const [form] = Form.useForm(); // Get the form instance

  const handleSubmit = async (values) => {
    const payload = {
      name: values.building,
      branch: selectedBranchId, // Include the branch ID if needed
    };

    try {
      await addNewBuilding(payload).unwrap();
      form.resetFields(); // Clear the input fields after successful submission
      message.success("Building is added successfully.");
      handleOk();
    } catch (error) {
      const errorMessage = error?.data?.message || "Failed to add building";
      message.error(errorMessage);
    }
  };

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields when Cancel is clicked
    handleCancel(); // Call parent cancel handler
  };

  return (
    <Form
      form={form} // Assign the form instance to the Form component
      layout="vertical"
      onFinish={handleSubmit}>
      <Form.Item label="New Building" name="building">
        <Input style={{ height: 44, background:'#f8f7f7' }} placeholder="Enter building name" />
      </Form.Item>
      <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="text" className="btn" onClick={handleFormCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="btn filter"
          loading={isLoading}>
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddNewBuildingForm;
