import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Correct import
import { tokenExpired } from "../features/auth/authSlice";

const useTokenExpiration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.authSlice.token);

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log("Decoded Token", decodedToken);

        const currentTime = Math.floor(Date.now() / 1000); // Ensure current time is in seconds

        if (decodedToken.exp < currentTime) {
          console.log("Token expired, navigating to sign-in...");
          dispatch(tokenExpired());
          navigate("/sign-in");
        }
      } catch (error) {
        console.error("Token decoding error:", error);
        dispatch(tokenExpired());
        navigate("/sign-in");
      }
    }
  }, [token, dispatch, navigate]);
};

export default useTokenExpiration;
