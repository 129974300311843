import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Select, Button } from "antd";
import styles from "./styles.css";

const SelectBranch = () => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);
    navigate("/");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="sign-in-form">
      <Form
        className="sign-in"
        layout="vertical"
        name="sign-in"
        style={{ width: "450px" }}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">

        <h2 className="header">TENANT MANAGEMENT SYSTEM</h2>

        <Form.Item label="Select Branch">
          <Select>
            <Select.Option value="one">Branch One</Select.Option>
            <Select.Option value="two">Branch Two</Select.Option>
            <Select.Option value="three">Branch Three</Select.Option>
          </Select>
        </Form.Item>
        
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="sign-in-submit-btn">
            ENTER
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SelectBranch;
