import React, { useState, useEffect } from "react";
import GenerateIcon from "../../../assets/icons/refresh.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Upload,
  Checkbox,
  message,
  Button,
  ConfigProvider,
  Breadcrumb,
} from "antd";
import ImgCrop from "antd-img-crop";
import "../styles.css";
import { isJPGorPNG } from "../../../utils";
import {
  useGetAllCitiesListQuery,
  useGetAllTenantQuery,
  useGetTownshipByCityIdQuery,
} from "../../../features/tenant/tenantApi";
import {
  useAddNewSubTenantMutation,
  useGetShopByTenantIdQuery,
} from "../../../features/tenant/subTenantApi";

const { Option } = Select;

const AddNewSubTenant = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [imageObj, setImageObj] = useState(null);
  const [imgUrl, setImgUrl] = useState("");
  const [fileList, setFileList] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const { data: tenantListData, isLoading: isTenantLoading } =
    useGetAllTenantQuery();
  const [selectedTenant, setSelectedTenant] = useState();
  const { data: shopListDataByTenant, isLoaidng: isShopLoading } =
    useGetShopByTenantIdQuery(
      { tenantId: selectedTenant },
      { skip: !selectedTenant }
    );

  const [addNewSubTenant, { isLoading: isAddNewTenantLoading }] =
    useAddNewSubTenantMutation();

  useEffect(() => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        setImgUrl(reader.result);
      },
      false
    );
    if (imageObj) {
      reader.readAsDataURL(imageObj);
    }
  }, [imageObj]);

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.originFileObj) {
        return {
          ...file,
          preview: URL.createObjectURL(file.originFileObj),
        };
      }
      return file;
    });

    setFileList(newFileList);
    if (info.fileList[0]?.originFileObj) {
      setImageObj(info.fileList[0]?.originFileObj);
    } else {
      setImageObj(null);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isJPGorPNG(file);
    if (!isJpgOrPng) {
      message.error("You need to upload JPG or PNG.");
      return false;
    }
    return false;
  };

  const handleShowPreview = () => {
    setShowPreviewModal(true);
  };

  const handlePreviewCancel = () => {
    setShowPreviewModal(false);
  };

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      const tenantInfo = form.getFieldsValue([
        "name",
        "phoneNumber",
        "email",
        "city",
        "township",
        "address",
      ]);
      form.setFieldsValue({
        billingName: tenantInfo.name,
        billingPhoneNumber: tenantInfo.phoneNumber,
        billingEmail: tenantInfo.email,
        billingCity: tenantInfo.city,
        billingTownship: tenantInfo.township,
        billingAddress: tenantInfo.address,
      });
    } else {
      form.resetFields([
        "billingName",
        "billingPhoneNumber",
        "billingEmail",
        "billingCity",
        "billingTownship",
        "billingAddress",
      ]);
    }
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        photo: imageObj,
        name: values.name,
        phoneNumber: values.phoneNumber,
        email: values.email,
        city: values.city,
        township: values.township,
        address: values.address,
        status: values.status,
        password: values.password,
        billing_contact_person: {
          name: values.billingName || "",
          phoneNumber: values.billingPhoneNumber || "",
          email: values.billingEmail || "",
          city: values.billingCity || "",
          township: values.billingTownship || "",
          address: values.billingAddress || "",
        },
        property_information: {
          tenant: values.tenant,
          shop: values.shop,
          outlet_name: values.outlet_name,
        },
      };
      console.log("Payload", payload);
      await addNewSubTenant(payload).unwrap();
      message.success("New Sub Tenant created successfully.");
      navigate(`/${id}/tenant/sub-list`);
    } catch (error) {
      message.error(`Not available to create new sub tenant.`);
    }
  };

  const [selectedOutletName, setSelectedOutletName] = useState("");

  const handleShopChange = (shopId) => {
    const selectedShop = shopListDataByTenant?.data?.find(
      (shopItem) => shopItem.shop._id === shopId
    );

    setSelectedOutletName(selectedShop?.outlet_name || "");
    form.setFieldsValue({
      outlet_name: selectedShop?.outlet_name || "",
      code: selectedShop?.shop?.code || "",
      zone: selectedShop?.shop?.zone?.name || "",
      floor: selectedShop?.shop?.floor?.name || "",
      building: selectedShop?.shop?.building?.name || "",
      area: selectedShop?.shop?.total_area || "",
      price: selectedShop?.shop?.price || "",
    });
  };

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < 8; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return password;
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    form.setFieldsValue({ password: newPassword }); // Update form field with new password
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/tenant/sub-list`)}>
          Sub Tenant List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Sub Tenant</Breadcrumb.Item>
      </Breadcrumb>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Card
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginLeft: "8px" }}>Sub Tenant Information</span>
            </div>
          }>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <Form.Item>
              <ImgCrop rotationSlider>
                <Upload
                  onPreview={handleShowPreview}
                  onChange={handleChange}
                  beforeUpload={beforeUpload}
                  listType="picture-circle"
                  accept=".png,.jpg,image/png,image/jpeg"
                  multiple={false}
                  maxCount={1}
                  fileList={fileList}>
                  {fileList.length >= 1 ? null : "+ Upload"}
                </Upload>
              </ImgCrop>
            </Form.Item>

            <div className="billing-contact-form">
              <Form.Item
                className="tenant-label-group"
                name="name"
                label="Name">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                className="tenant-label-group">
                <Input style={{ height: 44 }} placeholder="Phone Number" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Email"
                name="email">
                <Input style={{ height: 44 }} placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="City"
                name="city"
                className="tenant-label-group">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select city name"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isCityListLoading}>
                  {cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="township"
                label="Township"
                className="tenant-label-group">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a township"
                  loading={isTownshipListLoading}>
                  {townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Address"
                style={{ width: "100%" }}
                name="address">
                <Input style={{ height: 44 }} placeholder="Address" />
              </Form.Item>

              <Form.Item
                label="Status"
                className="tenant-label-group"
                name="status">
                <Select style={{ height: 44 }} placeholder="Select Status">
                  <Option value={1}>Active</Option>
                  <Option value={2}>Suspend</Option>
                </Select>
              </Form.Item>

              {/* <Form.Item
              name="password"
              label="Generate Password"
              className="tenant-label-group">
              <Input.Password
                style={{ height: 44 }}
                placeholder="Set Password"
              />
            </Form.Item> */}
              <Form.Item
                label="Generate Password"
                name="password"
                style={{ width: 400 }}
                rules={[
                  { required: true, message: "Please input the password" },
                ]}
                className="tenant-label-group">
                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                  <Form.Item name="password" style={{ flex: 1, margin: 0 }}>
                    <Input.Password
                      style={{ height: 44 }}
                      placeholder="Set Password"
                    />
                  </Form.Item>
                  <Button
                    style={{ width: 44, height: 44 }}
                    onClick={handleGeneratePassword}>
                    <img
                      style={{ width: 20, height: 20 }}
                      src={GenerateIcon}
                      alt="Generate"
                    />
                  </Button>
                </div>
              </Form.Item>
            </div>
            <Form.Item>
              <Checkbox onChange={handleCheckboxChange}>
                Billing contact person same as sub tenant info.
              </Checkbox>
            </Form.Item>
          </ConfigProvider>
        </Card>

        <Card title="Billing Contact Person" style={{ marginTop: "15px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="add-new-tenant">
              <Form.Item
                className="tenant-label-group"
                name="billingName"
                label="Name">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingPhoneNumber"
                label="Phone Number">
                <Input style={{ height: 44 }} placeholder="Phone Number" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingEmail"
                label="Email">
                <Input style={{ height: 44 }} placeholder="Email" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingCity"
                label="City">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select city name"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isCityListLoading}>
                  {cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                name="billingTownship"
                label="Township">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a township"
                  loading={isTownshipListLoading}>
                  {townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="billingAddress"
                label="Address"
                style={{ width: 705 }}>
                <Input style={{ height: 44 }} placeholder="Address" />
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Card title="Property Information" style={{ marginTop: "15px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: "#f8f7f7",
              },
            }}>
            <div className="add-new-tenant">
              <Form.Item
                className="tenant-label-group"
                name="tenant"
                label="Tenant Name">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select tenant name"
                  onChange={(value) => setSelectedTenant(value)}
                  loading={isTenantLoading}>
                  {tenantListData?.data?.map((tenant) => (
                    <Option key={tenant._id} value={tenant._id}>
                      {tenant?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="shop"
                className="tenant-label-group"
                label="Shop Name">
                {/* <Select style={{ height: 44 }} placeholder="Select Shop" /> */}
                <Select
                  style={{ height: 44 }}
                  onChange={handleShopChange}
                  placeholder="Select shop"
                  loading={isShopLoading}>
                  {shopListDataByTenant?.data?.map((shop) => (
                    <Option key={shop.shop._id} value={shop.shop._id}>
                      {shop?.shop?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="outlet_name"
                className="tenant-label-group"
                label="Outlet Name">
                <Input
                  style={{ height: 44 }}
                  placeholder="Outlet Name"
                  value={selectedOutletName}
                  readOnly
                />
              </Form.Item>

              <Form.Item
                name="code"
                className="tenant-label-group"
                label="Shop Code">
                <Input readOnly style={{ height: 44 }} placeholder="Code" />
              </Form.Item>

              <Form.Item
                name="zone"
                className="tenant-label-group"
                label="Zone / Role">
                <Input
                  readOnly
                  style={{ height: 44 }}
                  placeholder="Zone / Role"
                />
              </Form.Item>

              <Form.Item
                name="floor"
                className="tenant-label-group"
                label="Floor">
                <Input readOnly style={{ height: 44 }} placeholder="Floor" />
              </Form.Item>

              <Form.Item
                name="building"
                className="tenant-label-group"
                label="Building">
                <Input readOnly style={{ height: 44 }} placeholder="Building" />
              </Form.Item>

              <Form.Item
                name="area"
                className="tenant-label-group"
                label="Total Area (Sq ft)">
                <Input
                  readOnly
                  style={{ height: 44 }}
                  placeholder="Total Area"
                />
              </Form.Item>

              <Form.Item
                name="price"
                className="tenant-label-group"
                label="Price (MMK)">
                <Input readOnly style={{ height: 44 }} placeholder="Price" />
              </Form.Item>
            </div>
          </ConfigProvider>
        </Card>

        <Form.Item>
          <div className="add-tenant-btn-group">
            <Button className="btn">Cancel</Button>
            <Button
              type="text"
              htmlType="submit"
              loading={isAddNewTenantLoading}
              disabled={isAddNewTenantLoading}
              className="btn filter">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>
      {/* </ConfigProvider> */}
    </>
  );
};

export default AddNewSubTenant;
