import { Card, Form, Input, Button, Table, Space, Dropdown, Spin } from "antd";
import React from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { useGetAllBranchesQuery } from "./branchSettingApi";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";

const { Search } = Input;

const BranchList = () => {
  const token = useSelector((state) => state.authSlice.token);
  const { data: branchList, isLoading, error } = useGetAllBranchesQuery(token);

  const items = [
    {
      key: "1",
      label: (<span>
        <EditOutlined/> Edit
      </span>),
      // onClick: (record) =>
      //   navigate(`edit-tenant`, { state: { tenant: record } }),
    },
    {
      key: "4",
      label: (<span>
        <DeleteOutlined/> Delete
      </span>),
      // onClick: (record) => {
      //   showModal(record);
      // },
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Township",
      dataIndex: "township",
      key: "township",
    },
    {
      title: '',
      key: "operation",
      align: "center",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{border:'1px solid darkblue'}}>Action <DownOutlined/></Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const tableData = branchList?.data?.map((branch, index) => ({
    key: branch._id,
    no: index + 1,
    branchName: branch?.name,
    contactPerson: branch?.contact_name,
    email: branch?.email,
    phoneNumber: branch?.phone_number,
    city: branch?.city?.city_name,
    township: branch?.township?.township_name,
  }));

  return (
    <>
     <div className="page-new-add">
        <div>
          <h3 className="page-title">Branches</h3>
          <p className="total-number">
            Total Branches :{" "}
            <div className="total-no-display">
              {branchList?.data?.branches}
            </div>
          </p>
        </div>
        <div className="property-search">
          <Form.Item>
            <Link to={`/setting/branch/add-new-branch`}>
              <Button className="property-add-btn">
                <AddIcon />
                Add New Branch
              </Button>
            </Link>
          </Form.Item>
        </div>
      </div>
      <div className="search-picker-combo">
        <Search
          placeholder="Search"
          allowClear
          enterButton="Search"
          size="large"
          // onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: 456,
          }}
        />
      </div>
        <Form style={{marginTop: 20}}>
              <Table
                columns={columns}
                dataSource={tableData}
                loading={isLoading}
                // onChange={onChange}
                showSorterTooltip={{
                  target: "sorter-icon",
                }}
              />
        </Form>
    </>
  );
};

export default BranchList;
