import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Table, Breadcrumb } from "antd";

const OwnerHistory = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const columns = [
    {
      title: "#",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Phone No.",
      dataIndex: "phNum",
      key: "phNum",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Option",
      key: "operation",
      align: "center",
      render: () => <Link to={`/${id}/property/list/owner-details`}>View Details</Link>,
    },
  ];

  const data = [
    {
      key: "1",
      ownerName: "Win Pa Pa",
      phNum: "09123456789",
      email: "winpapa@gmail.com",
      startDate: "DD-MM-YYYY",
      endDate: "-",
    },
    {
      key: "2",
      ownerName: "GMP",
      phNum: "09123456789",
      email: "gmp@gmail.com",
      startDate: "DD-MM-YYYY",
      endDate: "DD-MM-YY",
    },
  ]

  return (
    <>
       <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/list`)}>
          Property List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Owner History</Breadcrumb.Item>
      </Breadcrumb>
      <Table columns={columns} dataSource={data}></Table>
    </>
  );
};

export default OwnerHistory;
