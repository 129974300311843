import { Card, DatePicker, Form,  message, Input, Select, Button, Breadcrumb, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import styles from "./styles.css";
import { useGetAllInvoicesListQuery ,useUpdatePreviewInvoiceMutation,useGetInvoiceByIdQuery} from '../../features/invoice/invoiceApi';


const EditInvoice = () => {
  const navigate = useNavigate();
  const { id ,invoice_id} = useParams();
  const { data, isLoading } = useGetInvoiceByIdQuery({ invoice_id });
  const [form] = Form.useForm(); // Create a form instance
  const [updatePreviewInvoice, { isLoading: isUpdateLoading }] =
  useUpdatePreviewInvoiceMutation();

  useEffect(() => {
    if (data?.data) {
      // Set initial form values based on fetched data
      form.setFieldsValue({
        invoice_code: data.data[0]?.invoice_code,
        invoice_type: data.data[0]?.invoice_type,
        tenant_name: data.data[0]?.tenant_name,
        outlet_name: data.data[0]?.propertycontract.outlet_name,
        transaction_type: data.data[0]?.transaction_type == 0 ? '-' : '',
        payment_type: data.data[0]?.payment_type == 0 ? '-' : '',
        date: data.data[0]?.date ? moment(data.data[0]?.date) : moment(),
      });
    }
  }, [data, form]);
  console.log(data)
  const handleUpdate = async (values) => {
    const formattedValues = {
      ...values,
      date: values.date.format('YYYY-MM-DD'), 
    };
  
    console.log('Updated Values:', formattedValues);
    try {
      
      await updatePreviewInvoice({
        params: formattedValues,
        id: invoice_id,
      }).unwrap();
      message.success("Preview is updated successfully.");
      navigate(`/${id}/invoice/preview`); 
    } catch (error) {
      console.error('Update failed:', error);
      message.error("Unable to update this invoice.");
    }
  };
  return (
    <>
     <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/invoice/preview`)}>
          Preview Invoice
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Invoice</Breadcrumb.Item>
      </Breadcrumb>
      <Card loading={isLoading} title="Invoice Details">
        <Form  layout="vertical"
          form={form}
          loading={isLoading}
          onFinish={handleUpdate}
          initialValues={{
            rental_fee: {
              unit: data?.data[0]?.rental_fee.unit,  
              qty: data?.data[0]?.rental_fee.qty,  
              rate: data?.data[0]?.rental_fee.rate,  
              amount: data?.data[0]?.rental_fee.amount,  
            },
            commercial_tax: {
              unit: data?.data[0]?.commercial_tax?.unit,  
              qty: data?.data[0]?.commercial_tax?.qty,  
              rate: data?.data[0]?.commercial_tax?.rate,  
              amount: data?.data[0]?.commercial_tax?.amount,  
            },
            advertising_fee: {
              unit: data?.data[0]?.advertising_fee?.unit,  
              qty: data?.data[0]?.advertising_fee?.qty,  
              rate: data?.data[0]?.advertising_fee?.rate,  
              amount: data?.data[0]?.advertising_fee?.amount,  
            },
            cleaning_security_fee: {
              unit: data?.data[0]?.cleaning_security_fee?.unit,  
              qty: data?.data[0]?.cleaning_security_fee?.qty,  
              rate: data?.data[0]?.cleaning_security_fee?.rate,  
              amount: data?.data[0]?.cleaning_security_fee?.amount,  
            },
            public_area_meter: {
              unit: data?.data[0]?.public_area_meter?.unit,  
              qty: data?.data[0]?.public_area_meter?.qty,  
              rate: data?.data[0]?.public_area_meter?.rate,  
              amount: data?.data[0]?.public_area_meter?.amount,  
            },
            yesb_meter: {
              // unit: data?.data[0]?.yesb_meter?.unit,  
              last_month: data?.data[0]?.yesb_meter?.last_month,  
              this_month: data?.data[0]?.yesb_meter?.this_month,  
              usage: data?.data[0]?.yesb_meter?.usage,  
              amount: data?.data[0]?.yesb_meter?.amount,  
            },
            gas_line_meter: {
              // unit: data?.data[0]?.gas_line_meter?.unit,  
              last_month: data?.data[0]?.gas_line_meter?.last_month,  
              this_month: data?.data[0]?.gas_line_meter?.this_month,  
              usage: data?.data[0]?.gas_line_meter?.usage,  
              amount: data?.data[0]?.gas_line_meter?.amount,  
            },
            generator_meter: {
              // unit: data?.data[0]?.generator_meter?.unit,  
              last_month: data?.data[0]?.generator_meter?.last_month,  
              this_month: data?.data[0]?.generator_meter?.this_month,  
              usage: data?.data[0]?.generator_meter?.usage,  
              amount: data?.data[0]?.generator_meter?.amount,  
            },
            water_cost_meter: {
              // unit: data?.data[0]?.water_cost_meter?.unit,  
              last_month: data?.data[0]?.water_cost_meter?.last_month,  
              this_month: data?.data[0]?.water_cost_meter?.this_month,  
              usage: data?.data[0]?.water_cost_meter?.usage,  
              amount: data?.data[0]?.water_cost_meter?.amount,  
            },
            poest_and_mosquito_control: {
              unit: data?.data[0]?.poest_and_mosquito_control?.unit,  
              qty: data?.data[0]?.poest_and_mosquito_control?.qty,  
              rate: data?.data[0]?.poest_and_mosquito_control?.rate,  
              rate_type: data?.data[0]?.poest_and_mosquito_control?.rate_type,  
              amount: data?.data[0]?.poest_and_mosquito_control?.amount,  
            },
            bill_board_advertising_charges: {
              unit: data?.data[0]?.bill_board_advertising_charges?.unit,  
              qty: data?.data[0]?.bill_board_advertising_charges?.qty,  
              rate: data?.data[0]?.bill_board_advertising_charges?.rate,  
              rate_type: data?.data[0]?.bill_board_advertising_charges?.rate_type,  
              amount: data?.data[0]?.bill_board_advertising_charges?.amount,  
            },
            sub_total: data?.data[0]?.sub_total,
            grand_total: data?.data[0]?.grand_total
          }}
          >
          {/* <div className="invoice-detail">
            <Form.Item label="Date" name="date" className="invoice-input">
              <DatePicker style={{ width: 250, height: 44 }} />
            </Form.Item>
           
            <Form.Item label="Invoice No." name="invoice_code" className="invoice-input">
              <Input disabled style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Invoice Type" name="invoice_type" className="invoice-input">
              <Select style={{ height: 44 }} options={[
                { value: "yearly", label: "Yearly" },
                { value: "monthly", label: "Monthly" },
              ]} />
            </Form.Item>
            <Form.Item label="Tenant Name" name="tenant_name" className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Outlet Name" name="outlet_name" className="invoice-input">
              <Input style={{ height: 44 }} />
            </Form.Item>
            <Form.Item label="Transaction Type"   name="transaction_type" className="invoice-input">
              <Select style={{ height: 44 }} options={[
                { value: "0", label: "-" },
                { value: "1", label: "KBZ Pay" },
                { value: "2", label: "Cash" },
                { value: "3", label: "AYA Pay" },
              ]}  />
            </Form.Item>
            <Form.Item label="Payment Type" name="payment_type" className="invoice-input">
              <Select style={{ height: 44 }} options={[
                { value: "1", label: "Partially" },
                { value: "2", label: "Fully" },
              ]} />
            </Form.Item>
          </div> */}
          {/* </div> */}

          <Row gutter={[16]}>
          <Col span={4}>
            <p>
              <strong>Tenant Name</strong>
            </p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={16}>
            <p>{data?.data[0]?.tenant_name}</p>
          </Col>

          <Col span={4}>
            <p>
              <strong>Outlet Name</strong>
            </p>
          </Col>
          <Col span={1}>
            <p>:</p>
          </Col>
          <Col span={16}>
            <p>{data?.data[0]?.propertycontract.outlet_name}</p>
          </Col>
          </Row>

          <div className="invoice-sub-header">
            <p>ဆိုင်ခန်းငှားခ</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit"  name={['rental_fee', 'unit']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Qty" name={['rental_fee', 'qty']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Rate" name={['rental_fee', 'rate']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['rental_fee', 'amount']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>ကုန်သွယ်လုပ်ငန်းခွန်</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit"  name={['commercial_tax', 'unit']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Qty" name={['commercial_tax', 'qty']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Rate" name={['commercial_tax', 'rate']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['commercial_tax', 'amount']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>ကြေငြာစရိတ် (ဆိုင်ခန်းငှားခ 2%)</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit"  name={['advertising_fee', 'unit']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Qty" name={['advertising_fee', 'qty']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Rate" name={['advertising_fee', 'rate']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['advertising_fee', 'amount']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>သန့်ရှင်းရေးနှင့်လုံခြုံရေးစရိတ်</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit"  name={['cleaning_security_fee', 'unit']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Qty" name={['cleaning_security_fee', 'qty']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Rate" name={['cleaning_security_fee', 'rate']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['cleaning_security_fee', 'amount']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>Air Conditioning & Elevator Fee</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit"  name={['public_area_meter', 'unit']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Qty" name={['public_area_meter', 'qty']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Rate" name={['public_area_meter', 'rate']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['public_area_meter', 'amount']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>Electricity (YESB)</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="This Month" name={['yesb_meter', 'this_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Previous Month" name={['yesb_meter', 'last_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Usage" name={['yesb_meter', 'usage']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['yesb_meter', 'amount']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>Electricity (Generator)</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="This Month" name={['generator_meter', 'this_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Previous Month" name={['generator_meter', 'last_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Usage" name={['generator_meter', 'usage']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['generator_meter', 'amount']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>Electricity (Gas Line)</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="This Month" name={['gas_line_meter', 'this_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Previous Month" name={['gas_line_meter', 'last_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Usage" name={['gas_line_meter', 'usage']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['gas_line_meter', 'amount']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>Other Fee (Water)</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="This Month" name={['water_cost_meter', 'this_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Previous Month" name={['water_cost_meter', 'last_month']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Usage" name={['water_cost_meter', 'usage']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['water_cost_meter', 'amount']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>Pest & Mosquito Control</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit"  name={['poest_and_mosquito_control', 'unit']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Qty" name={['poest_and_mosquito_control', 'qty']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Rate" name={['poest_and_mosquito_control', 'rate']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['poest_and_mosquito_control', 'amount']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>

          <div className="invoice-sub-header">
            <p>Bill Board Advertising Charges</p>
          </div>
          <div className="invoice-detail sub">
            <Form.Item label="Unit"  name={['bill_board_advertising_charges', 'unit']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Qty" name={['bill_board_advertising_charges', 'qty']} className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Rate" name={['bill_board_advertising_charges', 'rate']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
            <Form.Item label="Amount" name={['bill_board_advertising_charges', 'amount']}  className="invoice-input">
              <Input style={{ height: 44 }}></Input>
            </Form.Item>
          </div>
          {/* <div className="invoice-sub-header">
            <p style={{float:'right'}}>{data?.data.sub_total}</p>
          </div>
          <br/>
          <div className="invoice-sub-header">
            <p style={{float:'right'}}>{data?.data.grand_total}</p>
          </div> */}
          <Form.Item style={{display: 'flex', justifyContent:'flex-end'}}>
            <Button className="btn" onClick={() => navigate(-1)}>Cancel</Button>
            <Button className="btn update" type="primary" htmlType="submit">Update</Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default EditInvoice;
