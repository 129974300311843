import {
  Form,
  Input,
  Table,
  Modal,
  Select,
  Button,
  message,
  Divider,
  Dropdown,
  Space,
  ConfigProvider
} from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllZoneQuery,
  useGetAllBuildingQuery,
  useDeleteZoneMutation,
  useUpdateZoneMutation,
  useGetFloorsByBuildingIdQuery,
} from "./propertyApi";
import { EditOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";

const { Option } = Select;

const ZoneList = ({ searchTerm }) => {
  const token = useSelector((state) => state.authSlice.token2);

  // Fetch zones and buildings
  const { data: zoneListData, isLoading: isLoadingZones } =
    useGetAllZoneQuery(token);
  const { data: buildingListData, isLoading: isLoadingBuildings } =
    useGetAllBuildingQuery(token);

  const [selectedBuilding, setSelectedBuilding] = useState(null);

  const { data: floorListData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });

  // Mutations
  const [deleteZone, { isLoading: isDeleteLoading, error: isDeleteError }] =
    useDeleteZoneMutation();
  const [updateZone, { isLoading: isUpdateLoading, error: isUpdateError }] =
    useUpdateZoneMutation();

  const [form] = Form.useForm();

  const items = [
    {
      key: "1",
      label: (
        <span>
          <EditOutlined /> Edit
        </span>
      ),
      onClick: (record) => showEditModal(record),
    },
    {
      key: "2",
      label: (
        <span>
          <DeleteOutlined /> Delete
        </span>
      ),
      onClick: (record) => showDeleteModal(record),
    },
  ];

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Building",
      dataIndex: "building",
      key: "building",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Zone / Roles",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "",
      key: "operation",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space size="middle">
          <Dropdown
            menu={{
              items: items.map((item) => ({
                ...item,
                onClick: () => item.onClick(record), // Pass the record to onClick handler
              })),
            }}
            placement="bottomRight">
            <Button style={{ border: "1px solid darkblue" }}>
              Action <DownOutlined />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const [filteredZone, setFilteredZone] = useState([]);

  useEffect(() => {
    if (zoneListData) {
      // Filter data based on search term
      const filteredData =
        searchTerm.trim() === ""
          ? zoneListData.data?.zones
          : zoneListData.data.zones.filter((zone) =>
              zone.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
      setFilteredZone(filteredData);
    }
  }, [searchTerm, zoneListData]);

  const tableData = filteredZone?.map((zone, index) => ({
    key: zone._id,
    no: index + 1,
    zone: zone?.name,
    building: zone?.building?.name,
    buildingId: zone?.building?._id,
    floor: zone?.floor?.name,
    floorId: zone?.floor?._id,
  }));

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const showDeleteModal = (record) => {
    setCurrentRecord(record);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOk = async () => {
    if (currentRecord) {
      try {
        await deleteZone({ zoneId: currentRecord.key }).unwrap();
        setIsDeleteModalOpen(false);
        message.success("Zone Deleted Successfully.");
      } catch (isDeleteError) {
        const errorMessage = isDeleteError?.data?.errors?.[0] || "Failed to add floor";
        message.error(errorMessage);
      }
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const showEditModal = (record) => {
    setCurrentRecord({
      ...record,
      buildingId: record.buildingId,
      floorId: record.floorId,
    });
    form.setFieldsValue({
      building: record.buildingId,
      floor: record.floorId,
      zone: record.zone,
    });
    setSelectedBuilding(record.buildingId);
    setIsEditModalOpen(true);
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    form.setFieldsValue({ floor: undefined }); // Clear the floor field when a new building is selected
  };

  const handleEditOk = async () => {
    if (currentRecord) {
      const selectedBuildingId = form.getFieldValue("building");
      const selectedFloorId = form.getFieldValue("floor");

      const updatedData = {
        zoneId: currentRecord.key,
        buildingId: currentRecord.buildingId,
        floorId: currentRecord.floorId,
        updatedData: {
          name: form.getFieldValue("zone"),
          building:
            selectedBuildingId !== currentRecord.buildingId
              ? selectedBuildingId
              : currentRecord.buildingId,
          floor:
            selectedFloorId !== currentRecord.floorId
              ? selectedFloorId
              : currentRecord.floorId,
        },
      };

      try {
        await updateZone(updatedData).unwrap();
        setIsEditModalOpen(false);
        message.success("Zone updated successfully.");
      } catch (isUpdateError) {
        const errorMessage = isUpdateError?.data?.errors?.[0] || "Failed to add floor";
        message.error(errorMessage);
      }
    }
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  return (
    <>
    <ConfigProvider
        theme={{
          token: {
            colorBgContainer: "#f8f7f7",
          },
        }}>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isLoadingZones}
        pagination={{
          pageSizeOptions: ["10", "20", "30"], // Options for 10, 20, or 50 items per page
          showSizeChanger: true, // Enables the dropdown for selecting page size
          defaultPageSize: 10, // Sets the default page size to 10
        }}
      />
      <Modal
        width={360}
        style={{ textAlign: "center" }}
        title="ARE YOU SURE?"
        open={isDeleteModalOpen}
        closable={false}
        centered
        footer={[
          <div className="delete-modal-btn">
            <Button
              className="delete-btn"
              key="back"
              onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button
              className="delete-btn filter"
              key="submit"
              onClick={handleDeleteOk}>
              Delete
            </Button>
          </div>,
        ]}>
        <p>Do you want to delete this permanently?</p>
      </Modal>
      <Modal
        width={880}
        onCancel={handleEditCancel}
        title="Edit Zone"
        open={isEditModalOpen}
        footer={[
          <>
            <Button type="text" className="btn" key="back" onClick={handleEditCancel}>
              Cancel
            </Button>
            <Button className="btn filter" key="submit" onClick={handleEditOk}>
              Update
            </Button>
          </>,
        ]}>
        <Divider style={{ border: "2px solid darkblue", marginTop: "5px" }} />
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            building: currentRecord?.buildingId,
            floor: currentRecord?.floorId,
            zone: currentRecord?.zone,
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              flexWrap: "wrap",
            }}>
            <Form.Item label="Building" name="building">
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a building"
                loading={isLoadingBuildings}
                onChange={handleBuildingChange}>
                {buildingListData?.data?.buildings?.map((building) => (
                  <Option key={building._id} value={building._id}>
                    {building.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Floor Name" name="floor">
              <Select
                style={{ height: 44, width: 400 }}
                placeholder="Select a floor"
                loading={isLoadingFloors}>
                {floorListData?.data?.map((floor) => (
                  <Option key={floor._id} value={floor._id}>
                    {floor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{ width: 400 }} label="Zone / Role" name="zone">
              <Input style={{ height: 44 }} placeholder="Zone / Role" />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      </ConfigProvider>
    </>
  );
};

export default ZoneList;
