import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  message,
  Button,
  Spin,
  Breadcrumb,
} from "antd";
import styles from "../styles.css";
import {
  useGetAllCitiesListQuery,
  useGetTownshipByCityIdQuery,
} from "../../../features/tenant/tenantApi";
import { useGetSubTenantDetailByIdQuery, useUpdateSubTenantDetailMutation } from "../../../features/tenant/subTenantApi";
const { Dragger } = Upload;
const { Option } = Select;

const EditSubTenant = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const location = useLocation();
  const subTenantId = location.state?.subTenantId;

  const { data: subTenantDetail, isLoading: isTenantInfoLoading } =
    useGetSubTenantDetailByIdQuery({ subTenantId: subTenantId });

  const [updateSubTenantDetail, { isLoading: isUpdateLoading }] =
    useUpdateSubTenantDetailMutation();

  const { data: cityList, isLoading: isCityListLoading } =
    useGetAllCitiesListQuery();
  const [selectedCity, setSelectedCity] = useState(null);

  const { data: townshipList, isLoading: isTownshipListLoading } =
    useGetTownshipByCityIdQuery(
      { cityId: selectedCity },
      { skip: !selectedCity }
    );

  useEffect(() => {
    if (subTenantDetail?.data?.city?._id) {
      setSelectedCity(subTenantDetail.data.city?._id);
    }
  }, [subTenantDetail]);

  const initialValues = subTenantDetail
    ? {
        name: subTenantDetail?.data?.name || "",
        phoneNumber: subTenantDetail?.data?.phoneNumber || "",
        email: subTenantDetail?.data?.email || "",
        status: subTenantDetail?.data?.status || 1,
        city: subTenantDetail?.data?.city?._id || "",
        township: subTenantDetail?.data?.township?._id || "",
        address: subTenantDetail?.data?.address || "",
        type: subTenantDetail?.data?.type || 1,
      }
    : {};

  const onFinish = async (values) => {
    const payload = {
      name: values.name,
      phoneNumber: values.phoneNumber,
      email: values.email,
      city: values.city,
      township: values.township,
      address: values.address,
      status: values.status,
    };
  
    console.log("Payload:", payload);
  
    try {
      const result = await updateSubTenantDetail({ params: payload, subTenantId: subTenantId });
      console.log("Update result:", result);
      
      // Check if the update was successful based on the result
      if (result.error) {
        throw new Error(result.error.message || "Update failed");
      }
      
      message.success("Sub Tenant is updated successfully.");
      navigate(`/${id}/tenant/sub-list`);
    } catch (error) {
      console.error("Update failed:", error);
      message.error("Unable to update this sub tenant.");
      // Navigation should not occur here since the update failed
    }
  };  

  // const handleShowPreview = () => {
  //   setShowPreviewModal(true);
  // };

  // const handlePreviewCancel = () => {
  //   setShowPreviewModal(false);
  // };

  return (
    <>
     <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
    <Breadcrumb.Item onClick={() => navigate(`/${id}/tenant/sub-list`)}>
      Sub Tenant List
    </Breadcrumb.Item>
    <Breadcrumb.Item>Edit Profile</Breadcrumb.Item>
  </Breadcrumb>
      {isTenantInfoLoading ? (
        <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
          >
          <Card
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginLeft: "8px" }}>Edit Sub Tenant Info</span>
              </div>
            }>
              
            <div className="billing-contact-form">
              <Form.Item
                rules={[
                  { required: true, message: "Please input the tenant name" },
                ]}
                className="tenant-label-group"
                label="Name"
                name="name">
                <Input style={{ height: 44 }} placeholder="Name" />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                className="tenant-label-group"
                name="phoneNumber"
                rules={[
                  { required: true, message: "Please input the phone number" },
                ]}>
                <Input style={{ height: 44 }} placeholder="Phone Number" />
              </Form.Item>

              <Form.Item
                className="tenant-label-group"
                label="Email"
                rules={[{ required: true, message: "Please input email" }]}
                name="email">
                <Input style={{ height: 44 }} placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Status"
                className="tenant-label-group"
                rules={[
                  {
                    required: true,
                    message: "Please choose the tenant's status",
                  },
                ]}
                name="status">
                <Select style={{ height: 44 }} placeholder="Select Status">
                  <Option value={1}>Active</Option>
                  <Option value={2}>Suspend</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="City"
                rules={[{ required: true, message: "Please choose the city" }]}
                name="city"
                className="tenant-label-group">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select city name"
                  onChange={(value) => setSelectedCity(value)}
                  loading={isCityListLoading}>
                  {cityList?.data?.map((city) => (
                    <Option key={city._id} value={city._id}>
                      {city?.city_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[
                  { required: true, message: "Please choose the township" },
                ]}
                name="township"
                label="Township"
                className="tenant-label-group">
                <Select
                  style={{ height: 44 }}
                  placeholder="Select a township"
                  loading={isTownshipListLoading}>
                  {townshipList?.data?.map((township) => (
                    <Option key={township._id} value={township._id}>
                      {township?.township_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                rules={[
                  { required: true, message: "Please input the address" },
                ]}
                label="Address"
                style={{ width: 705 }}
                name="address">
                <Input style={{ height: 44 }} placeholder="Address" />
              </Form.Item>
            </div>
          </Card>

          <Form.Item>
            <div className="add-tenant-btn-group">
              <Button
                onClick={() => navigate(`/${id}/tenant/sub-list`)}
                className="btn">
                Cancel
              </Button>
              <Button type="text" htmlType="submit" className="btn filter">
                Update
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default EditSubTenant;
