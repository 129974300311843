import {
  Card,
  Form,
  Input,
  Button,
  Select,
  message,
  Breadcrumb,
  ConfigProvider,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  useAddNewShopMutation,
  useGetAllBuildingQuery,
  useGetFloorsByBuildingIdQuery,
  useGetZoneByBuildingIdQuery,
} from "./propertyApi";
import "../../pages/property/styles.css";
import { useNavigate, useParams } from "react-router-dom";

const AddNewShop = () => {
  const [form] = Form.useForm();
  const [addNewShop, { isLoading }] = useAddNewShopMutation();
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [pricePerSqft, setPricePerSqft] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();

  const formatPrice = (price) => {
    return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Fetching buildings
  const { data: buildingsData, isLoading: isLoadingBuildings } =
    useGetAllBuildingQuery();

  // Fetching floors based on selected building
  const { data: floorsData, isLoading: isLoadingFloors } =
    useGetFloorsByBuildingIdQuery(selectedBuilding, {
      skip: !selectedBuilding,
    });

  // Logging the selected building and floor
  useEffect(() => {
    if (selectedBuilding && selectedFloor) {
      console.log("Building ID:", selectedBuilding);
      console.log("Floor ID:", selectedFloor);
    }
  }, [selectedBuilding, selectedFloor]);

  // Fetching zones based on selected floor
  const { data: zonesData, isLoading: isLoadingZones } =
    useGetZoneByBuildingIdQuery(
      { floorId: selectedFloor },
      {
        skip: !selectedFloor,
      }
    );

  // Logging the zones data
  useEffect(() => {
    if (zonesData) {
      console.log("Zones Data:", zonesData);
    }
  }, [zonesData]);

  const [formData, setFormData] = useState({
    building: "",
    floor: "",
    zone: "",
    name: "",
    code: "",
    total_area: 0,
    price: 0,
    status: 1,
  });

  const handleZoneChange = (zoneId) => {
    const selectedZone = zonesData?.data?.find((zone) => zone._id === zoneId);
    if (selectedZone) {
      const pricePerSq = selectedZone?.floor?.price_per_sq;
      setPricePerSqft(pricePerSq); // Store price_per_sq
      setFormData({
        ...formData,
        zone: zoneId,
      });
    }
  };

  const handleInputChange = (key, value) => {
    const updatedValue =
      key === "total_area" || key === "price" ? parseInt(value, 10) : value;

    // If total area is updated, calculate the price
    if (key === "total_area") {
      const newPrice = updatedValue * pricePerSqft; // Calculate price
      setFormData({
        ...formData,
        total_area: updatedValue,
        price: newPrice, // Automatically update price
      });
      form.setFieldsValue({ price: formatPrice(newPrice) });
    } else {
      setFormData({
        ...formData,
        [key]: updatedValue,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      await addNewShop({
        ...formData,
        price: formData.price, // Send raw price without formatting to backend
      }).unwrap();
      message.success("Shop added successfully!");
      form.resetFields();
    } catch (error) {
      message.error("Failed to add the shop.");
    }
  };

  const handleBuildingChange = (value) => {
    setSelectedBuilding(value);
    setSelectedFloor(""); // Clear floor selection when building changes
    setFormData({ ...formData, building: value, floor: "", zone: "" });
  };

  const handleFloorChange = (value) => {
    setSelectedFloor(value);
    setFormData({ ...formData, floor: value, zone: "" });
  };

  const handleFormCancel = () => {
    form.resetFields(); // Reset form fields when Cancel is clicked
    navigate(`/${id}/property/add-shop`); // Call parent cancel handler
  };


  return (
    <>
      <Breadcrumb style={{ marginBottom: "10px", cursor: "pointer" }}>
        <Breadcrumb.Item onClick={() => navigate(`/${id}/property/add-shop`)}>
          Shop List
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create New Shop</Breadcrumb.Item>
      </Breadcrumb>
      <Card title="Create New Shop">
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: "#f8f7f7",
              colorBgContainerDisabled: 'rgba(0, 0, 0, 0.08)',
            },
          }}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <div className="view-detail-form">
              <Form.Item
                label="Building"
                className="input-group"
                name="building">
                <Select
                  placeholder="Building"
                  style={{ height: 44 }}
                  onChange={handleBuildingChange}
                  loading={isLoadingBuildings}>
                  {buildingsData?.data?.buildings?.map((building) => (
                    <Select.Option key={building._id} value={building._id}>
                      {building.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Floor" className="input-group" name="floor">
                <Select
                  placeholder="Floor"
                  style={{ height: 44 }}
                  onChange={handleFloorChange}
                  loading={isLoadingFloors}
                  disabled={!selectedBuilding}>
                  {floorsData?.data.map((floor) => (
                    <Select.Option key={floor._id} value={floor._id}>
                      {floor.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Zone / Role"
                className="input-group"
                name="zone">
                <Select
                  placeholder="Zone / Role"
                  style={{ height: 44 }}
                  onChange={handleZoneChange}
                  loading={isLoadingZones}
                  disabled={!selectedBuilding || !selectedFloor}>
                  {zonesData?.data?.map((zone) => (
                    <Select.Option key={zone._id} value={zone._id}>
                      {zone.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Shop Name" className="input-group" name="name">
                <Input
                  placeholder="Shop Name"
                  style={{ height: 44 }}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Code" className="input-group" name="code">
                <Input
                  style={{ height: 44 }}
                  placeholder="Code"
                  onChange={(e) => handleInputChange("code", e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Total Area (sq ft)"
                className="input-group"
                name="total_area">
                <Input
                  style={{ height: 44 }}
                  placeholder="Total Area"
                  onChange={(e) =>
                    handleInputChange("total_area", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                label="Price (MMK)"
                className="input-group"
                name="price">
                <Input
                  style={{ height: 44 }}
                  placeholder="Price (MMK)"
                  value={formatPrice(form.getFieldValue("price"))} // Display the calculated price
                  readOnly // Make it read-only as it's automatically calculated
                />
              </Form.Item>
            </div>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}>
              <Button
                className="btn"
                type="text"
                onClick={handleFormCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="btn update">
                Create
              </Button>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </Card>
    </>
  );
};

export default AddNewShop;
